/**
 * Writes data in BigEndian order.
 */
export class BigEndianWriter {
    //Constructors
    /**
     * Creates a new writer.
     */
    constructor(capacity) {
        //Constants
        /**
         * Size of Int32 type.
         */
        this.int32Size = 4;
        /**
         * Size of Int16 type.
         */
        this.int16Size = 2;
        /**
         * Size of long type.
         */
        this.int64Size = 8;
        this.bufferLength = capacity;
        this.buffer = [];
    }
    //Properties
    /**
     * Gets data written to the writer.
     */
    get data() {
        if (this.buffer.length < this.bufferLength) {
            let length = this.bufferLength - this.buffer.length;
            for (let i = 0; i < length; i++) {
                this.buffer.push(0);
            }
        }
        return this.buffer;
    }
    /// <summary>
    /// Gets position of the internal buffer.
    /// </summary>
    get position() {
        if (this.internalPosition === undefined || this.internalPosition === null) {
            this.internalPosition = 0;
        }
        return this.internalPosition;
    }
    /**
     * Writes short value.
     */
    writeShort(value) {
        let bytes = [((value & 0x0000ff00) >> 8), value & 0x000000ff];
        this.flush(bytes);
    }
    /**
     * Writes int value.
     */
    writeInt(value) {
        let i1 = (value & 0xff000000) >> 24;
        i1 = i1 < 0 ? 256 + i1 : i1;
        let i2 = (value & 0x00ff0000) >> 16;
        i2 = i2 < 0 ? 256 + i2 : i2;
        let i3 = (value & 0x0000ff00) >> 8;
        i3 = i3 < 0 ? 256 + i3 : i3;
        let i4 = value & 0x000000ff;
        i4 = i4 < 0 ? 256 + i4 : i4;
        let bytes = [(value & 0xff000000) >> 24, (value & 0x00ff0000) >> 16, (value & 0x0000ff00) >> 8, value & 0x000000ff];
        this.flush(bytes);
    }
    /**
     * Writes u int value.
     */
    writeUInt(value) {
        let buff = [(value & 0xff000000) >> 24, (value & 0x00ff0000) >> 16, (value & 0x0000ff00) >> 8, value & 0x000000ff];
        this.flush(buff);
    }
    /**
     * Writes string value.
     */
    writeString(value) {
        if (value == null) {
            throw new Error('Argument Null Exception : value');
        }
        let bytes = [];
        for (let i = 0; i < value.length; i++) {
            bytes.push(value.charCodeAt(i));
        }
        this.flush(bytes);
    }
    /**
     * Writes byte[] value.
     */
    writeBytes(value) {
        this.flush(value);
    }
    // //Implementation
    flush(buff) {
        if (buff === null) {
            throw new Error('Argument Null Exception : buff');
        }
        let position = this.position;
        for (let i = 0; i < buff.length; i++) {
            this.buffer[position] = buff[i];
            position++;
        }
        this.internalPosition += buff.length;
    }
}
