import { PdfDictionary } from './pdf-dictionary';
import { PdfNumber } from './pdf-number';
import { Operators } from './../input-output/pdf-operators';
import { PdfName } from './pdf-name';
import { PdfArray } from './pdf-array';
import { PdfReferenceHolder } from './pdf-reference';
import { CompressedStreamWriter } from '@syncfusion/ej2-compression';
/**
 * `PdfStream` class is used to perform stream related primitive operations.
 * @private
 */
export class PdfStream extends PdfDictionary {
    constructor(dictionary, data) {
        super(dictionary);
        //Constants
        /**
         * @hidden
         * @private
         */
        this.dicPrefix = 'stream';
        /**
         * @hidden
         * @private
         */
        this.dicSuffix = 'endstream';
        /**
         * Internal variable to hold `cloned object`.
         * @private
         */
        this.clonedObject2 = null;
        /**
         * @hidden
         * @private
         */
        this.bCompress = true;
        /**
         * @hidden
         * @private
         */
        this.isImageStream = false;
        /**
         * @hidden
         * @private
         */
        this.isFontStream = false;
        if (typeof dictionary !== 'undefined' || typeof data !== 'undefined') {
            this.dataStream2 = [];
            this.dataStream2 = data;
            this.bCompress2 = false;
        }
        else {
            this.dataStream2 = [];
            this.bCompress2 = true;
            //Pending
        }
    }
    /**
     * Gets the `internal` stream.
     * @private
     */
    get internalStream() {
        return this.dataStream2;
    }
    set internalStream(value) {
        this.dataStream2 = [];
        this.dataStream2 = value;
        this.modify();
    }
    /**
     * Gets or sets 'is image' flag.
     * @private
     */
    get isImage() {
        return this.isImageStream;
    }
    set isImage(value) {
        this.isImageStream = value;
    }
    /**
     * Gets or sets 'is font' flag.
     * @private
     */
    get isFont() {
        return this.isFontStream;
    }
    set isFont(value) {
        this.isFontStream = value;
    }
    /**
     * Gets or sets `compression` flag.
     * @private
     */
    get compress() {
        return this.bCompress;
    }
    set compress(value) {
        this.bCompress = value;
        this.modify();
    }
    /**
     * Gets or sets the `data`.
     * @private
     */
    get data() {
        return this.dataStream2;
    }
    set data(value) {
        this.dataStream2 = [];
        this.dataStream2 = value;
        this.modify();
    }
    /**
     * `Clear` the internal stream.
     * @private
     */
    clearStream() {
        this.internalStream = [];
        if (this.items.containsKey(this.dictionaryProperties.filter)) {
            this.remove(this.dictionaryProperties.filter);
        }
        this.bCompress = true;
        this.modify();
    }
    /**
     * `Writes` the specified string.
     * @private
     */
    write(text) {
        if (text == null) {
            throw new Error('ArgumentNullException:text');
        }
        if (text.length <= 0) {
            throw new Error('ArgumentException: Can not write an empty string, text');
        }
        this.dataStream2.push(text);
        this.modify();
    }
    /**
     * `Writes` the specified bytes.
     * @private
     */
    writeBytes(data) {
        if (data === null) {
            throw new Error('ArgumentNullException:data');
        }
        if (data.length <= 0) {
            throw new Error('ArgumentException: Can not write an empty bytes, data');
        }
        let text = '';
        for (let i = 0; i < data.length; i++) {
            text += String.fromCharCode(data[i]);
        }
        this.dataStream2.push(text);
        this.modify();
    }
    /**
     * Raises event `Cmap BeginSave`.
     * @private
     */
    onCmapBeginSave() {
        this.cmapBeginSave.sender.cmapBeginSave();
    }
    /**
     * Raises event `Font Program BeginSave`.
     * @private
     */
    onFontProgramBeginSave() {
        this.fontProgramBeginSave.sender.fontProgramBeginSave();
    }
    /**
     * `Compresses the content` if it's required.
     * @private
     */
    compressContent(data, writer) {
        if (this.bCompress) {
            let byteArray = [];
            for (let i = 0; i < data.length; i++) {
                byteArray.push(data.charCodeAt(i));
            }
            let dataArray = new Uint8Array(byteArray);
            let sw = new CompressedStreamWriter();
            // data = 'Hello World!!!';
            sw.write(dataArray, 0, dataArray.length);
            sw.close();
            data = sw.getCompressedString;
            this.addFilter(this.dictionaryProperties.flatedecode);
        }
        return data;
    }
    /**
     * `Adds a filter` to the filter array.
     * @private
     */
    addFilter(filterName) {
        let obj = this.items.getValue(this.dictionaryProperties.filter);
        if (obj instanceof PdfReferenceHolder) {
            let rh = obj;
            obj = rh.object;
        }
        let array = obj;
        let name = obj;
        if (name != null) {
            array = new PdfArray();
            array.insert(0, name);
            this.items.setValue(this.dictionaryProperties.filter, array);
        }
        name = new PdfName(filterName);
        if (array == null) {
            this.items.setValue(this.dictionaryProperties.filter, name);
        }
        else {
            array.insert(0, name);
        }
    }
    /**
     * `Saves` the object using the specified writer.
     * @private
     */
    save(writer) {
        if (typeof this.cmapBeginSave !== 'undefined') {
            this.onCmapBeginSave();
        }
        if (typeof this.fontProgramBeginSave !== 'undefined') {
            this.onFontProgramBeginSave();
        }
        let data = '';
        for (let i = 0; i < this.data.length; i++) {
            data = data + this.data[i];
        }
        if (data.length > 1 && !this.isImage && !this.isFont) {
            data = 'q\r\n' + data + 'Q\r\n';
        }
        data = this.compressContent(data, writer);
        let length = data.length;
        this.items.setValue(this.dictionaryProperties.length, new PdfNumber(length));
        super.save(writer, false);
        writer.write(this.dicPrefix);
        writer.write(Operators.newLine);
        if (data.length > 0) {
            writer.write(data);
        }
        writer.write(Operators.newLine);
        writer.write(this.dicSuffix);
        writer.write(Operators.newLine);
    }
    /**
     * Converts `bytes to string`.
     * @private
     */
    static bytesToString(byteArray) {
        let output = '';
        for (let i = 0; i < byteArray.length; i++) {
            output = output + String.fromCharCode(byteArray[i]);
        }
        return output;
    }
}
export class SaveCmapEventHandler {
    /**
     * New instance for `save section collection event handler` class.
     * @private
     */
    constructor(sender) {
        this.sender = sender;
    }
}
export class SaveFontProgramEventHandler {
    /**
     * New instance for `save section collection event handler` class.
     * @private
     */
    constructor(sender) {
        this.sender = sender;
    }
}
