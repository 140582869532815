/**
 * Provides data for `PageAddedEventHandler` event.
 * This event raises when adding the new PDF page to the PDF document.
 */
export class PageAddedEventArgs {
    /**
     * Gets the `newly added page`.
     * @private
     */
    get page() {
        return this.pdfPage;
    }
    constructor(page) {
        if (typeof page !== 'undefined') {
            this.pdfPage = page;
        }
        else {
            this.pdfPage = null;
        }
    }
}
