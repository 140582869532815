import { DictionaryProperties } from './../../input-output/pdf-dictionary-properties';
export class PdfFunction {
    //Constructor
    /**
     * Initializes a new instance of the `PdfFunction` class.
     * @public
     */
    constructor(dictionary) {
        //Field
        /**
         * Internal variable to store dictionary.
         * @private
         */
        this.mDictionary = null;
        /**
         * Local variable to store the dictionary properties.
         * @private
         */
        this.mDictionaryProperties = new DictionaryProperties();
        this.mDictionary = dictionary;
    }
    //Properties
    /**
     * Gets or sets the domain of the function.
     * @public
     */
    get domain() {
        let domain = this.mDictionary.items.getValue(this.mDictionaryProperties.domain);
        return domain;
    }
    set domain(value) {
        this.mDictionary.items.setValue(this.mDictionaryProperties.domain, value);
    }
    /**
     * Gets or sets the range.
     * @public
     */
    get range() {
        let range = (this.mDictionary.items.getValue(this.mDictionaryProperties.range));
        return range;
    }
    set range(value) {
        this.mDictionary.items.setValue(this.mDictionaryProperties.range, value);
    }
    /**
     * Gets the dictionary.
     */
    get dictionary() {
        return this.mDictionary;
    }
    //IPdfWrapper Members
    /**
     * Gets the element.
     */
    get element() {
        return this.mDictionary;
    }
}
