import { GetResourceEventHandler } from './../pdf-graphics';
import { PdfBrush } from './pdf-brush';
import { PointF, SizeF, RectangleF, Rectangle } from './../../drawing/pdf-drawing';
import { DictionaryProperties } from './../../input-output/pdf-dictionary-properties';
import { PdfArray } from './../../primitives/pdf-array';
import { PdfNumber } from './../../primitives/pdf-number';
import { PdfGraphics } from './../pdf-graphics';
import { PdfResources } from './../pdf-resources';
import { PdfStream } from './../../primitives/pdf-stream';
import { PdfPage } from './../../pages/pdf-page';
/**
 * `PdfTilingBrush` Implements a colored tiling brush.
 */
export class PdfTilingBrush extends PdfBrush {
    /**
     * Initializes a new instance of the `PdfTilingBrush` class.
     * @public
     */
    constructor(arg1, arg2) {
        super();
        /**
         * Local variable to store Stroking.
         * @private
         */
        this.mStroking = false;
        /**
         * Local variable to store the tile start location.
         * @private
         */
        this.mLocation = new PointF(0, 0);
        /**
         * Local variable to store the dictionary properties.
         * @private
         */
        this.mDictionaryProperties = new DictionaryProperties();
        let rect = null;
        if (arg1 instanceof Rectangle) {
            rect = arg1;
        }
        else if (arg1 instanceof SizeF) {
            rect = new Rectangle(0, 0, arg1.width, arg1.height);
        }
        if (arg2 !== null && arg2 instanceof PdfPage) {
            this.mPage = arg2;
        }
        this.brushStream = new PdfStream();
        this.mResources = new PdfResources();
        this.brushStream.items.setValue(this.mDictionaryProperties.resources, this.mResources);
        this.setBox(rect);
        this.setObligatoryFields();
        if (arg2 !== null && arg2 instanceof PdfPage) {
            this.mPage = arg2;
            this.graphics.colorSpace = arg2.document.colorSpace;
        }
    }
    /**
     * Initializes a new instance of the `PdfTilingBrush` class.
     * @private
     * @param rectangle The size of the smallest brush cell.
     * @param page The Current Page Object.
     * @param location The Tile start location.
     * @param matrix The matrix.
     */
    initialize(rectangle, page, location, matrix) {
        this.mPage = page;
        this.mLocation = location;
        this.mTransformationMatrix = matrix;
        this.tempBrushStream = this.brushStream;
        this.brushStream = new PdfStream();
        let tempResource = new PdfResources();
        this.brushStream.items.setValue(this.mDictionaryProperties.resources, tempResource);
        this.setBox(rectangle);
        this.setObligatoryFields();
        return this;
    }
    //Properties
    /**
     * Location representing the start position of the tiles.
     * @public
     */
    get location() {
        return this.mLocation;
    }
    set location(value) {
        this.mLocation = value;
    }
    /**
     * Sets the obligatory fields.
     * @private
     */
    setObligatoryFields() {
        this.brushStream.items.setValue(this.mDictionaryProperties.patternType, new PdfNumber(1));
        //  Tiling brush.
        this.brushStream.items.setValue(this.mDictionaryProperties.paintType, new PdfNumber(1));
        //  Coloured.
        this.brushStream.items.setValue(this.mDictionaryProperties.tilingType, new PdfNumber(1));
        //  Constant spacing.
        this.brushStream.items.setValue(this.mDictionaryProperties.xStep, new PdfNumber((this.mBox.right - this.mBox.left)));
        this.brushStream.items.setValue(this.mDictionaryProperties.yStep, new PdfNumber((this.mBox.bottom - this.mBox.top)));
        if ((this.mPage != null) && (this.mLocation != null)) {
            if ((this.mTransformationMatrix == null && typeof this.mTransformationMatrix === 'undefined')) {
                // Transform the tile origin to fit the location
                let tileTransform = (this.mPage.size.height % this.rectangle.size.height) - (this.mLocation.y);
                /* tslint:disable-next-line:max-line-length */
                this.brushStream.items.setValue(this.mDictionaryProperties.matrix, new PdfArray([1, 0, 0, 1, this.mLocation.x, tileTransform]));
            }
            else {
                let tileTransform = 0;
                // Transform the tile origin to fit the location
                let elements = this.mTransformationMatrix.matrix.elements;
                if ((this.mPage.size.height > this.rectangle.size.height)) {
                    tileTransform = (this.mTransformationMatrix.matrix.offsetY
                        - (this.mPage.size.height % this.rectangle.size.height));
                }
                else {
                    tileTransform = ((this.mPage.size.height % this.rectangle.size.height) + this.mTransformationMatrix.matrix.offsetY);
                }
                this.brushStream.items.setValue(this.mDictionaryProperties.matrix, new PdfArray([
                    elements[0], elements[1], elements[2], elements[3], elements[4], tileTransform
                ]));
            }
        }
    }
    /**
     * Sets the BBox coordinates.
     * @private
     */
    setBox(box) {
        this.mBox = box;
        let rect = new RectangleF(this.mBox.left, this.mBox.top, this.mBox.right, this.mBox.bottom);
        this.brushStream.items.setValue(this.mDictionaryProperties.bBox, PdfArray.fromRectangle(rect));
    }
    //Properties
    /**
     * Gets the boundary box of the smallest brush cell.
     * @public
     */
    get rectangle() {
        return this.mBox;
    }
    /**
     * Gets the size of the smallest brush cell.
     * @public
     */
    get size() {
        return this.mBox.size;
    }
    /**
     * Gets Graphics context of the brush.
     */
    get graphics() {
        if ((this.mGraphics == null && typeof this.mGraphics === 'undefined')) {
            let gr = new GetResourceEventHandler(this);
            let g = new PdfGraphics(this.size, gr, this.brushStream);
            this.mGraphics = g;
            this.mResources = this.getResources();
            this.mGraphics.initializeCoordinates();
        }
        return this.mGraphics;
    }
    /**
     * Gets the resources and modifies the template dictionary.
     * @public
     */
    getResources() {
        return this.mResources;
    }
    /**
     * Gets or sets a value indicating whether this PdfTilingBrush
     * is used for stroking operations.
     */
    get stroking() {
        return this.mStroking;
    }
    set stroking(value) {
        this.mStroking = value;
    }
    //PdfBrush methods
    /**
     * Creates a new copy of a brush.
     * @public
     */
    clone() {
        let brush = this.initialize(this.rectangle, this.mPage, this.location, this.mTransformationMatrix);
        if ((this.mTransformationMatrix != null) && (this.mTransformationMatrix.matrix != null)) {
            /* tslint:disable-next-line:max-line-length */
            brush.brushStream.items.setValue(this.mDictionaryProperties.matrix, new PdfArray(this.mTransformationMatrix.matrix.elements));
        }
        brush.brushStream.data = this.tempBrushStream.data;
        brush.mResources = new PdfResources(this.mResources);
        brush.brushStream.items.setValue(this.mDictionaryProperties.resources, brush.mResources);
        return brush;
    }
    /**
     * Monitors the changes of the brush and modify PDF state respectfully.
     * @param brush The brush
     * @param streamWriter The stream writer
     * @param getResources The get resources delegate.
     * @param saveChanges if set to true the changes should be saved anyway.
     * @param currentColorSpace The current color space.
     */
    /* tslint:disable-next-line:max-line-length */
    monitorChanges(brush, streamWriter, getResources, saveChanges, currentColorSpace) {
        let diff = false;
        if (brush !== this) {
            //  Set the Pattern colour space.
            streamWriter.setColorSpace('Pattern', this.mStroking);
            //  Set the pattern for non-stroking operations.
            let resources1 = getResources.getResources();
            let name1 = resources1.getName(this);
            streamWriter.setColourWithPattern(null, name1, this.mStroking);
            diff = true;
        }
        else if (brush instanceof PdfTilingBrush) {
            //  Set the /Pattern colour space.
            streamWriter.setColorSpace('Pattern', this.mStroking);
            //  Set the pattern for non-stroking operations.
            let resources = getResources.getResources();
            let name = resources.getName(this);
            streamWriter.setColourWithPattern(null, name, this.mStroking);
            diff = true;
        }
        return diff;
    }
    /**
     * Resets the changes, which were made by the brush.
     * In other words resets the state to the initial one.
     * @param streamWriter The stream writer.
     */
    resetChanges(streamWriter) {
        //  We shouldn't do anything to reset changes.
        //  All changes will be reset automatically by setting a new colour space.
    }
    /* tslint:enable */
    // IPdfWrapper Members
    /**
     * Gets the `element`.
     * @public
     */
    get element() {
        return this.brushStream;
    }
}
