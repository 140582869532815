/**
 * Dictionary class
 * @private
 * @hidden
 */
export class TemporaryDictionary {
    constructor() {
        /**
         * @hidden
         * @private
         */
        this.mKeys = [];
        /**
         * @hidden
         * @private
         */
        this.mValues = [];
    }
    /**
     * @hidden
     * @private
     */
    size() {
        return this.mKeys.length;
    }
    /**
     * @hidden
     * @private
     */
    add(key, value) {
        if (key === undefined || key === null || value === undefined || value === null) {
            throw new ReferenceError('Provided key or value is not valid.');
        }
        let index = this.mKeys.indexOf(key);
        if (index < 0) {
            this.mKeys.push(key);
            this.mValues.push(value);
            return 1;
        }
        else {
            throw new RangeError('An item with the same key has already been added.');
        }
    }
    /**
     * @hidden
     * @private
     */
    keys() {
        return this.mKeys;
    }
    /**
     * @hidden
     * @private
     */
    values() {
        return this.mValues;
    }
    /**
     * @hidden
     * @private
     */
    getValue(key) {
        if (key === undefined || key === null) {
            throw new ReferenceError('Provided key is not valid.');
        }
        let index = this.mKeys.indexOf(key);
        if (index < 0) {
            throw new RangeError('No item with the specified key has been added.');
        }
        else {
            return this.mValues[index];
        }
    }
    /**
     * @hidden
     * @private
     */
    setValue(key, value) {
        if (key === undefined || key === null) {
            throw new ReferenceError('Provided key is not valid.');
        }
        let index = this.mKeys.indexOf(key);
        if (index < 0) {
            this.mKeys.push(key);
            this.mValues.push(value);
        }
        else {
            this.mValues[index] = value;
        }
    }
    /**
     * @hidden
     * @private
     */
    remove(key) {
        if (key === undefined || key === null) {
            throw new ReferenceError('Provided key is not valid.');
        }
        let index = this.mKeys.indexOf(key);
        if (index < 0) {
            throw new RangeError('No item with the specified key has been added.');
        }
        else {
            this.mKeys.splice(index, 1);
            this.mValues.splice(index, 1);
            return true;
        }
    }
    /**
     * @hidden
     * @private
     */
    containsKey(key) {
        if (key === undefined || key === null) {
            throw new ReferenceError('Provided key is not valid.');
        }
        let index = this.mKeys.indexOf(key);
        if (index < 0) {
            return false;
        }
        return true;
    }
    /**
     * @hidden
     * @private
     */
    clear() {
        this.mKeys = [];
        this.mValues = [];
    }
}
