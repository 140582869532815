/**
 * Coordinates of Position for `PointF`.
 * @private
 */
export class PointF {
    constructor(x, y) {
        if (typeof x === 'undefined') {
            this.x = 0;
            this.y = 0;
        }
        else {
            if (x !== null) {
                this.x = x;
            }
            else {
                this.x = 0;
            }
            if (y !== null) {
                this.y = y;
            }
            else {
                this.y = 0;
            }
        }
    }
}
/**
 * Width and Height as `Size`.
 * @private
 */
export class SizeF {
    constructor(width, height) {
        if (typeof height === 'undefined') {
            this.height = 0;
            this.width = 0;
        }
        else {
            if (height !== null) {
                this.height = height;
            }
            else {
                this.height = 0;
            }
            if (width !== null) {
                this.width = width;
            }
            else {
                this.width = 0;
            }
        }
    }
}
/**
 * `RectangleF` with Position and size.
 * @private
 */
export class RectangleF {
    constructor(arg1, arg2, arg3, arg4) {
        if (typeof arg1 === typeof arg1 && typeof arg1 === 'undefined') {
            this.x = 0;
            this.y = 0;
            this.height = 0;
            this.width = 0;
        }
        else {
            if (arg1 instanceof PointF && arg2 instanceof SizeF && typeof arg3 === 'undefined') {
                let pointf = arg1;
                this.x = pointf.x;
                this.y = pointf.y;
                let sizef = arg2;
                this.height = sizef.height;
                this.width = sizef.width;
            }
            else {
                let x = arg1;
                let y = arg2;
                let width = arg3;
                let height = arg4;
                this.x = x;
                this.y = y;
                this.height = height;
                this.width = width;
            }
        }
    }
}
/**
 * `Rectangle` with left, right, top and bottom.
 * @private
 */
export class Rectangle {
    /**
     * Instance of `RectangleF` class with X, Y, Width and Height.
     * @private
     */
    constructor(left, top, right, bottom) {
        this.left = left;
        this.top = top;
        this.right = right;
        this.bottom = bottom;
    }
    /**
     * Gets a value of width
     */
    get width() {
        return this.right - this.left;
    }
    /**
     * Gets a value of height
     */
    get height() {
        return this.bottom - this.top;
    }
    /**
     * Gets a value of Top and Left
     */
    get topLeft() {
        return new PointF(this.left, this.top);
    }
    /**
     * Gets a value of size
     */
    get size() {
        return new SizeF(this.width, this.height);
    }
    toString() {
        return this.topLeft + 'x' + this.size;
    }
}
