/**
 * `PdfBrush` class provides objects used to fill the interiors of graphical shapes such as rectangles,
 * ellipses, pies, polygons, and paths.
 * @private
 */
export class PdfBrush {
    /**
     * Creates instanceof `PdfBrush` class.
     * @hidden
     * @private
     */
    constructor() {
        //
    }
    //IClonable implementation
    clone() {
        return this;
    }
}
