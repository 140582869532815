/**
 * PdfFillElement.ts class for EJ2-PDF
 */
import { PdfDrawElement } from './draw-element';
import { PdfPen } from './../../pdf-pen';
import { PdfColor } from './../../pdf-color';
/**
 * Represents a base class for all page graphics elements.
 */
export class PdfFillElement extends PdfDrawElement {
    /**
     * Initializes a new instance of the `PdfFillElement` class.
     * @protected
     */
    constructor(arg1, arg2) {
        super();
        // Fields
        /**
         * Internal variable to store pen.
         * @private
         */
        this.mbrush = null;
        if (typeof arg1 === 'undefined') {
            //
        }
        else if (arg1 instanceof PdfPen) {
            super(arg1);
        }
        else {
            this.mbrush = arg2;
        }
    }
    // Properties
    /**
     * Gets or sets a brush of the element.
     * @public
     */
    get brush() {
        return this.mbrush;
    }
    set brush(value) {
        this.mbrush = value;
    }
    // Implementation
    /**
     * Gets the pen. If both pen and brush are not explicitly defined, default pen will be used.
     * @protected
     */
    obtainPen() {
        return ((this.mbrush == null) && (this.pen == null)) ? new PdfPen(new PdfColor(0, 0, 0)) : this.pen;
    }
}
