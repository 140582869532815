/**
 * TtfGlyphInfo.ts class for EJ2-PDF
 */
export class TtfGlyphInfo {
    //Properties
    /**
     * Gets a value indicating whether this TtfGlyphInfo is empty.
     */
    get empty() {
        let empty = (this.index === this.width && this.width === this.charCode && this.charCode === 0);
        return empty;
    }
    //IComparable implementation
    /**
     * Compares two WidthDescriptor objects.
     */
    compareTo(obj) {
        let glyph = obj;
        return this.index - glyph.index;
    }
}
