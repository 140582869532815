/**
 * PdfAutomaticFieldInfo.ts class for EJ2-PDF
 * @private
 */
import { PointF } from './../../drawing/pdf-drawing';
import { PdfAutomaticField } from './automatic-field';
/**
 * Represents information about the automatic field.
 * @private
 */
export class PdfAutomaticFieldInfo {
    constructor(field, location, scaleX, scaleY) {
        // Fields
        /**
         * Internal variable to store location of the field.
         * @private
         */
        this.pageNumberFieldLocation = new PointF();
        /**
         * Internal variable to store field.
         * @private
         */
        this.pageNumberField = null;
        /**
         * Internal variable to store x scaling factor.
         * @private
         */
        this.scaleX = 1;
        /**
         * Internal variable to store y scaling factor.
         * @private
         */
        this.scaleY = 1;
        if (typeof location === 'undefined' && field instanceof PdfAutomaticFieldInfo) {
            this.pageNumberField = field.field;
            this.pageNumberFieldLocation = field.location;
            this.scaleX = field.scalingX;
            this.scaleY = field.scalingY;
        }
        else if (typeof scaleX === 'undefined' && location instanceof PointF && field instanceof PdfAutomaticField) {
            this.pageNumberField = field;
            this.pageNumberFieldLocation = location;
        }
        else {
            this.pageNumberField = field;
            this.pageNumberFieldLocation = location;
            this.scaleX = scaleX;
            this.scaleY = scaleY;
        }
    }
    /* tslint:enable */
    // Properties
    /**
     * Gets or sets the location.
     * @private
     */
    get location() {
        return this.pageNumberFieldLocation;
    }
    set location(value) {
        this.pageNumberFieldLocation = value;
    }
    /**
     * Gets or sets the field.
     * @private
     */
    get field() {
        return this.pageNumberField;
    }
    set field(value) {
        this.pageNumberField = value;
    }
    /**
     * Gets or sets the scaling X factor.
     * @private
     */
    get scalingX() {
        return this.scaleX;
    }
    set scalingX(value) {
        this.scaleX = value;
    }
    /**
     * Gets or sets the scaling Y factor.
     * @private
     */
    get scalingY() {
        return this.scaleY;
    }
    set scalingY(value) {
        this.scaleY = value;
    }
}
