import { PdfArray } from './../primitives/pdf-array';
import { PdfPageLayerCollection } from './pdf-page-layer-collection';
import { DictionaryProperties } from './../input-output/pdf-dictionary-properties';
import { PdfResources } from './../graphics/pdf-resources';
/**
 * The abstract base class for all pages,
 * `PdfPageBase` class provides methods and properties to create PDF pages and its elements.
 * @private
 */
export class PdfPageBase {
    //constructors
    /**
     * Initializes a new instance of the `PdfPageBase` class.
     * @private
     */
    constructor(dictionary) {
        /**
         * `Index` of the default layer.
         * @default -1.
         * @private
         */
        this.defLayerIndex = -1;
        /**
         * Local variable to store if page `updated`.
         * @default false.
         * @private
         */
        this.modified = false;
        /**
         * Instance of `DictionaryProperties` class.
         * @hidden
         * @private
         */
        this.dictionaryProperties = new DictionaryProperties();
        this.pageDictionary = dictionary;
    }
    //Properties
    /**
     * Gets the `section` of a page.
     * @private
     */
    get section() {
        // if (this.pdfSection === null) {
        //     throw new Error('PdfException : Page must be added to some section before using.');
        // }
        return this.pdfSection;
    }
    set section(value) {
        this.pdfSection = value;
    }
    /**
     * Gets the page `dictionary`.
     * @private
     */
    get dictionary() {
        return this.pageDictionary;
    }
    /**
     * Gets the wrapped `element`.
     * @private
     */
    get element() {
        return this.pageDictionary;
    }
    /**
     * Gets the `default layer` of the page (Read only).
     * @private
     */
    get defaultLayer() {
        let layer = this.layers;
        let index = this.defaultLayerIndex;
        let returnlayer = layer.items(index);
        return returnlayer;
    }
    /**
     * Gets or sets `index of the default layer`.
     * @private
     */
    get defaultLayerIndex() {
        if (this.layerCollection.count === 0 || this.defLayerIndex === -1) {
            let layer = this.layerCollection.add();
            this.defLayerIndex = this.layerCollection.indexOf(layer);
        }
        return this.defLayerIndex;
    }
    /**
     * Gets the collection of the page's `layers` (Read only).
     * @private
     */
    get layers() {
        if (this.layerCollection == null || typeof this.layerCollection === 'undefined') {
            this.layerCollection = new PdfPageLayerCollection(this);
        }
        return this.layerCollection;
    }
    /**
     * Return an instance of `PdfResources` class.
     * @private
     */
    getResources() {
        if (this.resources == null) {
            this.resources = new PdfResources();
            this.dictionary.items.setValue(this.dictionaryProperties.resources, this.resources);
        }
        return this.resources;
    }
    /**
     * Gets `array of page's content`.
     * @private
     */
    get contents() {
        let obj = this.pageDictionary.items.getValue(this.dictionaryProperties.contents);
        let contents = obj;
        let rh = obj;
        if (contents == null) {
            contents = new PdfArray();
            this.pageDictionary.items.setValue(this.dictionaryProperties.contents, contents);
        }
        return contents;
    }
    /**
     * Gets or sets` index of the default layer`.
     * @private
     */
    set defaultLayerIndex(value) {
        if (value < 0 || value > this.layers.count - 1) {
            throw new Error('ArgumentOutOfRangeException : value, Index can not be less 0 and greater Layers.Count - 1');
        }
        else {
            this.defLayerIndex = value;
            this.modified = true;
        }
    }
    /**
     * Sets the `resources`.
     * @private
     */
    setResources(res) {
        this.resources = res;
        this.dictionary.items.setValue(this.dictionaryProperties.resources, this.resources);
        this.modified = true;
    }
}
