/**
 * `PdfBoolean` class is used to perform boolean related primitive operations.
 * @private
 */
export class PdfBoolean {
    //constructor
    /**
     * Initializes a new instance of the `PdfBoolean` class.
     * @private
     */
    constructor(value) {
        /**
         * Internal variable to store the `position`.
         * @default -1
         * @private
         */
        this.currentPosition = -1;
        this.value = value;
    }
    //Properties
    /**
     * Gets or sets the `Status` of the specified object.
     * @private
     */
    get status() {
        return this.objectStatus;
    }
    set status(value) {
        this.objectStatus = value;
    }
    /**
     * Gets or sets a value indicating whether this document `is saving` or not.
     * @private
     */
    get isSaving() {
        return this.saving;
    }
    set isSaving(value) {
        this.saving = value;
    }
    /**
     * Gets or sets the `index` value of the specified object.
     * @private
     */
    get objectCollectionIndex() {
        return this.index;
    }
    set objectCollectionIndex(value) {
        this.index = value;
    }
    /**
     * Gets or sets the `position` of the object.
     * @private
     */
    get position() {
        return this.currentPosition;
    }
    set position(value) {
        this.currentPosition = value;
    }
    /**
     * Returns `cloned object`.
     * @private
     */
    get clonedObject() {
        let rValue = null;
        return rValue;
    }
    /**
     * `Saves` the object using the specified writer.
     * @private
     */
    save(writer) {
        writer.write(this.boolToStr(this.value));
    }
    /**
     * Creates a `copy of PdfBoolean`.
     * @private
     */
    clone(crossTable) {
        let newNumber = new PdfBoolean(this.value);
        return newNumber;
    }
    // Implementation
    /**
     * Converts `boolean to string` - 0/1 'true'/'false'.
     * @private
     */
    boolToStr(value) {
        return value ? 'true' : 'false';
    }
}
