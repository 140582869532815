/**
 * ShapeLayouter.ts class for EJ2-PDF
 * @private
 */
import { ElementLayouter, PdfLayoutResult } from './element-layouter';
import { RectangleF, PointF } from './../../../drawing/pdf-drawing';
import { PdfLayoutBreakType, PdfLayoutType } from './../../figures/enum';
import { BeginPageLayoutEventArgs, EndPageLayoutEventArgs } from './../../../structured-elements/grid/layout/grid-layouter';
/**
 * ShapeLayouter class.
 * @private
 */
export class ShapeLayouter extends ElementLayouter {
    // Constructors
    /**
     * Initializes a new instance of the `ShapeLayouter` class.
     * @private
     */
    constructor(element) {
        super(element);
        // Fields
        /**
         * Initializes the object to store `older form elements` of previous page.
         * @default 0
         * @private
         */
        this.olderPdfForm = 0;
        /**
         * The `bounds` of the shape element.
         * * @default new RectangleF()
         * @private
         */
        this.shapeBounds = new RectangleF();
        /**
         * Total Page size of the web page.
         * * @default 0
         * @private
         */
        this.totalPageSize = 0;
    }
    // Properties
    /**
     * Gets shape element.
     * @private
     */
    get element() {
        return this.elements;
    }
    // Implementation
    /**
     * Layouts the element.
     * @private
     */
    layoutInternal(param) {
        let currentPage = param.page;
        let currentBounds = param.bounds;
        let shapeLayoutBounds = this.element.getBounds();
        shapeLayoutBounds.x = 0;
        shapeLayoutBounds.y = 0;
        /* tslint:disable */
        let isEmpty = (this.shapeBounds.x === this.shapeBounds.y && this.shapeBounds.y === this.shapeBounds.width && this.shapeBounds.width === this.shapeBounds.height && this.shapeBounds.height === 0) ? true : false;
        /* tslint:enable */
        if ((this.isPdfGrid) && (!(isEmpty))) {
            shapeLayoutBounds = this.shapeBounds;
        }
        let result = null;
        let pageResult = new ShapeLayoutResult();
        pageResult.page = currentPage;
        /*tslint:disable:no-constant-condition */
        while (true) {
            // Raise event.
            let result1 = this.raiseBeforePageLayout(currentPage, currentBounds);
            currentBounds = result1.currentBounds;
            let endArgs = null;
            if (!result1.cancel) {
                pageResult = this.layoutOnPage(currentPage, currentBounds, shapeLayoutBounds, param);
                // Raise event.
                endArgs = this.raiseEndPageLayout(pageResult);
                result1.cancel = (endArgs === null) ? false : endArgs.cancel;
            }
            if (!pageResult.end && !result1.cancel) {
                currentBounds = this.getPaginateBounds(param);
                shapeLayoutBounds = this.getNextShapeBounds(shapeLayoutBounds, pageResult);
                currentPage = (endArgs === null || endArgs.nextPage === null) ?
                    this.getNextPage(currentPage) : endArgs.nextPage;
                if (this.isPdfGrid) {
                    result = this.getLayoutResult(pageResult);
                    break;
                }
            }
            else {
                result = this.getLayoutResult(pageResult);
                break;
            }
        }
        return result;
    }
    /**
     * Raises BeforePageLayout event.
     * @private
     */
    raiseBeforePageLayout(currentPage, currentBounds) {
        let cancel = false;
        if (this.element.raiseBeginPageLayout) {
            let args = new BeginPageLayoutEventArgs(currentBounds, currentPage);
            this.element.onBeginPageLayout(args);
            cancel = args.cancel;
            currentBounds = args.bounds;
        }
        return { currentBounds: currentBounds, cancel: cancel };
    }
    /**
     * Raises PageLayout event if needed.
     * @private
     */
    raiseEndPageLayout(pageResult) {
        let args = null;
        if (this.element.raiseEndPageLayout) {
            let res = this.getLayoutResult(pageResult);
            args = new EndPageLayoutEventArgs(res);
            this.element.onEndPageLayout(args);
        }
        return args;
    }
    /**
     * Creates layout result.
     * @private
     */
    getLayoutResult(pageResult) {
        let result = new PdfLayoutResult(pageResult.page, pageResult.bounds);
        return result;
    }
    /**
     * Calculates the next active shape bounds.
     * @private
     */
    getNextShapeBounds(shapeLayoutBounds, pageResult) {
        let layoutedBounds = pageResult.bounds;
        shapeLayoutBounds.y = (shapeLayoutBounds.y + layoutedBounds.height);
        shapeLayoutBounds.height = (shapeLayoutBounds.height - layoutedBounds.height);
        return shapeLayoutBounds;
    }
    /**
     * Layouts the element on the current page.
     * @private
     */
    layoutOnPage(currentPage, curBounds, sBounds, param) {
        let result = new ShapeLayoutResult();
        curBounds = this.checkCorrectCurrentBounds(currentPage, curBounds, param);
        let fitToPage = this.fitsToBounds(curBounds, sBounds);
        let canDraw = !((param.format.break === PdfLayoutBreakType.FitElement)
            && (!fitToPage && (currentPage === param.page)));
        let shapeFinished = false;
        if (canDraw) {
            let drawRectangle = this.getDrawBounds(curBounds, sBounds);
            this.drawShape(currentPage.graphics, curBounds, drawRectangle);
            result.bounds = this.getPageResultBounds(curBounds, sBounds);
            shapeFinished = ((curBounds.height) >= (sBounds.height));
        }
        result.end = (shapeFinished || (param.format.layout === PdfLayoutType.OnePage));
        result.page = currentPage;
        return result;
    }
    /**
     * Returns Rectangle for element drawing on the page.
     * @private
     */
    getDrawBounds(currentBounds, shapeLayoutBounds) {
        let result = currentBounds;
        result.y = (result.y - shapeLayoutBounds.y);
        result.height = (result.height + shapeLayoutBounds.y);
        return result;
    }
    /**
     * Draws the shape.
     * @private
     */
    drawShape(g, currentBounds, drawRectangle) {
        let gState = g.save();
        try {
            g.setClip(currentBounds);
            this.element.drawGraphicsHelper(g, new PointF(drawRectangle.x, drawRectangle.y));
        }
        finally {
            g.restore(gState);
        }
    }
    /**
     * Corrects current bounds on the page.
     * @protected
     */
    checkCorrectCurrentBounds(currentPage, curBounds, param) {
        let pageSize = currentPage.graphics.clientSize;
        curBounds.width = (curBounds.width > 0) ? curBounds.width : (pageSize.width - curBounds.x);
        curBounds.height = (curBounds.height > 0) ? curBounds.height : (pageSize.height - curBounds.y);
        if (this.isPdfGrid) {
            curBounds.height = (curBounds.height - this.bottomCellPadding);
        }
        return curBounds;
    }
    /**
     * Calculates bounds where the shape was layout on the page.
     * @private
     */
    getPageResultBounds(currentBounds, shapeLayoutBounds) {
        let result = currentBounds;
        result.height = Math.min(result.height, shapeLayoutBounds.height);
        return result;
    }
    /**
     * Checks whether shape rectangle fits to the lay outing bounds.
     * @private
     */
    fitsToBounds(currentBounds, shapeLayoutBounds) {
        let fits = (shapeLayoutBounds.height <= currentBounds.height);
        return fits;
    }
}
/**
 * Initializes the offset `index`.
 * * @default 0
 * @private
 */
ShapeLayouter.index = 0;
/**
 * Initializes the `difference in page height`.
 * * @default 0
 * @private
 */
ShapeLayouter.splitDiff = 0;
/**
 * Determines the `end of Vertical offset` values.
 * * @default false
 * @private
 */
ShapeLayouter.last = false;
/**
 * Determines the document link annotation `border width`.
 * * @default 0
 * @private
 */
ShapeLayouter.borderWidth = 0;
/**
 * Contains lay outing result settings.
 * @private
 */
class ShapeLayoutResult {
}
