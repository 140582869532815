/**
 * PdfSectionTemplate.ts class for EJ2-PDF
 */
import { PdfDocumentTemplate } from './../document/pdf-document-template';
/**
 * Represents a `page template` for all the pages in the section.
 */
export class PdfSectionTemplate extends PdfDocumentTemplate {
    // Properties
    /**
     * Gets or sets value indicating whether parent `Left page template should be used or not`.
     * @private
     */
    get applyDocumentLeftTemplate() {
        return this.leftValue;
    }
    set applyDocumentLeftTemplate(value) {
        this.leftValue = value;
    }
    /**
     * Gets or sets value indicating whether parent `Top page template should be used or not`.
     * @private
     */
    get applyDocumentTopTemplate() {
        return this.topValue;
    }
    set applyDocumentTopTemplate(value) {
        this.topValue = value;
    }
    /**
     * Gets or sets value indicating whether parent `Right page template should be used or not`.
     * @private
     */
    get applyDocumentRightTemplate() {
        return this.rightValue;
    }
    set applyDocumentRightTemplate(value) {
        this.rightValue = value;
    }
    /**
     * Gets or sets value indicating whether parent `Bottom page template should be used or not`.
     * @private
     */
    get applyDocumentBottomTemplate() {
        return this.bottomValue;
    }
    set applyDocumentBottomTemplate(value) {
        this.bottomValue = value;
    }
    /**
     * Gets or sets value indicating whether the `stamp value` is true or not.
     * @private
     */
    get applyDocumentStamps() {
        return this.stampValue;
    }
    set applyDocumentStamps(value) {
        this.stampValue = value;
    }
    // Constructors
    /**
     * `Creates a new object`.
     * @private
     */
    constructor() {
        super();
        this.leftValue = this.topValue = this.rightValue = this.bottomValue = this.stampValue = true;
    }
}
