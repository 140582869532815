/**
 * PdfResources.ts class for EJ2-PDF
 */
import { PdfDictionary } from './../primitives/pdf-dictionary';
import { TemporaryDictionary } from './../collections/object-object-pair/dictionary';
import { PdfName } from './../primitives/pdf-name';
import { PdfArray } from './../primitives/pdf-array';
import { PdfReferenceHolder } from './../primitives/pdf-reference';
import { PdfCrossTable } from './../input-output/pdf-cross-table';
import { PdfFont } from './fonts/pdf-font';
import { PdfTemplate } from './figures/pdf-template';
import { PdfBrush } from './brushes/pdf-brush';
import { PdfTransparency } from './pdf-transparency';
import { PdfBitmap } from './../graphics/images/pdf-bitmap';
import { PdfImage } from './../graphics/images/pdf-image';
import { PdfGradientBrush } from './brushes/pdf-gradient-brush';
import { PdfTilingBrush } from './brushes/pdf-tiling-brush';
/**
 * `PdfResources` class used to set resource contents like font, image.
 * @private
 */
export class PdfResources extends PdfDictionary {
    constructor(baseDictionary) {
        super(baseDictionary);
        /**
         * Dictionary for the `properties names`.
         * @private
         */
        this.properties = new PdfDictionary();
    }
    //Properties
    /**
     * Gets the `font names`.
     * @private
     */
    get names() {
        return this.getNames();
    }
    /**
     * Get or set the `page document`.
     * @private
     */
    get document() {
        return this.pdfDocument;
    }
    set document(value) {
        this.pdfDocument = value;
    }
    //Public Methods
    /**
     * `Generates name` for the object and adds to the resource if the object is new.
     * @private
     */
    getName(obj) {
        let primitive = obj.element;
        let name = null;
        if (this.names.containsKey(primitive)) {
            name = this.names.getValue(primitive);
        }
        // Object is new.
        if (name == null) {
            let sName = this.generateName();
            name = new PdfName(sName);
            this.names.setValue(primitive, name);
            if (obj instanceof PdfFont) {
                this.add(obj, name);
            }
            else if (obj instanceof PdfTemplate) {
                this.add(obj, name);
            }
            else if (obj instanceof PdfGradientBrush || obj instanceof PdfTilingBrush) {
                this.add(obj, name);
            }
            else if (obj instanceof PdfTransparency) {
                this.add(obj, name);
            }
            else if (obj instanceof PdfImage || obj instanceof PdfBitmap) {
                this.add(obj, name);
            }
        }
        return name;
    }
    /**
     * Gets `resource names` to font dictionaries.
     * @private
     */
    getNames() {
        if (this.pdfNames == null) {
            this.pdfNames = new TemporaryDictionary();
        }
        let fonts = this.items.getValue(this.dictionaryProperties.font);
        if (fonts != null) {
            let reference = fonts;
            let dictionary = fonts;
            dictionary = PdfCrossTable.dereference(fonts);
        }
        return this.pdfNames;
    }
    /**
     * Add `RequireProcedureSet` into procset array.
     * @private
     */
    requireProcedureSet(procedureSetName) {
        if (procedureSetName == null) {
            throw new Error('ArgumentNullException:procedureSetName');
        }
        let procSets = this.items.getValue(this.dictionaryProperties.procset);
        if (procSets == null) {
            procSets = new PdfArray();
            this.items.setValue(this.dictionaryProperties.procset, procSets);
        }
        let name = new PdfName(procedureSetName);
        if (!procSets.contains(name)) {
            procSets.add(name);
        }
    }
    //Helper Methods
    /**
     * `Remove font` from array.
     * @private
     */
    removeFont(name) {
        let key = null;
        let keys = this.pdfNames.keys();
        for (let index = 0; index < this.pdfNames.size(); index++) {
            if (this.pdfNames.getValue(keys[index]) === new PdfName(name)) {
                key = keys[index];
                break;
            }
        }
        if (key != null) {
            this.pdfNames.remove(key);
        }
    }
    /**
     * Generates `Unique string name`.
     * @private
     */
    generateName() {
        let name = Guid.getNewGuidString();
        return name;
    }
    add(arg1, arg2) {
        if (arg1 instanceof PdfFont) {
            let dictionary = null;
            let fonts = this.items.getValue(this.dictionaryProperties.font);
            if (fonts != null) {
                let reference = fonts;
                dictionary = fonts;
                dictionary = fonts;
            }
            else {
                dictionary = new PdfDictionary();
                this.items.setValue(this.dictionaryProperties.font, dictionary);
            }
            dictionary.items.setValue(arg2.value, new PdfReferenceHolder(arg1.element));
        }
        else if (arg1 instanceof PdfTemplate) {
            let xobjects;
            xobjects = this.items.getValue(this.dictionaryProperties.xObject);
            // Create fonts dictionary.
            if (xobjects == null) {
                xobjects = new PdfDictionary();
                this.items.setValue(this.dictionaryProperties.xObject, xobjects);
            }
            xobjects.items.setValue(arg2.value, new PdfReferenceHolder(arg1.element));
        }
        else if (arg1 instanceof PdfBrush) {
            if (arg1 instanceof PdfGradientBrush || arg1 instanceof PdfTilingBrush) {
                let savable = arg1.element;
                if (savable != null) {
                    let pattern = this.items.getValue(this.dictionaryProperties.pattern);
                    // Create a new pattern dictionary.
                    if (pattern == null) {
                        pattern = new PdfDictionary();
                        this.items.setValue(this.dictionaryProperties.pattern, pattern);
                    }
                    pattern.items.setValue(arg2.value, new PdfReferenceHolder(savable));
                }
            }
        }
        else if (arg1 instanceof PdfTransparency) {
            let savable = arg1.element;
            let transDic = null;
            transDic = this.items.getValue(this.dictionaryProperties.extGState);
            // Create a new pattern dictionary.
            if (transDic == null) {
                transDic = new PdfDictionary();
                this.items.setValue(this.dictionaryProperties.extGState, transDic);
            }
            transDic.items.setValue(arg2.value, new PdfReferenceHolder(savable));
        }
        else {
            /* tslint:disable */
            let xobjects = this.Dictionary.items.getValue(this.dictionaryProperties.xObject);
            let parentXObjects;
            if (typeof this.pdfDocument !== 'undefined') {
                parentXObjects = this.pdfDocument.sections.element.items.getValue(this.dictionaryProperties.resources).items.getValue(this.dictionaryProperties.xObject);
            }
            let values = this.Dictionary.items.values();
            let hasSameImageStream = false;
            let oldReference;
            if (typeof this.pdfDocument !== 'undefined' && (typeof parentXObjects === undefined || parentXObjects == null)) {
                parentXObjects = new PdfDictionary();
                this.pdfDocument.sections.element.items.getValue(this.dictionaryProperties.resources).items.setValue(this.dictionaryProperties.xObject, parentXObjects);
            }
            else if (typeof this.pdfDocument !== 'undefined') {
                let values = parentXObjects.items.values();
                for (let i = 0; i < values.length; i++) {
                    if (typeof values[i] !== 'undefined' && typeof values[i].element !== 'undefined') {
                        if (values[i].element.data[0] === arg1.element.data[0]) {
                            oldReference = values[i];
                            hasSameImageStream = true;
                        }
                    }
                }
            }
            if (xobjects == null) {
                xobjects = new PdfDictionary();
                this.Dictionary.items.setValue(this.dictionaryProperties.xObject, xobjects);
            }
            if (hasSameImageStream && typeof oldReference !== 'undefined') {
                xobjects.items.setValue(arg2.value, oldReference);
            }
            else {
                let reference = new PdfReferenceHolder(arg1.element);
                xobjects.items.setValue(arg2.value, reference);
                if (typeof this.pdfDocument !== 'undefined') {
                    parentXObjects.items.setValue(arg2.value, reference);
                }
            }
            /* tslint:enable */
        }
    }
}
/* tslint:disable */
/**
 * Used to create new guid for resources.
 * @private
 */
export class Guid {
    /**
     * Generate `new GUID`.
     * @private
     */
    static getNewGuidString() {
        return 'aaaaaaaa-aaaa-4aaa-baaa-aaaaaaaaaaaa'.replace(/[ab]/g, (c) => {
            let random = Math.random() * 16 | 0;
            let result = c === 'a' ? random : (random & 0x3 | 0x8);
            return result.toString(16);
        });
    }
}
/* tslint:enable */ 
