import { PdfPage } from './pdf-page';
import { Dictionary } from './../collections/dictionary';
/**
 * Represents a virtual collection of all the pages in the document.
 * @private
 */
export class PdfDocumentPageCollection {
    //constructor
    /**
     * Initializes a new instance of the `PdfPageCollection` class.
     * @private
     */
    constructor(document) {
        /**
         * It holds the page collection with the `index`.
         * @private
         */
        this.pdfPageCollectionIndex = new Dictionary();
        this.document = document;
    }
    //Property
    /**
     * Gets the total `number of the pages`.
     * @private
     */
    get count() {
        return this.countPages();
    }
    /**
     * Gets a `page index` from the document.
     * @private
     */
    get pageCollectionIndex() {
        return this.pdfPageCollectionIndex;
    }
    add(page) {
        if (typeof page === 'undefined') {
            let page = new PdfPage();
            this.add(page);
            return page;
        }
        else {
            let section = this.getLastSection();
            section.add(page);
        }
    }
    /**
     * Returns `last section` in the document.
     * @private
     */
    getLastSection() {
        let sc = this.document.sections;
        if (sc.section.length === 0) {
            sc.add();
        }
        let section = sc.section[sc.section.length - 1];
        return section;
    }
    /**
     * Called when `new page has been added`.
     * @private
     */
    onPageAdded(args) {
        // if (PageAdded !== null)
        // {
        //     PageAdded(this, args);
        // }
    }
    /**
     * Gets the `total number of pages`.
     * @private
     */
    countPages() {
        let sc = this.document.sections;
        let count = 0;
        for (let index = 0; index < sc.section.length; index++) {
            count += sc.section[index].count;
        }
        return count;
    }
    /**
     * Gets the `page object` from page index.
     * @private
     */
    getPageByIndex(index) {
        return this.getPage(index);
    }
    /**
     * Gets a page by its `index` in the document.
     * @private
     */
    getPage(index) {
        if ((index < 0) || (index >= this.count)) {
            throw Error('ArgumentOutOfRangeException("index", "Value can not be less 0")');
        }
        let page = null;
        let sectionStartIndex = 0;
        let sectionCount = 0;
        let pageIndex = 0;
        let length = this.document.sections.count;
        for (let i = 0; i < length; i++) {
            let section = this.document.sections.section[i];
            sectionCount = section.count;
            pageIndex = index - sectionStartIndex;
            // We found a section containing the page.
            if ((index >= sectionStartIndex && pageIndex < sectionCount)) {
                page = section.getPages()[pageIndex];
                break;
            }
            sectionStartIndex += sectionCount;
        }
        return page;
    }
    /**
     * Gets the `index of` the page in the document.
     * @private
     */
    indexOf(page) {
        let index = -1;
        if (page == null) {
            throw new Error('ArgumentNullException: page');
        }
        else {
            let numPages = 0;
            for (let i = 0, len = this.document.sections.count; i < len; i++) {
                let section = this.document.sections.pdfSectionCollection(i);
                index = section.indexOf(page);
                if (index >= 0) {
                    index += numPages;
                    break;
                }
                else {
                    index = -1;
                }
                numPages += section.count;
            }
        }
        return index;
    }
    /**
     * `Removes` the specified page.
     * @private
     */
    remove(page) {
        if (page == null) {
            throw Error('ArgumentNullException("page")');
        }
        let section = null;
        let len;
        for (let i = 0, len = this.document.sections.count; i < len; i++) {
            section = this.document.sections.pdfSectionCollection(i);
            if (section.pages.contains(page)) {
                section.pages.remove(page);
                break;
            }
        }
        return section;
    }
}
