/**
 * PdfCacheCollection.ts class for EJ2-PDF
 */
import { Dictionary } from './../collections/dictionary';
/**
 * `Collection of the cached objects`.
 * @private
 */
export class PdfCacheCollection {
    // Constructors
    /**
     * Initializes a new instance of the `PdfCacheCollection` class.
     * @private
     */
    constructor() {
        this.referenceObjects = [];
        this.pdfFontCollection = new Dictionary();
    }
    // Public methods
    /**
     * `Searches` for the similar cached object. If is not found - adds the object to the cache.
     * @private
     */
    search(obj) {
        let result = null;
        let group = this.getGroup(obj);
        if (group == null) {
            group = this.createNewGroup();
        }
        else if (group.length > 0) {
            result = group[0];
        }
        group.push(obj);
        return result;
    }
    // Implementation
    /**
     * `Creates` a new group.
     * @private
     */
    createNewGroup() {
        let group = [];
        this.referenceObjects.push(group);
        return group;
    }
    /**
     * `Find and Return` a group.
     * @private
     */
    getGroup(result) {
        let group = null;
        if (result !== null) {
            let len = this.referenceObjects.length;
            for (let i = 0; i < len; i++) {
                if (this.referenceObjects.length > 0) {
                    let tGroup = this.referenceObjects[i];
                    if (tGroup.length > 0) {
                        let representative = tGroup[0];
                        if (result.equalsTo(representative)) {
                            group = tGroup;
                            break;
                        }
                    }
                    else {
                        this.removeGroup(tGroup);
                    }
                }
                len = this.referenceObjects.length;
            }
        }
        return group;
    }
    /**
     * Remove a group from the storage.
     */
    removeGroup(group) {
        if (group !== null) {
            let index = this.referenceObjects.indexOf(group);
            this.referenceObjects.slice(index, index + 1);
        }
    }
    destroy() {
        this.pdfFontCollection = undefined;
        this.referenceObjects = undefined;
    }
}
