import { StreamWriter } from '@syncfusion/ej2-file-utils';
import { Encoding } from '@syncfusion/ej2-file-utils';
import { PdfDocument } from './pdf-document';
/**
 * `PdfDocumentBase` class represent common properties of PdfDocument classes.
 * @private
 */
export class PdfDocumentBase {
    constructor(document) {
        /**
         * If the stream is copied,  then it specifies true.
         * @private
         */
        this.isStreamCopied = false;
        if (document instanceof PdfDocument) {
            this.document = document;
        }
    }
    //Prpperties
    /**
     * Gets the `PDF objects` collection, which stores all objects and references to it..
     * @private
     */
    get pdfObjects() {
        return this.objects;
    }
    /**
     * Gets the `cross-reference` table.
     * @private
     */
    get crossTable() {
        return this.pdfCrossTable;
    }
    /**
     * Gets or sets the current saving `object number`.
     * @private
     */
    get currentSavingObj() {
        return this.currentSavingObject;
    }
    set currentSavingObj(value) {
        this.currentSavingObject = value;
    }
    /**
     * Gets the PDF document `catalog`.
     * @private
     */
    get catalog() {
        return this.pdfCatalog;
    }
    set catalog(value) {
        this.pdfCatalog = value;
    }
    //Public methods
    /**
     * Sets the `main object collection`.
     * @private
     */
    setMainObjectCollection(mainObjectCollection) {
        this.objects = mainObjectCollection;
    }
    /**
     * Sets the `cross table`.
     * @private
     */
    setCrossTable(cTable) {
        this.pdfCrossTable = cTable;
    }
    /**
     * Sets the `catalog`.
     * @private
     */
    setCatalog(catalog) {
        this.pdfCatalog = catalog;
    }
    save(filename) {
        let encoding = new Encoding(true);
        let SW = new StreamWriter(encoding);
        if (typeof filename === 'undefined') {
            let encoding = new Encoding(true);
            let SW = new StreamWriter(encoding);
            return new Promise((resolve, reject) => {
                /* tslint:disable-next-line:no-any */
                let obj = {};
                obj.blobData = new Blob([this.document.docSave(SW, true)], { type: 'application/pdf' });
                resolve(obj);
            });
        }
        else {
            this.document.docSave(SW, filename, true);
        }
    }
    /**
     * `Clone` of parent object - PdfDocument.
     * @private
     */
    clone() {
        return this.document;
    }
}
