/**
 * ElementLayouter.ts class for EJ2-PDF
 */
import { RectangleF } from './../../../drawing/pdf-drawing';
/**
 * Base class for `elements lay outing`.
 * @private
 */
export class ElementLayouter {
    // Constructor
    /**
     * Initializes a new instance of the `ElementLayouter` class.
     * @private
     */
    constructor(element) {
        this.layoutElement = element;
    }
    // Properties
    /**
     * Gets the `element`.
     * @private
     */
    get elements() {
        return this.layoutElement;
    }
    /**
     * Gets the `element`.
     * @private
     */
    getElement() {
        return this.layoutElement;
    }
    // Implementation
    /**
     * `Layouts` the element.
     * @private
     */
    layout(param) {
        return this.layoutInternal(param);
    }
    Layouter(param) {
        return this.layoutInternal(param);
    }
    /**
     * Returns the `next page`.
     * @private
     */
    getNextPage(currentPage) {
        let section = currentPage.section;
        let nextPage = section.add();
        return nextPage;
    }
    getPaginateBounds(param) {
        if ((param == null)) {
            throw new Error('ArgumentNullException : param');
        }
        let result = param.format.usePaginateBounds ? param.format.paginateBounds
            : new RectangleF(param.bounds.x, 0, param.bounds.width, param.bounds.height);
        return result;
    }
}
export class PdfLayoutFormat {
    // Properties
    /**
     * Gets or sets `layout` type of the element.
     * @private
     */
    get layout() {
        // if (typeof this.layoutType === 'undefined' || this.layoutType == null) {
        //      this.layoutType = PdfLayoutType.Paginate;
        // }
        return this.layoutType;
    }
    set layout(value) {
        this.layoutType = value;
    }
    /**
     * Gets or sets `break` type of the element.
     * @private
     */
    get break() {
        // if (typeof this.breakType === 'undefined' || this.boundsSet == null) {
        //      this.breakType = PdfLayoutBreakType.FitPage;
        // }
        return this.breakType;
    }
    set break(value) {
        this.breakType = value;
    }
    /**
     * Gets or sets the `bounds` on the next page.
     * @private
     */
    get paginateBounds() {
        if (typeof this.layoutPaginateBounds === 'undefined' && this.layoutPaginateBounds == null) {
            this.layoutPaginateBounds = new RectangleF(0, 0, 0, 0);
        }
        return this.layoutPaginateBounds;
    }
    set paginateBounds(value) {
        this.layoutPaginateBounds = value;
        this.boundsSet = true;
    }
    /**
     * Gets a value indicating whether [`use paginate bounds`].
     * @private
     */
    get usePaginateBounds() {
        // if (typeof this.boundsSet === 'undefined' || this.boundsSet == null) {
        //      this.boundsSet = false;
        // }
        return this.boundsSet;
    }
    constructor(baseFormat) {
        if (typeof baseFormat === 'undefined') {
            //
        }
        else {
            this.break = baseFormat.break;
            this.layout = baseFormat.layout;
            this.paginateBounds = baseFormat.paginateBounds;
            this.boundsSet = baseFormat.usePaginateBounds;
        }
    }
}
export class PdfLayoutParams {
    // Properties
    /**
     * Gets or sets the layout `page` for the element.
     * @private
     */
    get page() {
        return this.pdfPage;
    }
    set page(value) {
        this.pdfPage = value;
    }
    /**
     * Gets or sets layout `bounds` for the element.
     * @private
     */
    get bounds() {
        return new RectangleF(this.layoutBounds.x, this.layoutBounds.y, this.layoutBounds.width, this.layoutBounds.height);
    }
    set bounds(value) {
        this.layoutBounds = value;
    }
    /**
     * Gets or sets `layout settings` for the element.
     * @private
     */
    get format() {
        return this.layoutFormat;
    }
    set format(value) {
        this.layoutFormat = value;
    }
}
export class PdfLayoutResult {
    // Properties
    /**
     * Gets the last `page` where the element was drawn.
     * @private
     */
    get page() {
        return this.pdfPage;
    }
    /**
     * Gets the `bounds` of the element on the last page where it was drawn.
     * @private
     */
    get bounds() {
        return this.layoutBounds;
    }
    // Constructors
    /**
     * Initializes the new instance of `PdfLayoutResult` class.
     * @private
     */
    constructor(page, bounds) {
        this.pdfPage = page;
        this.layoutBounds = bounds;
    }
}
