import { PointF, RectangleF } from './../drawing/pdf-drawing';
import { PdfArray } from './../primitives/pdf-array';
import { PdfPageRotateAngle } from './../pages/enum';
import { PdfDestinationMode } from './../general/enum';
import { PdfReferenceHolder } from './../primitives/pdf-reference';
import { PdfName } from './../primitives/pdf-name';
import { PdfNumber } from './../primitives/pdf-number';
import { DictionaryProperties } from './../input-output/pdf-dictionary-properties';
/**
 * `PdfDestination` class represents an anchor in the document
 * where bookmarks or annotations can direct when clicked.
 */
export class PdfDestination {
    constructor(arg1, arg2) {
        // Fields
        /**
         * Internal variable for accessing fields from `DictionryProperties` class.
         * @private
         */
        this.dictionaryProperties = new DictionaryProperties();
        /**
         * Type of the `destination`.
         * @private
         */
        this.destinationMode = PdfDestinationMode.Location;
        /**
         * `Zoom` factor.
         * @private
         * @default 0
         */
        this.zoomFactor = 0;
        /**
         * `Location` of the destination.
         * @default new PointF() with 0 ,0 as co-ordinates
         * @private
         */
        this.destinationLocation = new PointF(0, 0);
        /**
         * `Bounds` of the destination as RectangleF.
         * @default RectangleF.Empty
         * @private
         */
        this.bounds = new RectangleF();
        /**
         * Pdf primitive representing `this` object.
         * @private
         */
        this.array = new PdfArray();
        let angle = PdfPageRotateAngle.RotateAngle0;
        this.destinationLocation = new PointF(0, this.destinationLocation.y);
        this.pdfPage = arg1;
        if (arg2 instanceof PointF) {
            this.destinationLocation = arg2;
        }
        else {
            this.bounds = arg2;
        }
    }
    // Properties
    /**
     * Gets and Sets the `zoom` factor.
     * @private
     */
    get zoom() {
        return this.zoomFactor;
    }
    set zoom(value) {
        this.zoomFactor = value;
        this.initializePrimitive();
    }
    /**
     * Gets and Sets the `page` object.
     * @private
     */
    get page() {
        return this.pdfPage;
    }
    set page(value) {
        this.pdfPage = value;
        this.initializePrimitive();
    }
    /**
     * Gets and Sets the destination `mode`.
     * @private
     */
    get mode() {
        return this.destinationMode;
    }
    set mode(value) {
        this.destinationMode = value;
        this.initializePrimitive();
    }
    /**
     * Gets and Sets the `location`.
     * @private
     */
    get location() {
        return this.destinationLocation;
    }
    set location(value) {
        this.destinationLocation = value;
        this.initializePrimitive();
    }
    /**
     * `Translates` co-ordinates to PDF co-ordinate system (lower/left).
     * @private
     */
    pointToNativePdf(page, point) {
        let section = page.section;
        return section.pointToNativePdf(page, point);
    }
    /**
     * `In fills` array by correct values.
     * @private
     */
    initializePrimitive() {
        this.array.clear();
        this.array.add(new PdfReferenceHolder(this.pdfPage));
        switch (this.destinationMode) {
            case PdfDestinationMode.Location:
                let simplePage = this.pdfPage;
                let point = new PointF();
                point = this.pointToNativePdf(simplePage, this.destinationLocation);
                this.array.add(new PdfName(this.dictionaryProperties.xyz));
                this.array.add(new PdfNumber(point.x));
                this.array.add(new PdfNumber(point.y));
                this.array.add(new PdfNumber(this.zoomFactor));
                break;
            case PdfDestinationMode.FitToPage:
                this.array.add(new PdfName(this.dictionaryProperties.fit));
                break;
        }
    }
    /**
     * Gets the `element` representing this object.
     * @private
     */
    get element() {
        this.initializePrimitive();
        return this.array;
    }
}
