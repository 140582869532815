/**
 * PdfTransformationMatrix.ts class for EJ2-PDF
 */
import { PointF } from './../drawing/pdf-drawing';
import { PdfNumber } from './../primitives/pdf-number';
/**
 * Class for representing Root `transformation matrix`.
 */
export class PdfTransformationMatrix {
    constructor(value) {
        /**
         * Value for `angle converting`.
         * @default 180.0 / Math.PI
         * @private
         */
        this.radDegFactor = 180.0 / Math.PI;
        if (typeof value === 'undefined') {
            this.transformationMatrix = new Matrix(1.00, 0.00, 0.00, 1.00, 0.00, 0.00);
        }
        else {
            this.transformationMatrix = new Matrix(1.00, 0.00, 0.00, -1.00, 0.00, 0.00);
        }
    }
    // Properties
    /**
     * Gets or sets the `internal matrix object`.
     * @private
     */
    get matrix() {
        return this.transformationMatrix;
    }
    set matrix(value) {
        this.transformationMatrix = value;
    }
    // Public methods
    /**
     * `Translates` coordinates by specified coordinates.
     * @private
     */
    translate(offsetX, offsetY) {
        this.transformationMatrix.translate(offsetX, offsetY);
    }
    /**
     * `Scales` coordinates by specified coordinates.
     * @private
     */
    scale(scaleX, scaleY) {
        this.transformationMatrix.elements[0] = scaleX;
        this.transformationMatrix.elements[3] = scaleY;
    }
    /**
     * `Rotates` coordinate system in counterclockwise direction.
     * @private
     */
    rotate(angle) {
        //Convert from degree to radian 
        angle = (angle * Math.PI) / 180;
        //Rotation 
        this.transformationMatrix.elements[0] = Math.cos(angle);
        this.transformationMatrix.elements[1] = Math.sin(angle);
        this.transformationMatrix.elements[2] = -Math.sin(angle);
        this.transformationMatrix.elements[3] = Math.cos(angle);
    }
    // Overrides
    /**
     * Gets `PDF representation`.
     * @private
     */
    toString() {
        let builder = '';
        let whitespace = ' ';
        for (let i = 0, len = this.transformationMatrix.elements.length; i < len; i++) {
            let temp = this.matrix.elements[i];
            builder += PdfNumber.floatToString(this.transformationMatrix.elements[i]);
            builder += whitespace;
        }
        return builder;
    }
    // Implementation
    /**
     * `Multiplies` matrices (changes coordinate system.)
     * @private
     */
    multiply(matrix) {
        this.transformationMatrix.multiply(matrix.matrix);
    }
    /**
     * Converts `degrees to radians`.
     * @private
     */
    static degreesToRadians(degreesX) {
        return this.degRadFactor * degreesX;
    }
    /**
     * Converts `radians to degrees`.
     * @private
     */
    radiansToDegrees(radians) {
        return this.radDegFactor * radians;
    }
    /**
     * `Clones` this instance of PdfTransformationMatrix.
     * @private
     */
    clone() {
        return this;
    }
}
// Constants
/**
 * Value for `angle converting`.
 * @default Math.PI / 180.0
 * @private
 */
PdfTransformationMatrix.degRadFactor = Math.PI / 180.0;
export class Matrix {
    constructor(arg1, arg2, arg3, arg4, arg5, arg6) {
        if (typeof arg1 === 'undefined') {
            this.metrixElements = [];
        }
        else if (typeof arg1 === 'number') {
            this.metrixElements = [];
            this.metrixElements.push(arg1);
            this.metrixElements.push(arg2);
            this.metrixElements.push(arg3);
            this.metrixElements.push(arg4);
            this.metrixElements.push(arg5);
            this.metrixElements.push(arg6);
        }
        else {
            this.metrixElements = arg1;
        }
    }
    // Properties
    /**
     * Gets the `elements`.
     * @private
     */
    get elements() {
        return this.metrixElements;
    }
    /**
     * Gets the off set `X`.
     * @private
     */
    get offsetX() {
        return this.metrixElements[4];
    }
    /**
     * Gets the off set `Y`.
     * @private
     */
    get offsetY() {
        return this.metrixElements[5];
    }
    // Implementation
    /**
     * `Translates` coordinates by specified coordinates.
     * @private
     */
    translate(offsetX, offsetY) {
        this.metrixElements[4] = offsetX;
        this.metrixElements[5] = offsetY;
    }
    /**
     * `Translates` the specified offset X.
     * @private
     */
    transform(point) {
        let x = point.x;
        let y = point.y;
        let x2 = x * this.elements[0] + y * this.elements[2] + this.offsetX;
        let y2 = x * this.elements[1] + y * this.elements[3] + this.offsetY;
        return new PointF(x2, y2);
    }
    /**
     * `Multiplies matrices` (changes coordinate system.)
     * @private
     */
    multiply(matrix) {
        let tempMatrix = [];
        tempMatrix.push(this.elements[0] * matrix.elements[0] + this.elements[1] * matrix.elements[2]);
        tempMatrix[1] = (this.elements[0] * matrix.elements[1] + this.elements[1] * matrix.elements[3]);
        tempMatrix[2] = (this.elements[2] * matrix.elements[0] + this.elements[3] * matrix.elements[2]);
        tempMatrix[3] = (this.elements[2] * matrix.elements[1] + this.elements[3] * matrix.elements[3]);
        tempMatrix[4] = (this.offsetX * matrix.elements[0] + this.offsetY * matrix.elements[2] + matrix.offsetX);
        tempMatrix[5] = (this.offsetX * matrix.elements[1] + this.offsetY * matrix.elements[3] + matrix.offsetY);
        for (let i = 0; i < tempMatrix.length; i++) {
            this.elements[i] = tempMatrix[i];
        }
    }
    // IDisposable Members
    /**
     * `Dispose` this instance of PdfTransformationMatrix class.
     * @private
     */
    dispose() {
        this.metrixElements = null;
    }
    // ICloneable Members
    /**
     * `Clones` this instance of PdfTransformationMatrix class.
     * @private
     */
    clone() {
        let m = new Matrix(this.metrixElements);
        return m;
    }
}
