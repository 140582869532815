/**
 * Represent class to store information about `template and value pairs`.
 * @private
 */
export class PdfTemplateValuePair {
    constructor(template, value) {
        // Fields
        /**
         * Internal variable to store template.
         * @default null
         * @private
         */
        this.pdfTemplate = null;
        /**
         * Intenal variable to store value.
         * @private
         */
        this.content = '';
        if (typeof template === 'undefined') {
            //
        }
        else {
            this.template = template;
            this.value = value;
        }
    }
    // Properties
    /**
     * Gets or sets the template.
     * @private
     */
    get template() {
        return this.pdfTemplate;
    }
    set template(value) {
        this.pdfTemplate = value;
    }
    /**
     * Gets or sets the value.
     * @private
     */
    get value() {
        return this.content;
    }
    set value(value) {
        this.content = value;
    }
}
