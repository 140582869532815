import { PdfFontFamily, PdfFontStyle, FontEncoding } from './enum';
import { PdfFont } from './pdf-font';
import { PdfDocument } from './../../document/pdf-document';
import { PdfStandardFontMetricsFactory } from './pdf-standard-font-metrics-factory';
import { PdfDictionary } from './../../primitives/pdf-dictionary';
import { DictionaryProperties } from './../../input-output/pdf-dictionary-properties';
import { PdfName } from './../../primitives/pdf-name';
/**
 * Represents one of the 14 standard fonts.
 * It's used to create a standard PDF font to draw the text in to the PDF.
 * ```typescript
 * // create a new PDF document
 * let document : PdfDocument = new PdfDocument();
 * // add a new page to the document
 * let page1 : PdfPage = document.pages.add();
 * //
 * // create new standard font
 * let font : PdfStandardFont = new PdfStandardFont(PdfFontFamily.Helvetica, 20);
 * //
 * // create black brush
 * let blackBrush : PdfSolidBrush = new PdfSolidBrush(new PdfColor(0, 0, 0));
 * // draw the text
 * page1.graphics.drawString('Hello World', font, blackBrush, new PointF(0, 0));
 * // save the document
 * document.save('output.pdf');
 * // destroy the document
 * document.destroy();
 * ```
 */
export class PdfStandardFont extends PdfFont {
    constructor(fontFamilyPrototype, size, style) {
        super(size, (typeof style === 'undefined') ? ((fontFamilyPrototype instanceof PdfStandardFont) ? fontFamilyPrototype.style : PdfFontStyle.Regular) : style);
        /**
         * Gets `ascent` of the font.
         * @private
         */
        this.dictionaryProperties = new DictionaryProperties();
        /**
         * Gets `encodings` for internal class use.
         * @hidden
         * @private
         */
        this.encodings = ['Unknown', 'StandardEncoding', 'MacRomanEncoding', 'MacExpertEncoding',
            'WinAnsiEncoding', 'PDFDocEncoding', 'IdentityH'];
        if (typeof fontFamilyPrototype === 'undefined') {
            this.pdfFontFamily = PdfFontFamily.Helvetica;
        }
        else if ((fontFamilyPrototype instanceof PdfStandardFont)) {
            this.pdfFontFamily = fontFamilyPrototype.fontFamily;
        }
        else {
            this.pdfFontFamily = fontFamilyPrototype;
        }
        this.checkStyle();
        this.initializeInternals();
    }
    /* tslint:enable */
    //Properties
    /**
     * Gets the `FontFamily`.
     * @private
     */
    get fontFamily() {
        return this.pdfFontFamily;
    }
    //methods
    /**
     * Checks font `style` of the font.
     * @private
     */
    checkStyle() {
        if (this.fontFamily === PdfFontFamily.Symbol || this.fontFamily === PdfFontFamily.ZapfDingbats) {
            let style = this.style;
            style &= ~(PdfFontStyle.Bold | PdfFontStyle.Italic);
            this.setStyle(style);
        }
    }
    /**
     * Returns `width` of the line.
     * @public
     */
    getLineWidth(line, format) {
        if (line == null) {
            throw new Error('ArgumentNullException:line');
        }
        let width = 0;
        let name = this.name;
        line = PdfStandardFont.convert(line);
        for (let i = 0, len = line.length; i < len; i++) {
            let ch = line[i];
            let charWidth = this.getCharWidthInternal(ch, format);
            width += charWidth;
        }
        let size = this.metrics.getSize(format);
        width *= (PdfFont.charSizeMultiplier * size);
        width = this.applyFormatSettings(line, format, width);
        return width;
    }
    /**
     * Checks whether fonts are `equals`.
     * @private
     */
    equalsToFont(font) {
        let equal = false;
        let stFont = font;
        if (stFont != null) {
            let fontFamilyEqual = (this.fontFamily === stFont.fontFamily);
            let lineReducer = (~(PdfFontStyle.Underline | PdfFontStyle.Strikeout));
            let styleEqual = (this.style & lineReducer) === (stFont.style & lineReducer);
            equal = (fontFamilyEqual && styleEqual);
        }
        return equal;
    }
    /**
     * `Initializes` font internals..
     * @private
     */
    initializeInternals() {
        let equalFont = null;
        // if (PdfDocument.EnableCache) {
        equalFont = PdfDocument.cache.search(this);
        // }
        let internals = null;
        // if (equalFont == null) {
        // Create font metrics.
        let metrics = PdfStandardFontMetricsFactory.getMetrics(this.pdfFontFamily, this.style, this.size);
        this.metrics = metrics;
        internals = this.createInternals();
        this.setInternals(internals);
    }
    /**
     * `Creates` font`s dictionary.
     * @private
     */
    createInternals() {
        let dictionary = new PdfDictionary();
        dictionary.items.setValue(this.dictionaryProperties.type, new PdfName(this.dictionaryProperties.font));
        dictionary.items.setValue(this.dictionaryProperties.subtype, new PdfName(this.dictionaryProperties.type1));
        dictionary.items.setValue(this.dictionaryProperties.baseFont, new PdfName(this.metrics.postScriptName));
        if (this.fontFamily !== PdfFontFamily.Symbol && this.fontFamily !== PdfFontFamily.ZapfDingbats) {
            let encoding = this.encodings[FontEncoding.WinAnsiEncoding];
            dictionary.items.setValue(this.dictionaryProperties.encoding, new PdfName(encoding));
        }
        return dictionary;
    }
    /**
     * Returns `width` of the char. This methods doesn`t takes into consideration font`s size.
     * @private
     */
    getCharWidthInternal(charCode, format) {
        let width = 0;
        let code = 0;
        code = charCode.charCodeAt(0);
        if (this.name === '0' || this.name === '1' || this.name === '2' ||
            this.name === '3' || this.name === '4') {
            code = code - PdfStandardFont.charOffset;
        }
        code = (code >= 0 && code !== 128) ? code : 0;
        let metrics = this.metrics;
        let widthTable = metrics.widthTable;
        width = widthTable.items(code);
        return width;
    }
    /**
     * `Converts` the specified text.
     * @private
     */
    static convert(text) {
        return text;
    }
}
//Constants
/**
 * First character `position`.
 * @private
 */
PdfStandardFont.charOffset = 32;
