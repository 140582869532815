/**
 * PdfCatalog.ts class for EJ2-PDF
 */
import { PdfDictionary } from './../primitives/pdf-dictionary';
import { DictionaryProperties } from './../input-output/pdf-dictionary-properties';
import { PdfName } from './../primitives/pdf-name';
import { PdfReferenceHolder } from './../primitives/pdf-reference';
/**
 * `PdfCatalog` class represents internal catalog of the Pdf document.
 * @private
 */
export class PdfCatalog extends PdfDictionary {
    //constructor
    /**
     * Initializes a new instance of the `PdfCatalog` class.
     * @private
     */
    constructor() {
        super();
        //fields
        /**
         * Internal variable to store collection of `sections`.
         * @default null
         * @private
         */
        this.sections = null;
        /**
         * Internal variable for accessing fields from `DictionryProperties` class.
         * @private
         */
        this.tempDictionaryProperties = new DictionaryProperties();
        this.items.setValue(new DictionaryProperties().type, new PdfName('Catalog'));
    }
    //Properties
    /**
     * Gets or sets the sections, which contain `pages`.
     * @private
     */
    get pages() {
        return this.sections;
    }
    set pages(value) {
        let dictionary = value.element;
        // if (this.sections !== value) {
        //     this.sections = value;
        //     this.Items.setValue(this.tempDictionaryProperties.pages, new PdfReferenceHolder(value));
        // }
        this.sections = value;
        this.items.setValue(this.tempDictionaryProperties.pages, new PdfReferenceHolder(value));
    }
}
