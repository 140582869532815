/**
 * StringTokenizer.ts class for EJ2-PDF
 * Utility class for working with strings.
 * @private
 */
export class StringTokenizer {
    // Constructors
    /**
     * Initializes a new instance of the `StringTokenizer` class.
     * @private
     */
    constructor(textValue) {
        /**
         * Current `position`.
         * @private
         */
        this.currentPosition = 0;
        if (textValue == null) {
            throw new Error('ArgumentNullException:text');
        }
        this.text = textValue;
    }
    // Properties
    /**
     * Gets text `length`.
     * @private
     */
    get length() {
        return this.text.length;
    }
    get end() {
        return (this.currentPosition === this.text.length);
    }
    /**
     * Gets or sets the position.
     * @private
     */
    get position() {
        return this.currentPosition;
    }
    set position(value) {
        this.currentPosition = value;
    }
    static getCharsCount(text, symbols) {
        if (typeof symbols === 'string') {
            if (text == null) {
                throw new Error('ArgumentNullException:wholeText');
            }
            let numSymbols = 0;
            let curIndex = 0;
            for (;;) {
                curIndex = text.indexOf(symbols, curIndex);
                if (curIndex === -1) {
                    break;
                }
                else {
                    numSymbols++;
                    curIndex++;
                }
            }
            return numSymbols;
        }
        else {
            if (text == null) {
                throw new Error('ArgumentNullException:text');
            }
            if (symbols == null) {
                throw new Error('ArgumentNullException:symbols');
            }
            let count = 0;
            for (let i = 0, len = text.length; i < len; i++) {
                let ch = text[i];
                if (this.contains(symbols, ch)) {
                    count++;
                }
            }
            return count;
        }
    }
    /**
     * Reads line of the text.
     * @private
     */
    readLine() {
        let pos = this.currentPosition;
        while (pos < this.length) {
            let ch = this.text[pos];
            switch (ch) {
                case '\r':
                case '\n': {
                    let text = this.text.substr(this.currentPosition, pos - this.currentPosition);
                    this.currentPosition = pos + 1;
                    if (((ch === '\r') && (this.currentPosition < this.length)) && (this.text[this.currentPosition] === '\n')) {
                        this.currentPosition++;
                    }
                    return text;
                }
            }
            pos++;
        }
        // The remaining text.
        if (pos > this.currentPosition) {
            let text2 = this.text.substr(this.currentPosition, pos - this.currentPosition);
            this.currentPosition = pos;
            return text2;
        }
        return null;
    }
    /**
     * Reads line of the text.
     * @private
     */
    peekLine() {
        let pos = this.currentPosition;
        let line = this.readLine();
        this.currentPosition = pos;
        return line;
    }
    /**
     * Reads a word from the text.
     * @private
     */
    readWord() {
        let pos = this.currentPosition;
        while (pos < this.length) {
            let ch = this.text[pos];
            switch (ch) {
                case '\r':
                case '\n':
                    let textValue = this.text.substr(this.currentPosition, pos - this.currentPosition);
                    this.currentPosition = pos + 1;
                    if (((ch === '\r') && (this.currentPosition < this.length)) && (this.text[this.currentPosition] === '\n')) {
                        this.currentPosition++;
                    }
                    return textValue;
                case ' ':
                case '\t': {
                    if (pos === this.currentPosition) {
                        pos++;
                    }
                    let text = this.text.substr(this.currentPosition, pos - this.currentPosition);
                    this.currentPosition = pos;
                    return text;
                }
            }
            pos++;
        }
        // The remaining text.
        if (pos > this.currentPosition) {
            let text2 = this.text.substr(this.currentPosition, pos - this.currentPosition);
            this.currentPosition = pos;
            return text2;
        }
        return null;
    }
    /**
     * Peeks a word from the text.
     * @private
     */
    peekWord() {
        let pos = this.currentPosition;
        let word = this.readWord();
        this.currentPosition = pos;
        return word;
    }
    read(count) {
        if (typeof count === 'undefined') {
            let ch = '0';
            if (!this.end) {
                ch = this.text[this.currentPosition];
                this.currentPosition++;
            }
            return ch;
        }
        else {
            let num = 0;
            let builder = '';
            while (!this.end && num < count) {
                let ch = this.read();
                builder = builder + ch;
                num++;
            }
            return builder;
        }
    }
    /**
     * Peeks char form the data.
     * @private
     */
    peek() {
        let ch = '0';
        if (!this.end) {
            ch = this.text[this.currentPosition];
        }
        return ch;
    }
    /**
     * Closes a reader.
     * @private
     */
    close() {
        this.text = null;
    }
    readToEnd() {
        let text;
        if (this.currentPosition === 0) {
            text = this.text;
        }
        else {
            text = this.text.substr(this.currentPosition, this.length - this.currentPosition);
        }
        this.currentPosition = this.length;
        return text;
    }
    //Implementation
    /**
     * Checks whether array contains a symbol.
     * @private
     */
    static contains(array, symbol) {
        let contains = false;
        for (let i = 0; i < array.length; i++) {
            if (array[i] === symbol) {
                contains = true;
                break;
            }
        }
        return contains;
    }
}
// Constants
/**
 * `Whitespace` symbol.
 * @private
 */
StringTokenizer.whiteSpace = ' ';
/**
 * `tab` symbol.
 * @private
 */
StringTokenizer.tab = '\t';
/**
 * Array of `spaces`.
 * @private
 */
StringTokenizer.spaces = [StringTokenizer.whiteSpace, StringTokenizer.tab];
/**
 * `Pattern` for WhiteSpace.
 * @private
 */
StringTokenizer.whiteSpacePattern = '^[ \t]+$';
