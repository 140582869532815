import { PdfBrush } from './../../graphics/brushes/pdf-brush';
import { PdfNumberStyle } from './../../pages/enum';
import { PdfNumbersConvertor } from './pdf-numbers-convertor';
import { PdfMultipleValueField } from './multiple-value-field';
/**
 * Represents PDF document `page number field`.
 * @public
 */
export class PdfPageNumberField extends PdfMultipleValueField {
    constructor(font, arg2) {
        super();
        // Fields
        /**
         * Stores the number style of the page number field.
         * @private
         */
        this.internalNumberStyle = PdfNumberStyle.Numeric;
        if (typeof arg2 === 'undefined') {
            this.font = font;
        }
        else if (arg2 instanceof PdfBrush) {
            this.font = font;
            this.brush = arg2;
        }
        else {
            this.font = font;
            this.bounds = arg2;
        }
    }
    // Properties
    /**
     * Gets and sets the number style of the page number field.
     * @private
     */
    get numberStyle() {
        return this.internalNumberStyle;
    }
    set numberStyle(value) {
        this.internalNumberStyle = value;
    }
    /**
     * Return the `string` value of page number field.
     * @public
     */
    getValue(graphics) {
        let result = null;
        let page = this.getPageFromGraphics(graphics);
        result = this.internalGetValue(page);
        return result;
    }
    /**
     * Internal method to `get actual value of page number`.
     * @private
     */
    internalGetValue(page) {
        let document = page.document;
        let pageIndex = document.pages.indexOf(page) + 1;
        return PdfNumbersConvertor.convert(pageIndex, this.numberStyle);
    }
}
