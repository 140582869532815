import { PdfLinkAnnotation } from './link-annotation';
/**
 * Represents base class for `link annotations` with associated action.
 * @private
 */
export class PdfActionLinkAnnotation extends PdfLinkAnnotation {
    // Constructors
    /**
     * Specifies the constructor for `ActionLinkAnnotation`.
     * @private
     */
    constructor(rectangle) {
        super(rectangle);
        // Fields
        /**
         * Internal variable to store annotation's `action`.
         * @default null
         * @private
         */
        this.pdfAction = null;
    }
    //Public method
    /**
     * get and set the `action`.
     * @hidden
     */
    getSetAction(value) {
        if (typeof value === 'undefined') {
            return this.pdfAction;
        }
        else {
            this.pdfAction = value;
        }
    }
}
