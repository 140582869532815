import { PdfPen } from './../pdf-pen';
import { RectangleF } from './../../drawing/pdf-drawing';
import { PdfFillElement } from './../figures/base/fill-element';
/**
 * `PdfRectangleArea` class Implements graphics rectangle area, which is a sequence of primitive graphics elements.
 * @private
 */
export class PdfRectangleArea extends PdfFillElement {
    /* tslint:disable-next-line:max-line-length */
    constructor(arg1, arg2, arg3, arg4, arg5, arg6) {
        super();
        //Fields
        /**
         * public variable to store the rectangle.
         * @public
         */
        this.bounds = new RectangleF(0, 0, 0, 0);
        if (typeof arg1 === 'undefined') {
            //
        }
        else if (arg1 instanceof PdfPen) {
            super(arg1, arg2);
            if (arg3 instanceof RectangleF) {
                this.bounds = arg3;
            }
            else {
                this.bounds = new RectangleF(arg3, arg4, arg5, arg6);
            }
        }
        else if (arg1 instanceof RectangleF) {
            this.bounds = arg1;
        }
        else {
            this.bounds = new RectangleF(arg1, arg2, arg3, arg4);
        }
    }
    //Properties
    /**
     * Gets or sets the X co-ordinate of the upper-left corner of this the element.
     * @public
     */
    get x() {
        return this.bounds.x;
    }
    set x(value) {
        this.bounds.x = value;
    }
    /**
     * Gets or sets the Y co-ordinate of the upper-left corner of this the element.
     * @public
     */
    get y() {
        return this.bounds.y;
    }
    set y(value) {
        this.bounds.y = value;
    }
    /**
     * Gets or sets the width of this element.
     * @public
     */
    get width() {
        return this.bounds.width;
    }
    set width(value) {
        this.bounds.width = value;
    }
    /**
     * Gets or sets the height of this element.
     * @public
     */
    get height() {
        return this.bounds.height;
    }
    set height(value) {
        this.bounds.height = value;
    }
    //Implementation
    getBoundsInternal() {
        return this.bounds;
    }
}
