import { PdfGridCell, PdfGridCellCollection } from './pdf-grid-cell';
import { PdfGridRowStyle } from './styles/style';
/**
 * `PdfGridRow` class provides customization of the settings for the particular row.
 */
export class PdfGridRow {
    //Constructor
    /**
     * Initializes a new instance of the `PdfGridRow` class with the parent grid.
     * @private
     */
    constructor(grid) {
        /**
         * Stores the index of the overflowing row.
         * @private
         */
        this.gridRowOverflowIndex = 0;
        /**
         * The `height` of the row.
         * @private
         */
        this.rowHeight = 0;
        /**
         * The `width` of the row.
         * @private
         */
        this.rowWidth = 0;
        /**
         * The `isFinish` of the row.
         * @private
         */
        this.isrowFinish = false;
        /**
         * Check whether the Row span row height `is set explicitly`.
         * @default false
         * @public
         */
        this.isRowSpanRowHeightSet = false;
        /**
         * The `page count` of the row.
         * @public
         */
        this.noOfPageCount = 0;
        /**
         * Check whether the row height `is set explicitly`.
         * @default false
         * @private
         */
        this.isRowHeightSet = false;
        this.isPageBreakRowSpanApplied = false;
        /**
         * Check weather the row merge `is completed` or not.
         * @default true
         * @private
         */
        this.isRowMergeComplete = true;
        this.repeatFlag = false;
        this.rowFontSplit = false;
        this.isHeaderRow = false;
        this.pdfGrid = grid;
    }
    //Properties
    /**
     * Gets or sets a value indicating [`row span exists`].
     * @private
     */
    get rowSpanExists() {
        return this.bRowSpanExists;
    }
    set rowSpanExists(value) {
        this.bRowSpanExists = value;
    }
    /**
     * Gets the `cells` from the selected row.[Read-Only].
     * @private
     */
    get cells() {
        if (this.gridCells == null) {
            this.gridCells = new PdfGridCellCollection(this);
        }
        return this.gridCells;
    }
    /**
     * Gets or sets the parent `grid`.
     * @private
     */
    get grid() {
        return this.pdfGrid;
    }
    set grid(value) {
        this.pdfGrid = value;
    }
    /**
     * Gets or sets the row `style`.
     * @private
     */
    get style() {
        if (typeof this.rowStyle === 'undefined') {
            this.rowStyle = new PdfGridRowStyle();
            this.rowStyle.setParent(this);
        }
        return this.rowStyle;
    }
    set style(value) {
        this.rowStyle = value;
        for (let i = 0; i < this.cells.count; i++) {
            this.cells.getCell(i).style.borders = value.border;
            if (typeof value.font !== 'undefined') {
                this.cells.getCell(i).style.font = value.font;
            }
            if (typeof value.backgroundBrush !== 'undefined') {
                this.cells.getCell(i).style.backgroundBrush = value.backgroundBrush;
            }
            if (typeof value.backgroundImage !== 'undefined') {
                this.cells.getCell(i).style.backgroundImage = value.backgroundImage;
            }
            if (typeof value.textBrush !== 'undefined') {
                this.cells.getCell(i).style.textBrush = value.textBrush;
            }
            if (typeof value.textPen !== 'undefined') {
                this.cells.getCell(i).style.textPen = value.textPen;
            }
        }
    }
    /**
     * `Height` of the row yet to be drawn after split.
     * @private
     */
    get rowBreakHeight() {
        if (typeof this.gridRowBreakHeight === 'undefined') {
            this.gridRowBreakHeight = 0;
        }
        return this.gridRowBreakHeight;
    }
    set rowBreakHeight(value) {
        this.gridRowBreakHeight = value;
    }
    /**
     * `over flow index` of the row.
     * @private
     */
    get rowOverflowIndex() {
        return this.gridRowOverflowIndex;
    }
    set rowOverflowIndex(value) {
        this.gridRowOverflowIndex = value;
    }
    /**
     * Gets or sets the `height` of the row.
     * @private
     */
    get height() {
        if (!this.isRowHeightSet) {
            this.rowHeight = this.measureHeight();
        }
        return this.rowHeight;
    }
    set height(value) {
        this.rowHeight = value;
        this.isRowHeightSet = true;
    }
    /**
     * Gets or sets the `width` of the row.
     * @private
     */
    get width() {
        if (this.rowWidth === 0 || typeof this.rowWidth === 'undefined') {
            this.rowWidth = this.measureWidth();
        }
        return this.rowWidth;
    }
    /**
     * Gets or sets the row `Nested grid Layout Result`.
     * @private
     */
    get NestedGridLayoutResult() {
        return this.gridResult;
    }
    set NestedGridLayoutResult(value) {
        this.gridResult = value;
    }
    /**
     * Gets or sets a value indicating [`column span exists`].
     * @private
     */
    get columnSpanExists() {
        return this.bColumnSpanExists;
    }
    set columnSpanExists(value) {
        this.bColumnSpanExists = value;
    }
    /**
     * Check whether the Row `has row span or row merge continue`.
     * @private
     */
    get rowMergeComplete() {
        return this.isRowMergeComplete;
    }
    set rowMergeComplete(value) {
        this.isRowMergeComplete = value;
    }
    /**
     * Returns `index` of the row.
     * @private
     */
    get rowIndex() {
        return this.grid.rows.rowCollection.indexOf(this);
    }
    //Implementation
    /**
     * `Calculates the height`.
     * @private
     */
    measureHeight() {
        let rowSpanRemainingHeight = 0;
        let rowHeight;
        let maxHeight = 0;
        if (this.cells.getCell(0).rowSpan > 1) {
            rowHeight = 0;
        }
        else {
            rowHeight = this.cells.getCell(0).height;
        }
        for (let i = 0; i < this.cells.count; i++) {
            let cell = this.cells.getCell(i);
            //get the maximum rowspan remaining height.
            if (cell.rowSpanRemainingHeight > rowSpanRemainingHeight) {
                rowSpanRemainingHeight = cell.rowSpanRemainingHeight;
            }
            //skip the cell if row spanned.
            // if (cell.isRowMergeContinue) {
            //     continue;
            // }
            // if (!cell.isRowMergeContinue) {
            //     this.rowMergeComplete = false;
            // }
            this.rowMergeComplete = false;
            if (cell.rowSpan > 1) {
                let cellIn = i;
                let rowin = this.isHeaderRow ? this.grid.headers.indexOf(this) : this.grid.rows.rowCollection.indexOf(this);
                for (let j = 0; j < cell.rowSpan; j++) {
                    if ((j + 1) < cell.rowSpan) {
                        (this.isHeaderRow ? this.grid.headers.getHeader(rowin + j + 1) : this.grid.rows.getRow(rowin + j + 1)).cells.getCell(cellIn).hasRowSpan = true;
                    }
                }
                if (maxHeight < cell.height) {
                    maxHeight = cell.height;
                }
                continue;
            }
            rowHeight = Math.max(rowHeight, cell.height);
        }
        if (maxHeight > rowHeight) {
            rowHeight = maxHeight;
        }
        if (rowHeight === 0) {
            rowHeight = maxHeight;
        }
        else if (rowSpanRemainingHeight > 0) {
            rowHeight += rowSpanRemainingHeight;
        }
        return rowHeight;
    }
    measureWidth() {
        let rowWid = 0;
        for (let i = 0; i < this.grid.columns.count; i++) {
            let column = this.grid.columns.getColumn(i);
            rowWid += column.width;
        }
        return rowWid;
    }
}
/**
 * `PdfGridRowCollection` class provides access to an ordered, strongly typed collection of 'PdfGridRow' objects.
 * @private
 */
export class PdfGridRowCollection {
    // Constructor
    /**
     * Initializes a new instance of the `PdfGridRowCollection` class with the parent grid.
     * @private
     */
    constructor(grid) {
        this.rows = [];
        this.grid = grid;
    }
    //Properties
    /**
     * Gets the number of header in the `PdfGrid`.[Read-Only].
     * @private
     */
    get count() {
        return this.rows.length;
    }
    //Implementation
    /**
     * Return the row collection of the `grid`.
     * @private
     */
    get rowCollection() {
        return this.rows;
    }
    addRow(arg) {
        if (typeof arg === 'undefined') {
            let temprow = new PdfGridRow(this.grid);
            this.addRow(temprow);
            return temprow;
        }
        else {
            arg.style.setBackgroundBrush(this.grid.style.backgroundBrush);
            arg.style.setFont(this.grid.style.font);
            arg.style.setTextBrush(this.grid.style.textBrush);
            arg.style.setTextPen(this.grid.style.textPen);
            if (arg.cells.count === 0) {
                for (let i = 0; i < this.grid.columns.count; i++) {
                    arg.cells.add(new PdfGridCell());
                }
            }
            this.rows.push(arg);
        }
    }
    /**
     * Return the row by index.
     * @private
     */
    getRow(index) {
        return this.rows[index];
    }
}
/**
 * `PdfGridHeaderCollection` class provides customization of the settings for the header.
 * @private
 */
export class PdfGridHeaderCollection {
    //constructor
    /**
     * Initializes a new instance of the `PdfGridHeaderCollection` class with the parent grid.
     * @private
     */
    constructor(grid) {
        /**
         * The array to store the `rows` of the grid header.
         * @private
         */
        this.rows = [];
        this.grid = grid;
        this.rows = [];
    }
    //Properties
    /**
     * Gets a 'PdfGridRow' object that represents the `header` row in a 'PdfGridHeaderCollection' control.[Read-Only].
     * @private
     */
    getHeader(index) {
        // if (index < 0 || index >= Count) {
        //     throw new IndexOutOfRangeException();
        // }
        return (this.rows[index]);
    }
    /**
     * Gets the `number of header` in the 'PdfGrid'.[Read-Only]
     * @private
     */
    get count() {
        return this.rows.length;
    }
    add(arg) {
        if (typeof arg === 'number') {
            let row;
            for (let i = 0; i < arg; i++) {
                row = new PdfGridRow(this.grid);
                row.isHeaderRow = true;
                for (let j = 0; j < this.grid.columns.count; j++) {
                    row.cells.add(new PdfGridCell());
                }
                this.rows.push(row);
            }
            return this.rows;
        }
        else {
            this.rows.push(arg);
        }
    }
    indexOf(row) {
        return this.rows.indexOf(row);
    }
}
