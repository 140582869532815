import { SizeF } from './../../drawing/pdf-drawing';
import { PdfGraphicsUnit } from './../enum';
import { PdfUnitConverter } from './../unit-convertor';
/**
 * `PdfImage` class represents the base class for images and provides functionality for the 'PdfBitmap' class.
 * @private
 */
export class PdfImage {
    /**
     * Gets and Sets the `width` of an image.
     * @private
     */
    get width() {
        return this.imageWidth;
    }
    set width(value) {
        this.imageWidth = value;
    }
    /**
     * Gets and Sets the `height` of an image.
     * @private
     */
    get height() {
        return this.imageHeight;
    }
    set height(value) {
        this.imageHeight = value;
    }
    /**
     * Gets or sets the size of the image.
     * @private
     */
    set size(value) {
        this.width = value.width;
        this.height = value.height;
    }
    /**
     * Gets the `physical dimension` of an image.
     * @private
     */
    get physicalDimension() {
        this.imagePhysicalDimension = this.getPointSize(this.width, this.height, this.horizontalResolution, this.verticalResolution);
        return new SizeF(this.width, this.height);
    }
    // /**
    //  * Gets the `image stream as string`.
    //  * @private
    //  */
    // public static fromString(string : string) : PdfImage {
    //     let image : PdfImage = new PdfBitmap(string);
    //     return image;
    // }
    /**
     * Gets the `element` image stream.
     * @private
     */
    get element() {
        return this.imageStream;
    }
    getPointSize(width, height, horizontalResolution, verticalResolution) {
        if (typeof horizontalResolution === 'undefined') {
            let dpiX = PdfUnitConverter.horizontalResolution;
            let dpiY = PdfUnitConverter.verticalResolution;
            let size = this.getPointSize(width, height, dpiX, dpiY);
            return size;
        }
        else {
            let ucX = new PdfUnitConverter(horizontalResolution);
            let ucY = new PdfUnitConverter(verticalResolution);
            let ptWidth = ucX.convertUnits(width, PdfGraphicsUnit.Pixel, PdfGraphicsUnit.Point);
            let ptHeight = ucY.convertUnits(height, PdfGraphicsUnit.Pixel, PdfGraphicsUnit.Point);
            let size = new SizeF(ptWidth, ptHeight);
            return size;
        }
    }
}
