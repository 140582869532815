/**
 * PdfColorBlend.ts class for EJ2-PDF
 */
import { PdfColorSpace } from './../enum';
import { PdfSampledFunction } from './../../general/functions/pdf-sampled-function';
import { PdfBlend } from './pdf-blend';
/**
 * `PdfColorBlend` Represents the arrays of colors and positions used for
 *  interpolating color blending in a multicolor gradient.
 * @private
 */
export class PdfColorBlend extends PdfBlend {
    constructor(count) {
        super();
        if (typeof count === 'number') {
            super(count);
        }
    }
    //Properties
    /**
     * Gets or sets the array of colors.
     * @public
     */
    get colors() {
        return this.mcolors;
    }
    set colors(value) {
        if ((value == null)) {
            throw new Error('ArgumentNullException : Colors');
        }
        this.mcolors = value;
    }
    //Implementation
    /**
     * Gets the function.
     * @param colorSpace The color space.
     * @public
     */
    getFunction(colorSpace) {
        let domain = [0, 1];
        let colourComponents = this.getColorComponentsCount(colorSpace);
        let maxComponentValue = this.getMaxComponentValue(colorSpace);
        let range = this.setRange(colourComponents, maxComponentValue);
        let func = null;
        if ((this.mbrush == null && typeof this.mbrush === 'undefined')) {
            let sizes = [1];
            let samplesCount;
            let step = 1;
            if (this.positions.length === 2) {
                samplesCount = 2;
            }
            else {
                let positions = this.positions;
                let intervals = this.getIntervals(positions);
                let gcd = this.gcd(intervals);
                step = gcd;
                samplesCount = (((1 / gcd)) + 1);
            }
            sizes[0] = samplesCount;
            let samples = this.getSamplesValues(colorSpace, samplesCount, maxComponentValue, step);
            func = new PdfSampledFunction(domain, range, sizes, samples);
            return func;
        }
        return func;
    }
    /**
     * 'cloneColorBlend' Clones this instance.
     * @public
     */
    cloneColorBlend() {
        let cBlend = this;
        if ((this.mcolors != null && typeof this.mcolors !== 'undefined')) {
            cBlend.colors = (this.mcolors);
        }
        if ((this.positions != null && typeof this.positions !== 'undefined')) {
            cBlend.positions = (this.positions);
        }
        return cBlend;
    }
    /**
     * Sets the range.
     * @param colourComponents The colour components.
     * @param maxValue The max value.
     */
    setRange(colourComponents, maxValue) {
        let range = [(colourComponents * 2)];
        for (let i = 0; (i < colourComponents); ++i) {
            range[(i * 2)] = 0;
            range[((i * 2) + 1)] = 1;
        }
        return range;
    }
    /**
     * Calculates the color components count according to colour space.
     * @param colorSpace The color space.
     */
    getColorComponentsCount(colorSpace) {
        let count = 0;
        switch (colorSpace) {
            case PdfColorSpace.Rgb:
                count = 3;
                break;
            case PdfColorSpace.Cmyk:
                count = 4;
                break;
            case PdfColorSpace.GrayScale:
                count = 1;
                break;
            default:
                throw new Error('ArgumentException - Unsupported color space: ' + colorSpace + ' colorSpace');
        }
        return count;
    }
    /**
     * Gets samples values for specified colour space.
     * @param colorSpace The color space.
     * @param sampleCount The sample count.
     * @param maxComponentValue The max component value.
     * @param step The step.
     */
    getSamplesValues(colorSpace, sampleCount, maxComponentValue, step) {
        let values;
        switch (colorSpace) {
            case PdfColorSpace.GrayScale:
                values = this.getGrayscaleSamples(sampleCount, maxComponentValue, step);
                break;
            case PdfColorSpace.Cmyk:
                values = this.getCmykSamples(sampleCount, maxComponentValue, step);
                break;
            case PdfColorSpace.Rgb:
                values = this.getRgbSamples(sampleCount, maxComponentValue, step);
                break;
            default:
                throw new Error('ArgumentException - Unsupported color space: ' + colorSpace + ' colorSpace');
        }
        return values;
    }
    /**
     * Gets the grayscale samples.
     * @param sampleCount The sample count.
     * @param maxComponentValue The max component value.
     * @param step The step.
     */
    getGrayscaleSamples(sampleCount, maxComponentValue, step) {
        let values = [(sampleCount * 2)];
        for (let i = 0; (i < sampleCount); ++i) {
            let color = this.getNextColor(i, step, PdfColorSpace.GrayScale);
            let index = (i * 2);
        }
        return values;
    }
    /**
     * Gets the RGB samples.
     * @param sampleCount The sample count.
     * @param maxComponentValue The max component value.
     * @param step The step.
     */
    getRgbSamples(sampleCount, maxComponentValue, step) {
        let values = [(sampleCount * 3)];
        for (let i = 0; (i < sampleCount); ++i) {
            let color = this.getNextColor(i, step, PdfColorSpace.Rgb);
            let index = (i * 3);
            values[index] = color.r;
            values[(index + 1)] = color.g;
            values[(index + 2)] = color.b;
        }
        return values;
    }
    /**
     * Gets the CMYK samples.
     * @param sampleCount The sample count.
     * @param maxComponentValue The max component value.
     * @param step The step.
     */
    getCmykSamples(sampleCount, maxComponentValue, step) {
        let values = [(sampleCount * 4)];
        for (let i = 0; (i < sampleCount); i++) {
            let color = this.getNextColor(i, step, PdfColorSpace.Cmyk);
            let index = (i * 4);
            values[index] = ((color.c * maxComponentValue));
            values[(index + 1)] = ((color.m * maxComponentValue));
            values[(index + 2)] = ((color.y * maxComponentValue));
            values[(index + 3)] = ((color.k * maxComponentValue));
        }
        return values;
    }
    /**
     * Calculates the color that should be at the specified index.
     * @param index The index.
     * @param step The step.
     * @param colorSpace The color space.
     */
    getNextColor(index, step, colorSpace) {
        let position = (step * index);
        let indexHi;
        let indexLow;
        let result = this.getIndices(position, indexLow, indexHi);
        indexLow = result.indexLow;
        indexHi = result.indexHi;
        let color;
        if (indexLow === indexHi) {
            color = this.mcolors[indexLow];
        }
        else {
            let positionLow = this.positions[indexLow];
            let positionHi = this.positions[indexHi];
            let colorLow = this.mcolors[indexLow];
            let colorHi = this.mcolors[indexHi];
            let t = ((position - positionLow) / (positionHi - positionLow));
            color = this.interpolate(t, colorLow, colorHi, colorSpace);
        }
        return color;
    }
    /**
     * Gets the indices.
     * @param position The position.
     * @param indexLow The index low.
     * @param indexHi The index hi.
     */
    getIndices(position, indexLow, indexHi) {
        let positions = this.positions;
        indexLow = 0;
        indexHi = 0;
        for (let i = 0; (i < this.mcolors.length); ++i) {
            let currPos = positions[i];
            if ((currPos === position)) {
                indexHi = i;
                indexLow = i;
                break;
            }
            else if ((currPos > position)) {
                indexHi = i;
                break;
            }
            indexLow = i;
            indexHi = i;
        }
        return { indexLow: indexLow, indexHi: indexHi };
    }
    /**
     * Calculates the max component value.
     * @param colorSpace The color space.
     */
    getMaxComponentValue(colorSpace) {
        let result = 0;
        switch (colorSpace) {
            case PdfColorSpace.Cmyk:
            case PdfColorSpace.Rgb:
                result = 255;
                break;
            case PdfColorSpace.GrayScale:
                result = 65535;
                break;
            default:
                throw new Error('ArgumentException - Unsupported color space: ' + colorSpace + 'colorSpace');
        }
        return result;
    }
    /**
     * Gets an intervals array from the positions array.
     * @param positions The positions array.
     */
    getIntervals(positions) {
        let count = positions.length;
        let intervals = [(count - 1)];
        let prev = positions[0];
        for (let i = 1; (i < count); ++i) {
            let v = positions[i];
            intervals[(i - 1)] = (v - prev);
            prev = v;
        }
        return intervals;
    }
}
