/**
 * PdfMainObjectCollection.ts class for EJ2-PDF
 */
import { Dictionary } from './../collections/dictionary';
import { ObjectStatus } from './enum';
/**
 * The collection of all `objects` within a PDF document.
 * @private
 */
export class PdfMainObjectCollection {
    constructor() {
        //Fields
        /**
         * The collection of the `indirect objects`.
         * @default []
         * @private
         */
        this.objectCollections = [];
        /**
         * The collection of the `Indirect objects`.
         * @default new Dictionary<number, ObjectInfo>()
         * @private
         */
        this.mainObjectCollection = new Dictionary();
        /**
         * The collection of `primitive objects`.
         * @private
         */
        this.primitiveObjectCollection = new Dictionary();
    }
    //Properties
    /**
     * Gets the `count`.
     * @private
     */
    get count() {
        return this.objectCollections.length;
    }
    /**
     * Gets the value of `ObjectInfo` from object collection.
     * @private
     */
    items(index) {
        return this.objectCollections[index];
    }
    //Methods
    /**
     * Specifies the value of `IsNew`.
     * @private
     */
    get outIsNew() {
        return this.isNew;
    }
    /**
     * `Adds` the specified element.
     * @private
     */
    add(element) {
        let objInfo = new ObjectInfo(element);
        this.objectCollections.push(objInfo);
        if (!this.primitiveObjectCollection.containsKey(element)) {
            this.primitiveObjectCollection.setValue(element, this.objectCollections.length - 1);
        }
        element.position = this.index = this.objectCollections.length - 1;
        element.status = ObjectStatus.Registered;
    }
    /**
     * `Looks` through the collection for the object specified.
     * @private
     */
    lookFor(obj) {
        let index = -1;
        if (obj.position !== -1) {
            return obj.position;
        }
        if (this.primitiveObjectCollection.containsKey(obj) && this.count === this.primitiveObjectCollection.size()) {
            index = this.primitiveObjectCollection.getValue(obj);
        }
        else {
            for (let i = this.count - 1; i >= 0; i--) {
                let oi = this.objectCollections[i];
                if (oi.object === obj) {
                    index = i;
                    break;
                }
            }
        }
        return index;
    }
    /**
     * Gets the `reference of the object`.
     * @private
     */
    getReference(index, isNew) {
        this.index = this.lookFor(index);
        let reference;
        this.isNew = false;
        let oi = this.objectCollections[this.index];
        reference = oi.reference;
        let obj = { reference: reference, wasNew: isNew };
        return obj;
    }
    /**
     * Tries to set the `reference to the object`.
     * @private
     */
    trySetReference(obj, reference, found) {
        let result = true;
        found = true;
        this.index = this.lookFor(obj);
        let oi = this.objectCollections[this.index];
        oi.setReference(reference);
        return result;
    }
    destroy() {
        for (let obj of this.objectCollections) {
            if (obj !== undefined) {
                obj.pdfObject.position = -1;
                obj.pdfObject.isSaving = undefined;
                obj.pdfObject.objectCollectionIndex = undefined;
                obj.pdfObject.position = undefined;
            }
        }
        this.objectCollections = [];
        this.mainObjectCollection = new Dictionary();
        this.primitiveObjectCollection = new Dictionary();
    }
}
export class ObjectInfo {
    constructor(obj, reference) {
        this.pdfObject = obj;
        this.pdfReference = reference;
    }
    //Properties
    /**
     * Gets the `object`.
     * @private
     */
    get object() {
        return this.pdfObject;
    }
    set object(value) {
        this.pdfObject = value;
    }
    /**
     * Gets the `reference`.
     * @private
     */
    get reference() {
        return this.pdfReference;
    }
    /**
     * Sets the `reference`.
     * @private
     */
    setReference(reference) {
        this.pdfReference = reference;
    }
}
