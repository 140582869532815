/**
 * Path.ts class for EJ2-PDF
 */
import { PdfBrush } from './../brushes/pdf-brush';
import { PdfPen } from './../pdf-pen';
import { PdfLayoutFormat } from './../figures/base/element-layouter';
import { RectangleF, PointF } from './../../drawing/pdf-drawing';
import { PathPointType } from './enum';
import { PdfFillElement } from './../figures/base/fill-element';
import { PdfFillMode } from './../enum';
/**
 * `PdfPath` class Implements graphics path, which is a sequence of primitive graphics elements.
 * @private
 */
export class PdfPath extends PdfFillElement {
    /**
     * Initializes a new instance of the `PdfPath` class.
     * @public
     */
    /* tslint:disable-next-line:max-line-length */
    constructor(arg1, arg2, arg3, arg4) {
        super();
        // Fields
        /**
         * Local variable to store the points.
         * @private
         */
        this.mpoints = null;
        /**
         * Local variable to store the path Types.
         * @private
         */
        this.mpathTypes = null;
        /**
         * Local variable to store the Start Figure.
         * @private
         */
        this.mStartFigure = true;
        /**
         * Local variable to store the fill Mode.
         * @private
         */
        this.mfillMode = PdfFillMode.Alternate;
        /**
         * Local variable to store the Beziers.
         * @private
         */
        this.isBeziers3 = false;
        /**
         * Local variable to store the xps.
         * @private
         */
        this.isXps = false;
        if (typeof arg1 === 'undefined') {
            //
        }
        else if (arg1 instanceof PdfPen) {
            super(arg1);
            if (arg2 instanceof PdfBrush) {
                super(arg1, arg2);
                this.fillMode = arg3;
            }
            else if (arg2 !== null && typeof arg2 !== 'undefined' && arg3 !== null && typeof arg3 !== 'undefined') {
                this.addPath(arg2, arg3);
            }
        }
        else if (arg1 instanceof PdfBrush) {
            super(arg1);
            if (arg2 !== null && typeof arg2 !== 'undefined') {
                this.fillMode = arg2;
            }
            if (arg3 !== null && typeof arg3 !== 'undefined' && arg4 !== null && typeof arg4 !== 'undefined') {
                this.addPath(arg3, arg4);
            }
        }
        else {
            this.addPath(arg1, arg2);
        }
    }
    // Properties
    /**
     * Gets or sets the fill mode.
     * @public
     */
    get fillMode() {
        return this.mfillMode;
    }
    set fillMode(value) {
        this.mfillMode = value;
    }
    /**
     * Gets the path points.
     * @public
     */
    get pathPoints() {
        return this.points;
    }
    /**
     * Gets the path point types.
     * @public
     */
    get pathTypes() {
        return this.types;
    }
    /**
     * Gets the point count.
     * @public
     */
    get pointCount() {
        let count = 0;
        if ((this.mpoints != null)) {
            count = this.mpoints.length;
        }
        return count;
    }
    /**
     * Gets the last points.
     * @public
     */
    get lastPoint() {
        return this.getLastPoint();
    }
    /**
     * Gets the points list.
     * @private
     */
    get points() {
        if ((this.mpoints == null)) {
            this.mpoints = [];
        }
        return this.mpoints;
    }
    /**
     * Gets the types.
     * @private
     */
    get types() {
        if ((this.mpathTypes == null)) {
            this.mpathTypes = [];
        }
        return this.mpathTypes;
    }
    draw(arg1, arg2, arg3, arg4) {
        if (arg2 instanceof PointF && typeof arg2.width === 'undefined' && typeof arg3 === 'undefined') {
            return this.drawHelper(arg1, arg2.x, arg2.y);
        }
        else if (arg2 instanceof RectangleF && typeof arg2.width !== 'undefined' && typeof arg3 === 'undefined') {
            return this.drawHelper(arg1, arg2, null);
        }
        else if (typeof arg2 === 'number' && typeof arg3 === 'number' && typeof arg4 === 'undefined') {
            return this.drawHelper(arg1, arg2, arg3, null);
        }
        else if (arg2 instanceof PointF && arg3 instanceof PdfLayoutFormat) {
            return this.drawHelper(arg1, arg2.x, arg2.y, arg3);
        }
        else if (typeof arg2 === 'number' && (arg4 instanceof PdfLayoutFormat || arg4 == null) && typeof arg3 === 'number') {
            let widthValue = (arg1.graphics.clientSize.width - arg2);
            let layoutRect = new RectangleF(arg2, arg3, widthValue, 0);
            return this.drawHelper(arg1, layoutRect, arg4);
        }
        else if (arg2 instanceof RectangleF && arg3 instanceof PdfLayoutFormat) {
            return this.drawHelper(arg1, arg2, arg3);
        }
        else {
            return this.drawHelper(arg1, arg2, arg3);
        }
    }
    addArc(arg1, arg2, arg3, arg4, arg5, arg6) {
        if (arg1 instanceof RectangleF) {
            this.addArc(arg1.x, arg1.y, arg1.width, arg1.height, arg2, arg3);
        }
        else {
            let points = this.getBezierArcPoints(arg1, arg2, (arg2 + arg3), (arg2 + arg4), arg5, arg6);
            for (let i = 0; i < points.length; i = i + 8) {
                /* tslint:disable-next-line:max-line-length */
                let point = [points[i], points[i + 1], points[i + 2], points[i + 3], points[i + 4], points[i + 5], points[i + 6], points[i + 7]];
                this.addPoints(point, PathPointType.Bezier3);
            }
        }
    }
    /* tslint:disable-next-line:max-line-length */
    addBezier(arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8) {
        if (arg1 instanceof PointF && arg2 instanceof PointF && arg3 instanceof PointF && arg4 instanceof PointF) {
            this.addBezier(arg1.x, arg1.y, arg2.x, arg2.y, arg3.x, arg3.y, arg4.x, arg4.y);
        }
        else {
            let points = [];
            points.push(arg1);
            points.push(arg2);
            points.push(arg3);
            points.push(arg4);
            points.push(arg5);
            points.push(arg6);
            points.push(arg7);
            points.push(arg8);
            this.addPoints(points, PathPointType.Bezier3);
        }
    }
    addEllipse(arg1, arg2, arg3, arg4) {
        if (arg1 instanceof RectangleF) {
            this.addEllipse(arg1.x, arg1.y, arg1.width, arg1.height);
        }
        else {
            this.startFigure();
            this.addArc(arg1, arg2, arg3, arg4, 0, 360);
            this.closeFigure();
        }
    }
    addLine(arg1, arg2, arg3, arg4) {
        if (arg1 instanceof PointF && arg2 instanceof PointF) {
            this.addLine(arg1.x, arg1.y, arg2.x, arg2.y);
        }
        else {
            let points = [];
            points.push(arg1);
            points.push(arg2);
            points.push(arg3);
            points.push(arg4);
            this.addPoints(points, PathPointType.Line);
        }
    }
    addPath(arg1, arg2) {
        if (arg1 instanceof PdfPath) {
            this.addPath(arg1.pathPoints, arg1.pathTypes);
        }
        else {
            if ((arg1 == null)) {
                throw new Error('ArgumentNullException:pathPoints');
            }
            if ((arg2 == null)) {
                throw new Error('ArgumentNullException:pathTypes');
            }
            let count = arg1.length;
            if ((count !== arg2.length)) {
                throw new Error('The argument arrays should be of equal length.');
            }
        }
    }
    addPie(arg1, arg2, arg3, arg4, arg5, arg6) {
        if (arg1 instanceof RectangleF) {
            this.addPie(arg1.x, arg1.y, arg1.width, arg1.height, arg2, arg3);
        }
        else {
            this.startFigure();
            this.addArc(arg1, arg2, arg3, arg4, arg5, arg6);
            this.addPoint(new PointF((arg1 + (arg3 / 2)), (arg2 + (arg4 / 2))), PathPointType.Line);
            this.closeFigure();
        }
    }
    /**
     * `add a polygon` specified by points.
     * @param points The points of the polygon
     */
    addPolygon(points) {
        let count = (points.length * 2);
        let p = [];
        this.startFigure();
        for (let i = 0; i < points.length; i++) {
            p.push(points[i].x);
            p.push(points[i].y);
        }
        this.addPoints(p, PathPointType.Line);
        this.closeFigure();
    }
    addRectangle(arg1, y, width, height) {
        if (arg1 instanceof RectangleF) {
            this.addRectangle(arg1.x, arg1.y, arg1.width, arg1.height);
        }
        else {
            let points = [];
            this.startFigure();
            points.push(arg1);
            points.push(y);
            points.push((arg1 + width));
            points.push(y);
            points.push((arg1 + width));
            points.push((y + height));
            points.push(arg1);
            points.push((y + height));
            this.addPoints(points, PathPointType.Line);
            this.closeFigure();
        }
    }
    /**
     * Starts a new figure.
     * @public
     */
    startFigure() {
        this.mStartFigure = true;
    }
    /**
     * Closed all non-closed figures.
     * @public
     */
    closeAllFigures() {
        let startPath = this.pathPoints[0];
        for (let i = 0; i < this.mpathTypes.length; i++) {
            let pt = ((this.types[i]));
            let flag = false;
            if (((i !== 0) && (pt === PathPointType.Start))) {
                this.closeFigure((i - 1));
                flag = true;
            }
            else if (((i === (this.mpathTypes.length - 1)) && (!flag && this.isXps))) {
                if ((startPath.x === this.pathPoints[i].y)) {
                    this.closeFigure(i);
                }
            }
        }
    }
    /**
     * Gets the last point.
     * @public
     */
    getLastPoint() {
        let lastPoint = new PointF(0, 0);
        let count = this.pointCount;
        if (((count > 0) && (this.mpoints != null))) {
            lastPoint.x = this.mpoints[(count - 1)].x;
            lastPoint.y = this.mpoints[(count - 1)].y;
        }
        return lastPoint;
    }
    /**
     * Gets the bezier points for arc constructing.
     * @public
     */
    getBezierArcPoints(x1, y1, x2, y2, s1, e1) {
        if ((x1 > x2)) {
            let tmp;
            tmp = x1;
            x1 = x2;
            x2 = tmp;
        }
        if ((y2 > y1)) {
            let tmp;
            tmp = y1;
            y1 = y2;
            y2 = tmp;
        }
        let fragAngle;
        let numFragments;
        if ((Math.abs(e1) <= 90)) {
            fragAngle = e1;
            numFragments = 1;
        }
        else {
            numFragments = (Math.ceil((Math.abs(e1) / 90)));
            fragAngle = (e1 / numFragments);
        }
        let xcen = ((x1 + x2) / 2);
        let ycen = ((y1 + y2) / 2);
        let rx = ((x2 - x1) / 2);
        let ry = ((y2 - y1) / 2);
        let halfAng = ((fragAngle * (Math.PI / 360)));
        let kappa = (Math.abs(4.0 / 3.0 * (1.0 - Math.cos(halfAng)) / Math.sin(halfAng)));
        let pointList = [];
        for (let i = 0; (i < numFragments); i++) {
            let theta0 = (((s1 + (i * fragAngle)) * (Math.PI / 180)));
            let theta1 = (((s1 + ((i + 1) * fragAngle)) * (Math.PI / 180)));
            let cos0 = (Math.cos(theta0));
            let cos1 = (Math.cos(theta1));
            let sin0 = (Math.sin(theta0));
            let sin1 = (Math.sin(theta1));
            if ((fragAngle > 0)) {
                /* tslint:disable-next-line:max-line-length */
                pointList.push((xcen + (rx * cos0)), (ycen - (ry * sin0)), (xcen + (rx * (cos0 - (kappa * sin0)))), (ycen - (ry * (sin0 + (kappa * cos0)))), (xcen + (rx * (cos1 + (kappa * sin1)))), (ycen - (ry * (sin1 - (kappa * cos1)))), (xcen + (rx * cos1)), (ycen - (ry * sin1)));
            }
            else {
                /* tslint:disable-next-line:max-line-length */
                pointList.push((xcen + (rx * cos0)), (ycen - (ry * sin0)), (xcen + (rx * (cos0 + (kappa * sin0)))), (ycen - (ry * (sin0 - (kappa * cos0)))), (xcen + (rx * (cos1 - (kappa * sin1)))), (ycen - (ry * (sin1 + (kappa * cos1)))), (xcen + (rx * cos1)), (ycen - (ry * sin1)));
            }
        }
        return pointList;
    }
    /**
     * `getBoundsInternal` Returns a rectangle that bounds this element.
     * @public
     */
    getBoundsInternal() {
        let points = this.pathPoints;
        let bounds = new RectangleF(0, 0, 0, 0);
        if ((points.length > 0)) {
            let xmin = points[0].x;
            let xmax = points[0].x;
            let ymin = points[0].y;
            let ymax = points[0].y;
            for (let i = 1; i < points.length; i++) {
                let point = points[i];
                xmin = Math.min(point.x, xmin);
                xmax = Math.max(point.x, xmax);
                ymin = Math.min(point.y, ymin);
                ymax = Math.max(point.y, ymax);
            }
            bounds = new RectangleF(xmin, ymin, (xmax - xmin), (ymax - ymin));
        }
        return bounds;
    }
    /**
     * `drawInternal` Draws an element on the Graphics.
     * @param graphics Graphics context where the element should be printed.
     * @public
     */
    drawInternal(graphics) {
        if ((graphics == null)) {
            throw new Error('ArgumentNullException :graphics');
        }
        graphics.drawPath(this.obtainPen(), this.brush, this);
    }
    addPoints(points, pointType, startIndex, endIndex) {
        if (typeof startIndex === 'undefined' && typeof endIndex === 'undefined') {
            this.addPoints(points, pointType, 0, points.length);
        }
        else {
            for (let i = startIndex; i < endIndex; i++) {
                let point = new PointF(points[i], points[(i + 1)]);
                if ((i === startIndex)) {
                    if (((this.pointCount <= 0) || this.mStartFigure)) {
                        this.addPoint(point, PathPointType.Start);
                        this.mStartFigure = false;
                    }
                    else if (((point.x !== this.lastPoint.x) && (point.y !== this.lastPoint.y) && !this.isBeziers3)) {
                        this.addPoint(point, PathPointType.Line);
                    }
                    else if ((point.x !== this.lastPoint.x) && (point.y !== this.lastPoint.y)) {
                        this.addPoint(point, PathPointType.Bezier3);
                    }
                }
                else {
                    this.addPoint(point, pointType);
                }
                i++;
            }
        }
    }
    /**
     * `add a point` Adds the point and its type
     * @param points The points.
     * @param pointType Type of the points.
     * @private
     */
    addPoint(point, pointType) {
        this.points.push(point);
        this.types.push((pointType));
    }
    closeFigure(index) {
        if (typeof index === 'undefined') {
            if ((this.pointCount > 0)) {
                this.closeFigure(this.pointCount - 1);
            }
            this.startFigure();
        }
        else {
            if ((index < 0)) {
                throw new Error('IndexOutOfRangeException()');
            }
            let pt = ((this.types[index]));
            pt = (pt | PathPointType.CloseSubpath);
            this.types[index] = (pt);
        }
    }
}
