/**
 * PdfCollection.ts class for EJ2-PDF
 * The class used to handle the collection of PdF objects.
 */
export class PdfCollection {
    // Constructors
    /**
     * Initializes a new instance of the `Collection` class.
     * @private
     */
    constructor() {
        //
    }
    // Properties
    /**
     * Gets the `Count` of stored objects.
     * @private
     */
    get count() {
        if (typeof this.collection === 'undefined') {
            this.collection = [];
        }
        return this.collection.length;
    }
    /**
     * Gets the `list` of stored objects.
     * @private
     */
    get list() {
        if (typeof this.collection === 'undefined') {
            this.collection = [];
        }
        return this.collection;
    }
}
