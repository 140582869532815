/**
 * PdfBorders.ts class for EJ2-PDF
 */
import { PdfPen } from './../../../graphics/pdf-pen';
import { PdfDashStyle } from './../../../graphics/enum';
import { PdfColor } from './../../../graphics/pdf-color';
/**
 * `PdfBorders` class used represents the cell border of the PDF grid.
 */
export class PdfBorders {
    // Properties
    /**
     * Gets or sets the `Left`.
     * @private
     */
    get left() {
        return this.leftPen;
    }
    set left(value) {
        this.leftPen = value;
    }
    /**
     * Gets or sets the `Right`.
     * @private
     */
    get right() {
        return this.rightPen;
    }
    set right(value) {
        this.rightPen = value;
    }
    /**
     * Gets or sets the `Top`.
     * @private
     */
    get top() {
        return this.topPen;
    }
    set top(value) {
        this.topPen = value;
    }
    /**
     * Gets or sets the `Bottom`.
     * @private
     */
    get bottom() {
        return this.bottomPen;
    }
    set bottom(value) {
        this.bottomPen = value;
    }
    /**
     * sets the `All`.
     * @private
     */
    set all(value) {
        this.leftPen = this.rightPen = this.topPen = this.bottomPen = value;
    }
    /**
     * Gets a value indicating whether this instance `is all`.
     * @private
     */
    get isAll() {
        return ((this.leftPen === this.rightPen) && (this.leftPen === this.topPen) && (this.leftPen === this.bottomPen));
    }
    /**
     * Gets the `default`.
     * @private
     */
    static get default() {
        return new PdfBorders();
    }
    // Constructor
    /**
     * Create a new instance for `PdfBorders` class.
     * @private
     */
    constructor() {
        let defaultBorderPenLeft = new PdfPen(new PdfColor(0, 0, 0));
        defaultBorderPenLeft.dashStyle = PdfDashStyle.Solid;
        let defaultBorderPenRight = new PdfPen(new PdfColor(0, 0, 0));
        defaultBorderPenRight.dashStyle = PdfDashStyle.Solid;
        let defaultBorderPenTop = new PdfPen(new PdfColor(0, 0, 0));
        defaultBorderPenTop.dashStyle = PdfDashStyle.Solid;
        let defaultBorderPenBottom = new PdfPen(new PdfColor(0, 0, 0));
        defaultBorderPenBottom.dashStyle = PdfDashStyle.Solid;
        this.leftPen = defaultBorderPenLeft;
        this.rightPen = defaultBorderPenRight;
        this.topPen = defaultBorderPenTop;
        this.bottomPen = defaultBorderPenBottom;
    }
}
export class PdfPaddings {
    constructor(left, right, top, bottom) {
        /**
         * The 'left' border padding set.
         * @private
         */
        this.hasLeftPad = false;
        /**
         * The 'right' border padding set.
         * @private
         */
        this.hasRightPad = false;
        /**
         * The 'top' border padding set.
         * @private
         */
        this.hasTopPad = false;
        /**
         * The 'bottom' border padding set.
         * @private
         */
        this.hasBottomPad = false;
        if (typeof left === 'undefined') {
            //5.76 and 0 are taken from ms-word default table margins.
            this.leftPad = this.rightPad = 5.76;
            //0.5 is set for top and bottom by default.
            this.bottomPad = this.topPad = 0.5;
        }
        else {
            this.leftPad = left;
            this.rightPad = right;
            this.topPad = top;
            this.bottomPad = bottom;
            this.hasLeftPad = true;
            this.hasRightPad = true;
            this.hasTopPad = true;
            this.hasBottomPad = true;
        }
    }
    // Properties
    /**
     * Gets or sets the `left` value of the edge
     * @private
     */
    get left() {
        return this.leftPad;
    }
    set left(value) {
        this.leftPad = value;
        this.hasLeftPad = true;
    }
    /**
     * Gets or sets the `right` value of the edge.
     * @private
     */
    get right() {
        return this.rightPad;
    }
    set right(value) {
        this.rightPad = value;
        this.hasRightPad = true;
    }
    /**
     * Gets or sets the `top` value of the edge
     * @private
     */
    get top() {
        return this.topPad;
    }
    set top(value) {
        this.topPad = value;
        this.hasTopPad = true;
    }
    /**
     * Gets or sets the `bottom` value of the edge.
     * @private
     */
    get bottom() {
        return this.bottomPad;
    }
    set bottom(value) {
        this.bottomPad = value;
        this.hasBottomPad = true;
    }
    /**
     * Sets value to all sides `left,right,top and bottom`.s
     * @private
     */
    set all(value) {
        this.leftPad = this.rightPad = this.topPad = this.bottomPad = value;
        this.hasLeftPad = true;
        this.hasRightPad = true;
        this.hasTopPad = true;
        this.hasBottomPad = true;
    }
}
