import { PdfNumber } from './pdf-number';
import { Operators } from './../input-output/pdf-operators';
/**
 * `PdfArray` class is used to perform array related primitive operations.
 * @private
 */
export class PdfArray {
    constructor(array) {
        //Fields
        /**
         * `startMark` - '['
         * @private
         */
        this.startMark = '[';
        /**
         * `endMark` - ']'.
         * @private
         */
        this.endMark = ']';
        /**
         * Internal variable to store the `position`.
         * @default -1
         * @private
         */
        this.position9 = -1;
        /**
         * Internal variable to hold `cloned object`.
         * @default null
         * @private
         */
        this.clonedObject9 = null;
        /**
         * Represents the Font dictionary.
         * @hidden
         * @private
         */
        this.isFont = false;
        if (typeof array === 'undefined') {
            this.internalElements = [];
        }
        else {
            if (typeof array !== 'undefined' && !(array instanceof PdfArray)) {
                let tempNumberArray = array;
                for (let index = 0; index < tempNumberArray.length; index++) {
                    let pdfNumber = new PdfNumber(tempNumberArray[index]);
                    this.add(pdfNumber);
                }
                // } else if (typeof array !== 'undefined' && (array instanceof PdfArray)) {
            }
            else {
                let tempArray = array;
                // if (tempArray.Elements.length > 0) {
                this.internalElements = [];
                for (let index = 0; index < tempArray.elements.length; index++) {
                    this.internalElements.push(tempArray.elements[index]);
                }
                // }
            }
        }
    }
    //property
    /**
     * Gets the `IPdfSavable` at the specified index.
     * @private
     */
    items(index) {
        // if (index < 0 || index >= this.Count) {
        //     throw new Error('ArgumentOutOfRangeException : index, The index can"t be less then zero or greater then Count.');
        // }
        return this.internalElements[index];
    }
    /**
     * Gets the `count`.
     * @private
     */
    get count() {
        return this.internalElements.length;
    }
    /**
     * Gets or sets the `Status` of the specified object.
     * @private
     */
    get status() {
        return this.status9;
    }
    set status(value) {
        this.status9 = value;
    }
    /**
     * Gets or sets a value indicating whether this document `is saving` or not.
     * @private
     */
    get isSaving() {
        return this.isSaving9;
    }
    set isSaving(value) {
        this.isSaving9 = value;
    }
    /**
     * Returns `cloned object`.
     * @private
     */
    get clonedObject() {
        return this.clonedObject9;
    }
    /**
     * Gets or sets the `position` of the object.
     * @private
     */
    get position() {
        return this.position9;
    }
    set position(value) {
        this.position9 = value;
    }
    /**
     * Gets or sets the `index` value of the specified object.
     * @private
     */
    get objectCollectionIndex() {
        return this.index9;
    }
    set objectCollectionIndex(value) {
        this.index9 = value;
    }
    /**
     * Returns `PdfCrossTable` associated with the object.
     * @private
     */
    get CrossTable() {
        return this.pdfCrossTable;
    }
    /**
     * Gets the `elements` of the Pdf Array.
     * @private
     */
    get elements() {
        return this.internalElements;
    }
    /**
     * `Adds` the specified element to the PDF array.
     * @private
     */
    add(element) {
        // if (element === null) {
        //     throw new Error('ArgumentNullException : obj');
        // }
        if (typeof this.internalElements === 'undefined') {
            this.internalElements = [];
        }
        this.internalElements.push(element);
        this.markedChange();
    }
    /**
     * `Marks` the object changed.
     * @private
     */
    markedChange() {
        this.bChanged = true;
    }
    /**
     * `Determines` whether the specified element is within the array.
     * @private
     */
    contains(element) {
        let returnValue = false;
        for (let index = 0; index < this.internalElements.length; index++) {
            let tempElement = this.internalElements[index];
            let inputElement = element;
            if (tempElement != null && typeof tempElement !== 'undefined' && inputElement != null && typeof inputElement !== 'undefined') {
                if (tempElement.value === inputElement.value) {
                    return true;
                }
            }
            // if (this.internalElements[index] === element) {
            //     returnValue = true;
            // }
        }
        return returnValue;
    }
    /**
     * Returns the `primitive object` of input index.
     * @private
     */
    getItems(index) {
        // if (index < 0 || index >= this.Count) {
        //     throw new Error('ArgumentOutOfRangeException : index , The index can"t be less then zero or greater then Count.');
        // }
        return this.internalElements[index];
    }
    /**
     * `Saves` the object using the specified writer.
     * @private
     */
    save(writer) {
        // if (writer === null) {
        //     throw new Error('ArgumentNullException : writer');
        // }
        writer.write(this.startMark);
        for (let i = 0, len = this.count; i < len; i++) {
            this.getItems(i).save(writer);
            if (i + 1 !== len) {
                writer.write(Operators.whiteSpace);
            }
        }
        writer.write(this.endMark);
    }
    /**
     * Creates a `copy of PdfArray`.
     * @private
     */
    clone(crossTable) {
        // if (this.clonedObject9 !== null && this.clonedObject9.CrossTable === crossTable) {
        //     return this.clonedObject9;
        // } else {
        this.clonedObject9 = null;
        // Else clone the object.
        let newArray = new PdfArray();
        for (let index = 0; index < this.internalElements.length; index++) {
            let obj = this.internalElements[index];
            newArray.add(obj.clone(crossTable));
        }
        newArray.pdfCrossTable = crossTable;
        this.clonedObject9 = newArray;
        return newArray;
    }
    /**
     * Creates filled PDF array `from the rectangle`.
     * @private
     */
    static fromRectangle(bounds) {
        let values = [bounds.x, bounds.y, bounds.width, bounds.height];
        let array = new PdfArray(values);
        return array;
    }
    // /**
    //  * Creates the rectangle from filled PDF array.
    //  * @private
    //  */
    // public ToRectangle() : RectangleF {
    //     if (this.Count < 4) {
    //         throw Error('InvalidOperationException-Can not convert to rectangle.');
    //     }
    //     let x1 : number;
    //     let x2 : number;
    //     let y1 : number;
    //     let y2 : number;
    //     let num : PdfNumber = this.getItems(0) as PdfNumber;
    //     x1 = num.IntValue;
    //     num = this.getItems(1) as PdfNumber;
    //     y1 = num.IntValue;
    //     num = this.getItems(2) as PdfNumber;
    //     x2 = num.IntValue;
    //     num = this.getItems(3) as PdfNumber;
    //     y2 = num.IntValue;
    //     let x : number = Math.min(x1, x2);
    //     let y : number = Math.min(y1, y2);
    //     let width : number = Math.abs(x1 - x2);
    //     let height : number = Math.abs(y1 - y2);
    //     let rect : RectangleF = new RectangleF(new PointF(x, y), new SizeF(width, height));
    //     return rect;
    // }
    /**
     * `Inserts` the element into the array.
     * @private
     */
    insert(index, element) {
        if (index < this.internalElements.length && index > 0) {
            let tempElements = [];
            for (let i = 0; i < index; i++) {
                tempElements.push(this.internalElements[i]);
            }
            tempElements.push(element);
            for (let i = index; i < this.internalElements.length; i++) {
                tempElements.push(this.internalElements[i]);
            }
            this.internalElements = tempElements;
        }
        else {
            this.internalElements.push(element);
        }
        this.markChanged();
    }
    /**
     * `Checks whether array contains the element`.
     * @private
     */
    indexOf(element) {
        return this.internalElements.indexOf(element);
    }
    /**
     * `Removes` element from the array.
     * @private
     */
    remove(element) {
        // if (element === null) {
        //     throw new Error('ArgumentNullException : element');
        // }
        let index = this.internalElements.indexOf(element);
        // if (index >= 0 && index < this.internalElements.length) {
        this.internalElements[index] = null;
        // }
        this.markChanged();
    }
    /**
     * `Remove` the element from the array by its index.
     * @private
     */
    removeAt(index) {
        // this.internalElements.RemoveAt(index);
        if (this.internalElements.length > index) {
            let tempArray = [];
            for (let i = 0; i < index; i++) {
                tempArray.push(this.internalElements[i]);
            }
            for (let i = index + 1; i < this.internalElements.length; i++) {
                tempArray.push(this.internalElements[i]);
            }
            this.internalElements = tempArray;
        }
        this.markChanged();
    }
    /**
     * `Clear` the array.
     * @private
     */
    clear() {
        this.internalElements = [];
        this.markChanged();
    }
    /**
     * `Marks` the object changed.
     * @private
     */
    markChanged() {
        this.bChanged = true;
    }
}
