/**
 * Represent a `collection of automatic fields information`.
 * @private
 */
export class PdfAutomaticFieldInfoCollection {
    /**
     * Initializes a new instance of the 'PdfPageNumberFieldInfoCollection' class.
     * @private
     */
    constructor() {
        /**
         * Internal variable to store instance of `pageNumberFields` class.
         * @private
         */
        this.automaticFieldsInformation = [];
        //
    }
    /**
     * Gets the `page number fields collection`.
     * @private
     */
    get automaticFields() {
        return this.automaticFieldsInformation;
    }
    // Public methods
    /// Adds the specified field info.
    /**
     * Add page number field into collection.
     * @private
     */
    add(fieldInfo) {
        return this.automaticFields.push(fieldInfo);
    }
}
