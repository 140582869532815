export class TtfMetrics {
    //Properties
    /**
     * Gets a value indicating whether this instance is italic.
     */
    get isItalic() {
        return ((this.macStyle & 2) !== 0);
    }
    /**
     * Gets a value indicating whether this instance is bold.
     */
    get isBold() {
        return ((this.macStyle & 1) !== 0);
    }
}
