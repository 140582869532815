import { SizeF, RectangleF, PointF } from './../../drawing/pdf-drawing';
import { PdfWordWrapType } from './enum';
import { StringTokenizer } from './string-tokenizer';
/**
 * Class `lay outing the text`.
 */
export class PdfStringLayouter {
    // Constructors
    /**
     * Initializes a new instance of the `StringLayouter` class.
     * @private
     */
    constructor() {
        /**
         * Checks whether the x co-ordinate is need to set as client size or not.
         * @hidden
         * @private
         */
        this.isOverloadWithPosition = false;
        //
    }
    layout(arg1, arg2, arg3, arg4, arg5, arg6, arg7) {
        if (arg4 instanceof RectangleF) {
            this.initialize(arg1, arg2, arg3, arg4, arg5);
            this.isOverloadWithPosition = arg6;
            this.clientSize = arg7;
            let result = this.doLayout();
            this.clear();
            return result;
        }
        else {
            this.initialize(arg1, arg2, arg3, arg4);
            this.isOverloadWithPosition = arg5;
            this.clientSize = arg6;
            let result = this.doLayout();
            this.clear();
            return result;
        }
    }
    initialize(text, font, format, rectSize, pageHeight) {
        if (typeof pageHeight === 'number') {
            if (text == null) {
                throw new Error('ArgumentNullException:text');
            }
            if (font == null) {
                throw new Error('ArgumentNullException:font');
            }
            this.text = text;
            this.font = font;
            this.format = format;
            this.size = new SizeF(rectSize.width, rectSize.height);
            this.rectangle = rectSize;
            this.pageHeight = pageHeight;
            this.reader = new StringTokenizer(text);
        }
        else {
            this.initialize(text, font, format, new RectangleF(new PointF(0, 0), rectSize), 0);
        }
    }
    /**
     * `Clear` all resources.
     * @private
     */
    clear() {
        this.font = null;
        this.format = null;
        this.reader.close();
        this.reader = null;
        this.text = null;
    }
    /**
     * `Layouts` the text.
     * @private
     */
    doLayout() {
        let result = new PdfStringLayoutResult();
        let lineResult = new PdfStringLayoutResult();
        let lines = [];
        let line = this.reader.peekLine();
        let lineIndent = this.getLineIndent(true);
        while (line != null) {
            lineResult = this.layoutLine(line, lineIndent);
            if (lineResult !== null || typeof lineResult !== 'undefined') {
                let numSymbolsInserted = 0;
                /* tslint:disable */
                let returnedValue = this.copyToResult(result, lineResult, lines, /*out*/ numSymbolsInserted);
                /* tslint:enable */
                let success = returnedValue.success;
                numSymbolsInserted = returnedValue.numInserted;
                if (!success) {
                    this.reader.read(numSymbolsInserted);
                    break;
                }
            }
            // if (lineResult.textRemainder != null && lineResult.textRemainder.length > 0 ) {
            //     break;
            // }
            this.reader.readLine();
            line = this.reader.peekLine();
            lineIndent = this.getLineIndent(false);
        }
        this.finalizeResult(result, lines);
        return result;
    }
    /**
     * Returns `line indent` for the line.
     * @private
     */
    getLineIndent(firstLine) {
        let lineIndent = 0;
        if (this.format != null) {
            lineIndent = (firstLine) ? this.format.firstLineIndent : this.format.paragraphIndent;
            lineIndent = (this.size.width > 0) ? Math.min(this.size.width, lineIndent) : lineIndent;
        }
        return lineIndent;
    }
    /**
     * Calculates `height` of the line.
     * @private
     */
    getLineHeight() {
        let height = this.font.height;
        if (this.format != null && this.format.lineSpacing !== 0) {
            height = this.format.lineSpacing + this.font.height;
        }
        return height;
    }
    /**
     * Calculates `width` of the line.
     * @private
     */
    getLineWidth(line) {
        let width = this.font.getLineWidth(line, this.format);
        return width;
    }
    // tslint:disable
    /**
     * `Layouts` line.
     * @private
     */
    layoutLine(line, lineIndent) {
        let lineResult = new PdfStringLayoutResult();
        lineResult.layoutLineHeight = this.getLineHeight();
        let lines = [];
        let maxWidth = this.size.width;
        let lineWidth = this.getLineWidth(line) + lineIndent;
        let lineType = LineType.FirstParagraphLine;
        let readWord = true;
        // line is in bounds.
        if (maxWidth <= 0 || Math.round(lineWidth) <= Math.round(maxWidth)) {
            this.addToLineResult(lineResult, lines, line, lineWidth, LineType.NewLineBreak | lineType);
        }
        else {
            let builder = '';
            let curLine = '';
            lineWidth = lineIndent;
            let curIndent = lineIndent;
            let reader = new StringTokenizer(line);
            let word = reader.peekWord();
            let isSingleWord = false;
            if (word.length !== reader.length) {
                if (word === ' ') {
                    curLine = curLine + word;
                    builder = builder + word;
                    reader.position += 1;
                    word = reader.peekWord();
                }
            }
            while (word != null) {
                curLine = curLine + word;
                let curLineWidth = this.getLineWidth(curLine.toString()) + curIndent /*)*/;
                if (curLine.toString() === ' ') {
                    curLine = '';
                    curLineWidth = 0;
                }
                if (curLineWidth > maxWidth) {
                    if (this.getWrapType() === PdfWordWrapType.None) {
                        break;
                    }
                    if (curLine.length === word.length) {
                        //  Character wrap is disabled or one symbol is greater than bounds.
                        if (this.getWrapType() === PdfWordWrapType.WordOnly) {
                            lineResult.textRemainder = line.substring(reader.position);
                            break;
                        }
                        else if (curLine.length === 1) {
                            builder = builder + word;
                            break;
                        }
                        else {
                            readWord = false;
                            curLine = '';
                            word = reader.peek().toString();
                            continue;
                        }
                    }
                    else {
                        if (this.getLineWidth(word.toString()) > maxWidth) {
                            this.format.wordWrap = PdfWordWrapType.Character;
                        }
                        else {
                            if (typeof this.format !== 'undefined' && this.format !== null) {
                                this.format.wordWrap = PdfWordWrapType.Word;
                            }
                        }
                        if (this.getWrapType() !== PdfWordWrapType.Character || !readWord) {
                            let ln = builder.toString();
                            // if (ln.indexOf(' ') === -1) {
                            //     isSingleWord = true;
                            //     this.addToLineResult(lineResult, lines, curLine, lineWidth, LineType.LayoutBreak | lineType);
                            // } else {
                            //     this.addToLineResult(lineResult, lines, ln, lineWidth, LineType.LayoutBreak | lineType);
                            // }                          
                            if (ln !== ' ') {
                                this.addToLineResult(lineResult, lines, ln, lineWidth, LineType.LayoutBreak | lineType);
                            }
                            if (this.isOverloadWithPosition) {
                                maxWidth = this.clientSize.width;
                            }
                            curLine = '';
                            builder = '';
                            lineWidth = 0;
                            curIndent = 0;
                            curLineWidth = 0;
                            lineType = LineType.None;
                            // if (isSingleWord) {
                            //     reader.readWord();
                            //     readWord = false;
                            // }
                            word = (readWord) ? word : reader.peekWord();
                            //isSingleWord = false;
                            readWord = true;
                        }
                        else {
                            readWord = false;
                            curLine = '';
                            curLine = curLine + builder.toString();
                            word = reader.peek().toString();
                        }
                        continue;
                    }
                }
                /*tslint:disable:max-func-body-length */
                builder = builder + word;
                lineWidth = curLineWidth;
                if (readWord) {
                    reader.readWord();
                    word = reader.peekWord();
                    //isSingleWord = false;
                }
                else {
                    reader.read();
                    word = reader.peek().toString();
                }
            }
            if (builder.length > 0) {
                let ln = builder.toString();
                this.addToLineResult(lineResult, lines, ln, lineWidth, LineType.NewLineBreak | LineType.LastParagraphLine);
            }
            reader.close();
        }
        lineResult.layoutLines = [];
        for (let index = 0; index < lines.length; index++) {
            lineResult.layoutLines.push(lines[index]);
        }
        lines = [];
        return lineResult;
    }
    /**
     * `Adds` line to line result.
     * @private
     */
    addToLineResult(lineResult, lines, line, lineWidth, breakType) {
        let info = new LineInfo();
        info.text = line;
        info.width = lineWidth;
        info.lineType = breakType;
        lines.push(info);
        let size = lineResult.actualSize;
        size.height += this.getLineHeight();
        size.width = Math.max(size.width, lineWidth);
        lineResult.size = size;
    }
    /**
     * `Copies` layout result from line result to entire result. Checks whether we can proceed lay outing or not.
     * @private
     */
    copyToResult(result, lineResult, lines, 
    /*out*/ numInserted) {
        let success = true;
        let allowPartialLines = (this.format != null && !this.format.lineLimit);
        let height = result.actualSize.height;
        let maxHeight = this.size.height;
        if ((this.pageHeight > 0) && (maxHeight + this.rectangle.y > this.pageHeight)) {
            maxHeight = this.rectangle.y - this.pageHeight;
            maxHeight = Math.max(maxHeight, -maxHeight);
        }
        numInserted = 0;
        if (lineResult.lines != null) {
            for (let i = 0, len = lineResult.lines.length; i < len; i++) {
                let expHeight = height + lineResult.lineHeight;
                if (expHeight <= maxHeight || maxHeight <= 0 || allowPartialLines) {
                    let info = lineResult.lines[i];
                    numInserted += info.text.length;
                    info = this.trimLine(info, (lines.length === 0));
                    lines.push(info);
                    // Update width.
                    let size = result.actualSize;
                    size.width = Math.max(size.width, info.width);
                    result.size = size;
                    // The part of the line fits only and it's allowed to use partial lines.
                    // if (expHeight >= maxHeight && maxHeight > 0 && allowPartialLines)
                    // {
                    //     let shouldClip : boolean = (this.format == null || !this.format.noClip);
                    //     if (shouldClip)
                    //     {
                    //         let exceededHeight : number = expHeight - maxHeight;
                    //         let fitHeight : number  = /*Utils.Round(*/ lineResult.lineHeight - exceededHeight /*)*/;
                    //         height = /*Utils.Round(*/ height + fitHeight /*)*/;
                    //     }
                    //     else
                    //     {
                    //         height = expHeight;
                    //     }
                    //     success = false;
                    //     break;
                    // } else {
                    height = expHeight;
                    // }
                }
                else {
                    success = false;
                    break;
                }
            }
        }
        if (height != result.size.height) {
            let size1 = result.actualSize;
            size1.height = height;
            result.size = size1;
        }
        return { success: success, numInserted: numInserted };
    }
    /**
     * `Finalizes` final result.
     * @private
     */
    finalizeResult(result, lines) {
        result.layoutLines = [];
        for (let index = 0; index < lines.length; index++) {
            result.layoutLines.push(lines[index]);
        }
        result.layoutLineHeight = this.getLineHeight();
        if (!this.reader.end) {
            result.textRemainder = this.reader.readToEnd();
        }
        lines = [];
    }
    /**
     * `Trims` whitespaces at the line.
     * @private
     */
    trimLine(info, firstLine) {
        let line = info.text;
        let lineWidth = info.width;
        // Trim start whitespaces if the line is not a start of the paragraph only.
        let trimStartSpaces = ((info.lineType & LineType.FirstParagraphLine) === 0);
        let start = (this.format == null || !this.format.rightToLeft);
        let spaces = StringTokenizer.spaces;
        line = (start) ? line.trim() : line.trim();
        // Recalculate line width.
        if (line.length !== info.text.length) {
            lineWidth = this.getLineWidth(line);
            if ((info.lineType & LineType.FirstParagraphLine) > 0) {
                lineWidth += this.getLineIndent(firstLine);
            }
        }
        info.text = line;
        info.width = lineWidth;
        return info;
    }
    /**
     * Returns `wrap` type.
     * @private
     */
    getWrapType() {
        let wrapType = (this.format != null) ? this.format.wordWrap : PdfWordWrapType.Word;
        return wrapType;
    }
}
//Internal declaration
export class PdfStringLayoutResult {
    // Properties
    /**
     * Gets the `text` which is not lay outed.
     * @private
     */
    get remainder() {
        return this.textRemainder;
    }
    /**
     * Gets the actual layout text `bounds`.
     * @private
     */
    get actualSize() {
        if (typeof this.size === 'undefined') {
            this.size = new SizeF(0, 0);
        }
        return this.size;
    }
    /**
     * Gets layout `lines` information.
     * @private
     */
    get lines() {
        return this.layoutLines;
    }
    /**
     * Gets the `height` of the line.
     * @private
     */
    get lineHeight() {
        return this.layoutLineHeight;
    }
    /**
     * Gets value that indicates whether any layout text [`empty`].
     * @private
     */
    get empty() {
        return (this.layoutLines == null || this.layoutLines.length === 0);
    }
    /**
     * Gets `number of` the layout lines.
     * @private
     */
    get lineCount() {
        let count = (!this.empty) ? this.layoutLines.length : 0;
        return count;
    }
}
export class LineInfo {
    //Properties
    /**
     * Gets the `type` of the line text.
     * @private
     */
    get lineType() {
        return this.type;
    }
    set lineType(value) {
        this.type = value;
    }
    /**
     * Gets the line `text`.
     * @private
     */
    get text() {
        return this.content;
    }
    set text(value) {
        this.content = value;
    }
    /**
     * Gets `width` of the line text.
     * @private
     */
    get width() {
        return this.lineWidth;
    }
    set width(value) {
        this.lineWidth = value;
    }
}
/**
* Break type of the `line`.
* @private
*/
export var LineType;
(function (LineType) {
    /**
     * Specifies the type of `None`.
     * @private
     */
    LineType[LineType["None"] = 0] = "None";
    /**
     * Specifies the type of `NewLineBreak`.
     * @private
     */
    LineType[LineType["NewLineBreak"] = 1] = "NewLineBreak";
    /**
     * Specifies the type of `LayoutBreak`.
     * @private
     */
    LineType[LineType["LayoutBreak"] = 2] = "LayoutBreak";
    /**
     * Specifies the type of `FirstParagraphLine`.
     * @private
     */
    LineType[LineType["FirstParagraphLine"] = 4] = "FirstParagraphLine";
    /**
     * Specifies the type of `LastParagraphLine`.
     * @private
     */
    LineType[LineType["LastParagraphLine"] = 8] = "LastParagraphLine";
})(LineType || (LineType = {}));
