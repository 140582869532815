/**
 * PdfMargins.ts class for EJ2-PDF
 * A class representing PDF page margins.
 */
export class PdfMargins {
    /**
     * Initializes a new instance of the `PdfMargins` class.
     * @private
     */
    constructor() {
        /**
         * Represents the `Default Page Margin` value.
         * @default 0.0
         * @private
         */
        this.pdfMargin = 40.0;
        this.setMargins(this.pdfMargin);
    }
    //Properties
    /**
     * Gets or sets the `left margin` size.
     * @private
     */
    get left() {
        return this.leftMargin;
    }
    set left(value) {
        this.leftMargin = value;
    }
    /**
     * Gets or sets the `top margin` size.
     * @private
     */
    get top() {
        return this.topMargin;
    }
    set top(value) {
        this.topMargin = value;
    }
    /**
     * Gets or sets the `right margin` size.
     * @private
     */
    get right() {
        return this.rightMargin;
    }
    set right(value) {
        this.rightMargin = value;
    }
    /**
     * Gets or sets the `bottom margin` size.
     * @private
     */
    get bottom() {
        return this.bottomMargin;
    }
    set bottom(value) {
        this.bottomMargin = value;
    }
    /**
     * Sets the `margins`.
     * @private
     */
    set all(value) {
        this.setMargins(value);
    }
    setMargins(margin1, margin2, margin3, margin4) {
        if (typeof margin2 === 'undefined') {
            this.leftMargin = this.topMargin = this.rightMargin = this.bottomMargin = margin1;
        }
        else {
            if (typeof margin3 === 'undefined') {
                this.leftMargin = this.rightMargin = margin1;
                this.bottomMargin = this.topMargin = margin2;
            }
            else {
                this.leftMargin = margin1;
                this.topMargin = margin2;
                this.rightMargin = margin3;
                this.bottomMargin = margin4;
            }
        }
    }
    /**
     * `Clones` the object.
     * @private
     */
    clone() {
        return this;
    }
}
