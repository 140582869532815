/**
 * PdfPageCountField.ts class for EJ2-PDF
 */
import { PdfSingleValueField } from './single-value-field';
import { PdfNumberStyle } from './../../pages/enum';
import { PdfBrush } from './../../graphics/brushes/pdf-brush';
import { PdfNumbersConvertor } from './pdf-numbers-convertor';
/**
 * Represents total PDF document page count automatic field.
 */
export class PdfPageCountField extends PdfSingleValueField {
    constructor(font, arg2) {
        super();
        // Fields
        /**
         * Stores the number style of the field.
         * @private
         */
        this.internalNumberStyle = PdfNumberStyle.Numeric;
        if (typeof arg2 === 'undefined') {
            this.font = font;
        }
        else if (arg2 instanceof PdfBrush) {
            this.font = font;
            this.brush = arg2;
        }
        else {
            this.font = font;
            this.bounds = arg2;
        }
    }
    // Properties
    /**
     * Gets and sets the number style of the field.
     * @public
     */
    get numberStyle() {
        return this.internalNumberStyle;
    }
    set numberStyle(value) {
        this.internalNumberStyle = value;
    }
    // Implementation
    /**
     * Return the actual value of the content to drawn.
     * @public
     */
    getValue(graphics) {
        let result = null;
        let page = this.getPageFromGraphics(graphics);
        let document = page.section.parent.document;
        let count = document.pages.count;
        result = PdfNumbersConvertor.convert(count, this.numberStyle);
        return result;
    }
}
