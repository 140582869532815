/**
 * TtfTableInfo.ts class for EJ2-PDF
 */
export class TtfTableInfo {
    //Properties
    /**
     * Gets a value indicating whether this table is empty.
     * @private
     */
    get empty() {
        let empty = (this.offset === this.length && this.length === this.checksum && this.checksum === 0);
        return empty;
    }
}
