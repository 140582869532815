/**
 * PdfSampledFunction.ts class for EJ2-PDF
 * Implements PDF Sampled Function.
 */
import { PdfArray } from './../../primitives/pdf-array';
import { PdfFunction } from './pdf-function';
import { PdfStream } from './../../primitives/pdf-stream';
import { PdfNumber } from './../../primitives/pdf-number';
export class PdfSampledFunction extends PdfFunction {
    /**
     * Initializes a new instance of the `PdfSampledFunction` class.
     * @public
     */
    constructor(domain, range, sizes, samples) {
        super(new PdfStream());
        if (typeof domain === 'undefined') {
            this.dictionary.items.setValue(this.mDictionaryProperties.functionType, new PdfNumber(0));
        }
        else {
            this.dictionary.items.setValue(this.mDictionaryProperties.functionType, new PdfNumber(0));
            this.checkParams(domain, range, sizes, samples);
            this.setDomainAndRange(domain, range);
            this.setSizeAndValues(sizes, samples);
        }
    }
    // Helper methods
    /**
     * Checks the input parameters.
     */
    checkParams(domain, range, sizes, samples) {
        let rLength = range.length;
        let dLength = domain.length;
        let sLength = samples.length;
        let frameLength = (rLength * (dLength / 4));
    }
    /**
     * Sets the domain and range.
     */
    setDomainAndRange(domain, range) {
        this.domain = new PdfArray(domain);
        this.range = new PdfArray(range);
    }
    /**
     * Sets the size and values.
     */
    setSizeAndValues(sizes, samples) {
        let s = (this.dictionary);
        this.dictionary.items.setValue(this.mDictionaryProperties.size, new PdfArray(sizes));
        this.dictionary.items.setValue(this.mDictionaryProperties.bitsPerSample, new PdfNumber(8));
        s.writeBytes(samples);
    }
}
