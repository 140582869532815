/**
 * PdfDrawElement.ts class for EJ2-PDF
 */
import { PdfShapeElement } from './pdf-shape-element';
/**
 * Represents a base class for all page graphics elements.
 */
export class PdfDrawElement extends PdfShapeElement {
    /**
     * Initializes a new instance of the `PdfDrawElement` class.
     * @protected
     */
    constructor(pen) {
        super();
        if (typeof pen !== 'undefined') {
            this.mpen = pen;
        }
    }
    // Properties
    /**
     * Gets or sets a pen that will be used to draw the element.
     * @public
     */
    get pen() {
        return this.mpen;
    }
    set pen(value) {
        this.mpen = value;
    }
}
