import { ObjectStatus } from './../input-output/enum';
import { PdfDictionary } from './../primitives/pdf-dictionary';
import { DictionaryProperties } from './pdf-dictionary-properties';
import { Operators } from './pdf-operators';
import { Dictionary } from './../collections/dictionary';
import { PdfReference } from './../primitives/pdf-reference';
import { ObjectType } from './cross-table';
import { PdfStream } from './../primitives/pdf-stream';
import { PdfNumber } from './../primitives/pdf-number';
import { PdfCatalog } from './../document/pdf-catalog';
/**
 * `PdfCrossTable` is responsible for intermediate level parsing
 * and savingof a PDF document.
 * @private
 */
export class PdfCrossTable {
    constructor() {
        /**
         * The modified `objects` that should be saved.
         * @private
         */
        this.objects = new Dictionary();
        /**
         * Holds `maximal generation number` or offset to object.
         * @default 0
         * @private
         */
        this.maxGenNumIndex = 0;
        /**
         * The `number of the objects`.
         * @default 0
         * @private
         */
        this.objectCount = 0;
        /**
         * Internal variable for accessing fields from `DictionryProperties` class.
         * @default new PdfDictionaryProperties()
         * @private
         */
        this.dictionaryProperties = new DictionaryProperties();
    }
    //Properties
    /**
     * Gets or sets if the document `is merged`.
     * @private
     */
    get isMerging() {
        return this.merging;
    }
    set isMerging(value) {
        this.merging = value;
    }
    /**
     * Gets the `trailer`.
     * @private
     */
    get trailer() {
        if (this.internalTrailer == null) {
            this.internalTrailer = new PdfStream();
        }
        return this.internalTrailer;
    }
    /**
     * Gets or sets the main `PdfDocument` class instance.
     * @private
     */
    get document() {
        return this.pdfDocument;
    }
    set document(value) {
        this.pdfDocument = value;
        this.items = this.pdfDocument.pdfObjects;
    }
    /**
     * Gets the catched `PDF object` main collection.
     * @private
     */
    get pdfObjects() {
        return this.items;
    }
    /**
     * Gets the `object collection`.
     * @private
     */
    get objectCollection() {
        return this.pdfDocument.pdfObjects;
    }
    /**
     * Gets or sets the `number of the objects` within the document.
     * @private
     */
    get count() {
        return this.objectCount;
    }
    set count(value) {
        this.objectCount = value;
    }
    /**
     * Returns `next available object number`.
     * @private
     */
    get nextObjNumber() {
        this.count = this.count + 1;
        return this.count;
    }
    save(writer, filename) {
        this.saveHead(writer);
        let state = false;
        this.mappedReferences = null;
        this.objects.clear();
        this.markTrailerReferences();
        this.saveObjects(writer);
        let saveCount = this.count;
        let xrefPos = writer.position;
        this.registerObject(0, new PdfReference(0, -1), true);
        let prevXRef = 0;
        writer.write(Operators.xref);
        writer.write(Operators.newLine);
        this.saveSections(writer);
        this.saveTrailer(writer, this.count, prevXRef);
        this.saveTheEndess(writer, xrefPos);
        this.count = saveCount;
        for (let i = 0; i < this.objectCollection.count; ++i) {
            let oi = this.objectCollection.items(i);
            oi.object.isSaving = false;
        }
        if (typeof filename === 'undefined') {
            return writer.stream.buffer;
        }
        else {
            writer.stream.save(filename);
        }
    }
    /**
     * `Saves the endess` of the file.
     * @private
     */
    saveTheEndess(writer, xrefPos) {
        writer.write(Operators.newLine + Operators.startxref + Operators.newLine);
        writer.write(xrefPos.toString() + Operators.newLine);
        writer.write(Operators.eof + Operators.newLine);
    }
    /**
     * `Saves the new trailer` dictionary.
     * @private
     */
    saveTrailer(writer, count, prevXRef) {
        writer.write(Operators.trailer + Operators.newLine);
        // Save the dictionary.
        let trailer = this.trailer;
        trailer.items.setValue(this.dictionaryProperties.size, new PdfNumber(this.objectCount + 1));
        trailer = new PdfDictionary(trailer); // Make it real dictionary.
        trailer.setEncrypt(false);
        trailer.save(writer);
    }
    /**
     * `Saves the xref section`.
     * @private
     */
    saveSections(writer) {
        let objectNum = 0;
        let count = 0;
        do {
            count = this.prepareSubsection(objectNum);
            this.saveSubsection(writer, objectNum, count);
            objectNum += count;
        } while (count !== 0);
    }
    /**
     * `Saves a subsection`.
     * @private
     */
    saveSubsection(writer, objectNum, count) {
        if (count <= 0 || objectNum >= this.count) {
            return;
        }
        let subsectionHead = '{0} {1}{2}';
        writer.write(objectNum + ' ' + (count + 1) + Operators.newLine);
        for (let i = objectNum; i <= objectNum + count; ++i) {
            let obj = this.objects.getValue(i);
            let str = '';
            if (obj.type === ObjectType.Free) {
                str = this.getItem(obj.offset, 65535, true);
            }
            else {
                str = this.getItem(obj.offset, obj.generation, false);
            }
            writer.write(str);
        }
    }
    /**
     * Generates string for `xref table item`.
     * @private
     */
    getItem(offset, genNumber, isFree) {
        let returnString = '';
        let addOffsetLength = 10 - offset.toString().length;
        if (genNumber <= 0) {
            genNumber = 0;
        }
        let addGenNumberLength = (5 - genNumber.toString().length) <= 0 ? 0 : (5 - genNumber.toString().length);
        for (let index = 0; index < addOffsetLength; index++) {
            returnString = returnString + '0';
        }
        returnString = returnString + offset.toString() + ' ';
        for (let index = 0; index < addGenNumberLength; index++) {
            returnString = returnString + '0';
        }
        returnString = returnString + genNumber.toString() + ' ';
        returnString = returnString + ((isFree) ? Operators.f : Operators.n) + Operators.newLine;
        return returnString;
    }
    /**
     * `Prepares a subsection` of the current section within the cross-reference table.
     * @private
     */
    prepareSubsection(objectNum) {
        let count = 0;
        let i;
        let total = this.count;
        for (let k = 0; k < this.document.pdfObjects.count; k++) {
            let reference = this.document.pdfObjects.items(k).reference;
            let refString = reference.toString();
            let refArray = refString.split(' ');
        }
        if (objectNum >= total) {
            return count;
        }
        // search for first changed indirect object.
        for (i = objectNum; i < total; ++i) {
            break;
        }
        objectNum = i;
        // look up for all indirect objects in one subsection.
        for (; i < total; ++i) {
            ++count;
        }
        return count;
    }
    /**
     * `Marks the trailer references` being saved.
     * @private
     */
    markTrailerReferences() {
        let tempArray;
        let keys = this.trailer.items.keys();
        let values = this.trailer.items.values();
    }
    /**
     * `Saves the head`.
     * @private
     */
    saveHead(writer) {
        let version = this.generateFileVersion(writer.document);
        writer.write('%PDF-' + version);
        writer.write(Operators.newLine);
    }
    /**
     * Generates the `version` of the file.
     * @private
     */
    generateFileVersion(document) {
        let iVersion = 4;
        let version = '1.' + iVersion.toString();
        return version;
    }
    getReference(obj, bNew) {
        if (typeof bNew === 'undefined') {
            let wasNew = false;
            return this.getReference(obj, wasNew);
        }
        else {
            //code splitted for reducing lines of code exceeds 100.
            return this.getSubReference(obj, bNew);
        }
    }
    /**
     * Retrieves the `reference` of the object given.
     * @private
     */
    getSubReference(obj, bNew) {
        let isNew = false;
        let wasNew;
        let reference = null;
        // if (obj.IsSaving) {
        if (this.items.count > 0 && obj.objectCollectionIndex > 0 && this.items.count > obj.objectCollectionIndex - 1) {
            let tempObj = this.document.pdfObjects.getReference(obj, wasNew);
            reference = tempObj.reference;
            wasNew = tempObj.wasNew;
        }
        if (reference == null) {
            if (obj.status === ObjectStatus.Registered) {
                wasNew = false;
            }
            else {
                wasNew = true;
            }
        }
        else {
            wasNew = false;
        }
        // need to add mapped reference code
        if (reference == null) {
            let objnumber = this.nextObjNumber;
            reference = new PdfReference(objnumber, 0);
            let found;
            if (wasNew) {
                this.document.pdfObjects.add(obj);
                this.document.pdfObjects.trySetReference(obj, reference, found);
                let tempIndex = this.document.pdfObjects.count - 1;
                let tempkey = this.document.pdfObjects.objectCollections[tempIndex].reference.objNumber;
                let tempvalue = this.document.pdfObjects.objectCollections[this.document.pdfObjects.count - 1];
                this.document.pdfObjects.mainObjectCollection.setValue(tempkey, tempvalue);
                obj.position = -1;
            }
            else {
                this.document.pdfObjects.trySetReference(obj, reference, found);
            }
            obj.objectCollectionIndex = reference.objNumber;
            obj.status = ObjectStatus.None;
            isNew = true;
        }
        bNew = isNew || this.bForceNew;
        return reference;
    }
    /**
     * `Saves all objects` in the collection.
     * @private
     */
    saveObjects(writer) {
        let objectCollection = this.objectCollection;
        for (let i = 0; i < objectCollection.count; ++i) {
            let oi = objectCollection.items(i);
            let obj = oi.object;
            obj.isSaving = true;
            this.saveIndirectObject(obj, writer);
        }
    }
    /**
     * `Saves indirect object`.
     * @private
     */
    saveIndirectObject(obj, writer) {
        let reference = this.getReference(obj);
        if (obj instanceof PdfCatalog) {
            this.trailer.items.setValue(this.dictionaryProperties.root, reference);
        }
        // NOTE :  This is needed for correct string objects encryption.
        this.pdfDocument.currentSavingObj = reference;
        let tempArchive = false;
        tempArchive = obj.getArchive();
        let allowedType = !((obj instanceof PdfStream) || !tempArchive || (obj instanceof PdfCatalog));
        let sigFlag = false;
        this.registerObject(writer.position, reference);
        this.doSaveObject(obj, reference, writer);
    }
    /**
     * Performs `real saving` of the save object.
     * @private
     */
    doSaveObject(obj, reference, writer) {
        let correctPosition = writer.length;
        writer.write(reference.objNumber.toString());
        writer.write(Operators.whiteSpace);
        writer.write(reference.genNumber.toString());
        writer.write(Operators.whiteSpace);
        writer.write(Operators.obj);
        writer.write(Operators.newLine);
        obj.save(writer);
        let stream = writer.stream;
        writer.write(Operators.endObj);
        writer.write(Operators.newLine);
    }
    registerObject(offset, reference, free) {
        if (typeof free === 'boolean') {
            // Register the object by its number.
            this.objects.setValue(reference.objNumber, new RegisteredObject(offset, reference, free));
            this.maxGenNumIndex = Math.max(this.maxGenNumIndex, reference.genNumber);
        }
        else if (typeof free === 'undefined') {
            // Register the object by its number.
            this.objects.setValue(reference.objNumber, new RegisteredObject(offset, reference));
            this.maxGenNumIndex = Math.max(this.maxGenNumIndex, reference.genNumber);
        }
    }
    /**
     * `Dereferences` the specified primitive object.
     * @private
     */
    static dereference(obj) {
        let rh = obj;
        if (rh != null) {
            obj = rh.object;
        }
        return obj;
    }
}
export class RegisteredObject {
    //Properties
    /**
     * Gets the `object number`.
     * @private
     */
    get objectNumber() {
        return this.object;
    }
    /**
     * Gets the `offset`.
     * @private
     */
    get offset() {
        let result;
        result = this.offsetNumber;
        return result;
    }
    constructor(offset, reference, free) {
        let tempOffset = offset;
        this.offsetNumber = tempOffset;
        let tempReference = reference;
        this.generation = tempReference.genNumber;
        this.object = tempReference.objNumber;
        if (typeof free === 'undefined') {
            this.type = ObjectType.Normal;
        }
        else {
            this.type = ObjectType.Free;
        }
    }
}
