/**
 * TextLayouter.ts class for EJ2-PDF
 */
import { ElementLayouter, PdfLayoutResult } from './element-layouter';
import { RectangleF, SizeF } from './../../../drawing/pdf-drawing';
import { PdfStringLayouter } from './../../fonts/string-layouter';
import { PdfLayoutBreakType } from './../../figures/enum';
import { PdfColor } from './../../pdf-color';
import { PdfTextWebLink } from './../../../annotations/pdf-text-web-link';
/**
 * Class that `layouts the text`.
 * @private
 */
export class TextLayouter extends ElementLayouter {
    /**
     * Gets the layout `element`.
     * @private
     */
    get element() {
        return super.getElement();
    }
    // Constructors
    /**
     * Initializes a new instance of the `TextLayouter` class.
     * @private
     */
    constructor(element) {
        super(element);
    }
    // Implementation
    /**
     * `Layouts` the element.
     * @private
     */
    layoutInternal(param) {
        /* tslint:disable */
        this.format = (this.element.stringFormat !== null && typeof this.element.stringFormat !== 'undefined') ? this.element.stringFormat : null;
        let currentPage = param.page;
        let currentBounds = param.bounds;
        let text = this.element.value;
        let result = null;
        let pageResult = new TextPageLayoutResult();
        pageResult.page = currentPage;
        pageResult.remainder = text;
        for (;;) {
            pageResult = this.layoutOnPage(text, currentPage, currentBounds, param);
            result = this.getLayoutResult(pageResult);
            break;
        }
        /* tslint:enable */
        return result;
    }
    /**
     * Raises `PageLayout` event if needed.
     * @private
     */
    getLayoutResult(pageResult) {
        let result = new PdfTextLayoutResult(pageResult.page, pageResult.bounds, pageResult.remainder, pageResult.lastLineBounds);
        return result;
    }
    /* tslint:disable */
    /**
     * `Layouts` the text on the page.
     * @private
     */
    layoutOnPage(text, currentPage, currentBounds, param) {
        let result = new TextPageLayoutResult();
        result.remainder = text;
        result.page = currentPage;
        currentBounds = this.checkCorrectBounds(currentPage, currentBounds);
        let layouter = new PdfStringLayouter();
        let stringResult = layouter.layout(text, this.element.font, this.format, currentBounds, currentPage.getClientSize().height, false, new SizeF(0, 0));
        let textFinished = (stringResult.remainder == null);
        let doesntFit = (param.format.break === PdfLayoutBreakType.FitElement);
        let canDraw = !(doesntFit || stringResult.empty);
        // Draw the text.
        let graphics = currentPage.graphics;
        let brush = this.element.getBrush();
        if (this.element instanceof PdfTextWebLink) {
            brush.color = new PdfColor(0, 0, 255);
        }
        graphics.drawStringLayoutResult(stringResult, this.element.font, this.element.pen, brush, currentBounds, this.format);
        let lineInfo = stringResult.lines[stringResult.lineCount - 1];
        result.lastLineBounds = graphics.getLineBounds(stringResult.lineCount - 1, stringResult, this.element.font, currentBounds, this.format);
        result.bounds = this.getTextPageBounds(currentPage, currentBounds, stringResult);
        result.remainder = stringResult.remainder;
        result.end = (textFinished);
        return result;
    }
    /* tslint:enable */
    /**
     * `Corrects current bounds` on the page.
     * @private
     */
    checkCorrectBounds(currentPage, currentBounds) {
        let pageSize = currentPage.graphics.clientSize;
        currentBounds.height = (currentBounds.height > 0) ? currentBounds.height : pageSize.height - currentBounds.y;
        return currentBounds;
    }
    /**
     * Returns a `rectangle` where the text was printed on the page.
     * @private
     */
    /* tslint:disable */
    getTextPageBounds(currentPage, currentBounds, stringResult) {
        let textSize = stringResult.actualSize;
        let x = currentBounds.x;
        let y = currentBounds.y;
        let width = (currentBounds.width > 0) ? currentBounds.width : textSize.width;
        let height = textSize.height;
        let shiftedRect = currentPage.graphics.checkCorrectLayoutRectangle(textSize, currentBounds.x, currentBounds.y, this.format);
        // if (currentBounds.width <= 0) {
        x = shiftedRect.x;
        // }
        let verticalShift = currentPage.graphics.getTextVerticalAlignShift(textSize.height, currentBounds.height, this.format);
        y += verticalShift;
        let bounds = new RectangleF(x, y, width, height);
        return bounds;
    }
}
export class TextPageLayoutResult {
}
export class PdfTextLayoutResult extends PdfLayoutResult {
    // Properties
    /**
     * Gets a value that contains the `text` that was not printed.
     * @private
     */
    get remainder() {
        return this.remainderText;
    }
    /**
     * Gets a value that indicates the `bounds` of the last line that was printed on the page.
     * @private
     */
    get lastLineBounds() {
        return this.lastLineTextBounds;
    }
    // Constructors
    /**
     * Initializes the new instance of `PdfTextLayoutResult` class.
     * @private
     */
    constructor(page, bounds, remainder, lastLineBounds) {
        super(page, bounds);
        this.remainderText = remainder;
        this.lastLineTextBounds = lastLineBounds;
    }
}
