/**
 * `PdfString` class is used to perform string related primitive operations.
 * @private
 */
export var InternalEnum;
(function (InternalEnum) {
    //Internals
    /**
     * public Enum for `ForceEncoding`.
     * @private
     */
    let ForceEncoding;
    (function (ForceEncoding) {
        /**
         * Specifies the type of `None`.
         * @private
         */
        ForceEncoding[ForceEncoding["None"] = 0] = "None";
        /**
         * Specifies the type of `Ascii`.
         * @private
         */
        ForceEncoding[ForceEncoding["Ascii"] = 1] = "Ascii";
        /**
         * Specifies the type of `Unicode`.
         * @private
         */
        ForceEncoding[ForceEncoding["Unicode"] = 2] = "Unicode";
    })(ForceEncoding = InternalEnum.ForceEncoding || (InternalEnum.ForceEncoding = {}));
    /**
     * public Enum for `SourceType`.
     * @private
     */
    let SourceType;
    (function (SourceType) {
        /**
         * Specifies the type of `StringValue`.
         * @private
         */
        SourceType[SourceType["StringValue"] = 0] = "StringValue";
        /**
         * Specifies the type of `ByteBuffer`.
         * @private
         */
        SourceType[SourceType["ByteBuffer"] = 1] = "ByteBuffer";
    })(SourceType || (SourceType = {}));
})(InternalEnum || (InternalEnum = {}));
export class PdfString {
    constructor(value) {
        /**
         * Value indicating whether the string was converted to hex.
         * @default false
         * @private
         */
        this.bHex = false;
        /**
         * Internal variable to store the `position`.
         * @default -1
         * @private
         */
        this.position1 = -1;
        /**
         * Internal variable to hold `cloned object`.
         * @default null
         * @private
         */
        this.clonedObject1 = null;
        /**
         * `Shows` if the data of the stream was decrypted.
         * @default false
         * @private
         */
        this.bDecrypted = false;
        /**
         * Shows if the data of the stream `was decrypted`.
         * @default false
         * @private
         */
        this.isParentDecrypted = false;
        /**
         * Gets a value indicating whether the object is `packed or not`.
         * @default false
         * @private
         */
        this.isPacked = false;
        /**
         * @hidden
         * @private
         */
        this.isFormField = false;
        /**
         * @hidden
         * @private
         */
        this.isColorSpace = false;
        /**
         * @hidden
         * @private
         */
        this.isHexString = true;
        if (typeof value === 'undefined') {
            this.bHex = false;
        }
        else {
            if (!(value.length > 0 && value[0] === '0xfeff')) {
                this.stringValue = value;
                this.data = [];
                for (let i = 0; i < value.length; ++i) {
                    this.data.push(value.charCodeAt(i));
                }
            }
        }
    }
    //Property
    /**
     * Gets a value indicating whether string is in `hex`.
     * @private
     */
    get hex() {
        return this.bHex;
    }
    /**
     * Gets or sets string `value` of the object.
     * @private
     */
    get value() {
        return this.stringValue;
    }
    set value(value) {
        this.stringValue = value;
        this.data = null;
    }
    /**
     * Gets or sets the `Status` of the specified object.
     * @private
     */
    get status() {
        return this.status1;
    }
    set status(value) {
        this.status1 = value;
    }
    /**
     * Gets or sets a value indicating whether this document `is saving` or not.
     * @private
     */
    get isSaving() {
        return this.isSaving1;
    }
    set isSaving(value) {
        this.isSaving1 = value;
    }
    /**
     * Gets or sets the `index` value of the specified object.
     * @private
     */
    get objectCollectionIndex() {
        return this.index1;
    }
    set objectCollectionIndex(value) {
        this.index1 = value;
    }
    /**
     * Returns `cloned object`.
     * @private
     */
    get clonedObject() {
        return this.clonedObject1;
    }
    /**
     * Gets or sets the `position` of the object.
     * @private
     */
    get position() {
        return this.position1;
    }
    set position(value) {
        this.position1 = value;
    }
    /**
     * Returns `PdfCrossTable` associated with the object.
     * @private
     */
    get CrossTable() {
        return this.crossTable;
    }
    /**
     * Gets a value indicating whether to check if the value has unicode characters.
     * @private
     */
    get converted() {
        return this.bConverted;
    }
    /**
     * sets a value indicating whether to check if the value has unicode characters.
     * @private
     */
    set converted(value) {
        this.bConverted = value;
    }
    /**
     * Gets value indicating whether we should convert data to Unicode.
     */
    get encode() {
        return this.bForceEncoding;
    }
    set encode(value) {
        this.bForceEncoding = value;
    }
    //Methods
    /**
     * Converts `bytes to string using hex format` for representing string.
     * @private
     */
    static bytesToHex(bytes) {
        if (bytes == null) {
            return '';
        }
        let builder = '';
        return builder;
    }
    /**
     * `Saves` the object using the specified writer.
     * @private
     */
    save(writer) {
        if (writer === null) {
            throw new Error('ArgumentNullException : writer');
        }
        if (this.encode !== undefined && this.encode === InternalEnum.ForceEncoding.Ascii) {
            writer.write(this.pdfEncode());
        }
        else {
            writer.write(PdfString.stringMark[0] + this.value + PdfString.stringMark[1]);
        }
    }
    pdfEncode() {
        let result = '';
        if (this.encode !== undefined && this.encode === InternalEnum.ForceEncoding.Ascii) {
            let data = this.escapeSymbols(this.value);
            for (let i = 0; i < data.length; i++) {
                result += String.fromCharCode(data[i]);
            }
            result = PdfString.stringMark[0] + result + PdfString.stringMark[1];
        }
        else {
            result = this.value;
        }
        return result;
    }
    escapeSymbols(value) {
        let data = [];
        for (let i = 0; i < value.length; i++) {
            let currentData = value.charCodeAt(i);
            switch (currentData) {
                case 40:
                case 41:
                    data.push(92);
                    data.push(currentData);
                    break;
                case 13:
                    data.push(92);
                    data.push(114);
                    break;
                case 92:
                    data.push(92);
                    data.push(currentData);
                    break;
                default:
                    data.push(currentData);
                    break;
            }
        }
        return data;
    }
    /**
     * Creates a `copy of PdfString`.
     * @private
     */
    clone(crossTable) {
        if (this.clonedObject1 !== null && this.clonedObject1.CrossTable === crossTable) {
            return this.clonedObject1;
        }
        else {
            this.clonedObject1 = null;
        }
        let newString = new PdfString(this.stringValue);
        newString.bHex = this.bHex;
        newString.crossTable = crossTable;
        newString.isColorSpace = this.isColorSpace;
        this.clonedObject1 = newString;
        return newString;
    }
    /**
     * Converts string to array of unicode symbols.
     */
    static toUnicodeArray(value, bAddPrefix) {
        if (value == null) {
            throw new Error('Argument Null Exception : value');
        }
        let startIndex = 0;
        let output = [];
        for (let i = 0; i < value.length; i++) {
            let code = value.charCodeAt(i);
            output.push(code / 256 >>> 0);
            output.push(code & 0xff);
        }
        return output;
    }
    /**
     * Converts byte data to string.
     */
    static byteToString(data) {
        if (data == null) {
            throw new Error('Argument Null Exception : stream');
        }
        let result = '';
        for (let i = 0; i < data.length; ++i) {
            result += String.fromCharCode(data[i]);
        }
        return result;
    }
}
//constants = ;
/**
 * `General markers` for string.
 * @private
 */
PdfString.stringMark = '()';
/**
 * `Hex markers` for string.
 * @private
 */
PdfString.hexStringMark = '<>';
/**
 * Format of password data.
 * @private
 */
PdfString.hexFormatPattern = '{0:X2}';
