/**
 * PdfTrueTypeFont.ts class for EJ2-PDF
 */
import { UnicodeTrueTypeFont } from './unicode-true-type-font';
import { PdfFont } from './pdf-font';
import { PdfFontStyle } from './enum';
import { PdfDocument } from './../../document/pdf-document';
import { PdfTextDirection } from './../enum';
import { RtlRenderer } from './rtl-renderer';
//https://www.giftofspeed.com/base64-encoder/
export class PdfTrueTypeFont extends PdfFont {
    constructor(base64String, size, style) {
        super(size);
        /**
         * Indicates whether the font is embedded or not.
         * @private
         */
        this.isEmbedFont = false;
        /**
         * Indicates whether the font is unicoded or not.
         * @private
         */
        this.isUnicode = true;
        if (style !== undefined) {
            this.createFontInternal(base64String, style);
        }
        else {
            this.createFontInternal(base64String, PdfFontStyle.Regular);
        }
    }
    equalsToFont(font) {
        let result = false;
        //let result : boolean = this.fontInternal.equalsToFont(font);
        return result;
    }
    getLineWidth(line, format) {
        let width = 0;
        if (format !== null && typeof format !== 'undefined' && format.textDirection !== PdfTextDirection.None) {
            let returnValue = this.getUnicodeLineWidth(line, /*out*/ width, format);
            width = returnValue.width;
        }
        else {
            width = this.fontInternal.getLineWidth(line);
        }
        let size = this.metrics.getSize(format);
        width *= (PdfFont.charSizeMultiplier * size);
        width = this.applyFormatSettings(line, format, width);
        return width;
    }
    /**
     * Returns width of the char.
     */
    getCharWidth(charCode, format) {
        let codeWidth = this.fontInternal.getCharWidth(charCode);
        let size = this.metrics.getSize(format);
        codeWidth *= (0.001 * size);
        return codeWidth;
    }
    //Implementation
    createFontInternal(base64String, style) {
        this.fontInternal = new UnicodeTrueTypeFont(base64String, this.size);
        this.calculateStyle(style);
        this.initializeInternals();
    }
    calculateStyle(style) {
        let iStyle = this.fontInternal.ttfMetrics.macStyle;
        if ((style & PdfFontStyle.Underline) !== 0) {
            iStyle |= PdfFontStyle.Underline;
        }
        if ((style & PdfFontStyle.Strikeout) !== 0) {
            iStyle |= PdfFontStyle.Strikeout;
        }
        this.setStyle(iStyle);
    }
    initializeInternals() {
        let equalFont = null;
        if (PdfDocument.enableCache) {
            // Search for the similar fonts.
            equalFont = PdfDocument.cache.search(this);
        }
        let internals = null;
        // There is not equal font in the cache.
        if (equalFont !== null && equalFont !== undefined) {
            // Get the settings from the cached font.
            internals = equalFont.getInternals();
            let metrics = equalFont.metrics;
            metrics = metrics.clone();
            metrics.size = this.size;
            this.metrics = metrics;
            this.fontInternal = equalFont.fontInternal;
        }
        else {
            if (equalFont == null) {
                if (this.fontInternal instanceof UnicodeTrueTypeFont) {
                    this.fontInternal.isEmbed = this.isEmbedFont;
                }
                this.fontInternal.createInternals();
                internals = this.fontInternal.getInternals();
                this.metrics = this.fontInternal.metrics;
            }
        }
        this.metrics.isUnicodeFont = true;
        this.setInternals(internals);
        //this.ttfReader = (this.fontInternal as UnicodeTrueTypeFont).ttfReader;
    }
    /**
     * Stores used symbols.
     */
    setSymbols(text) {
        let internalFont = this.fontInternal;
        if (internalFont != null) {
            internalFont.setSymbols(text);
        }
    }
    /**
     * Property
     *
     */
    get Unicode() {
        return this.isUnicode;
    }
    // public get Font() : UnicodeTrueTypeFont {
    //     return this.fontInternal as UnicodeTrueTypeFont;
    // }
    getUnicodeLineWidth(line, /*out*/ width, format) {
        // if (line == null) {
        //     throw new Error('ArgumentNullException : line');
        // }
        width = 0;
        let glyphIndices = null;
        let rtlRender = new RtlRenderer();
        /* tslint:disable-next-line:max-line-length */
        let result = rtlRender.getGlyphIndex(line, this, (format.textDirection === PdfTextDirection.RightToLeft) ? true : false, /*out*/ glyphIndices, true);
        let resultGlyph = result.success;
        glyphIndices = result.glyphs;
        if (resultGlyph && glyphIndices !== null) {
            let ttfReader = this.fontInternal.ttfReader;
            for (let i = 0, len = glyphIndices.length; i < len; i++) {
                let glyphIndex = glyphIndices[i];
                let glyph = ttfReader.getGlyph(glyphIndex);
                if (glyph !== null && typeof glyph !== 'undefined') {
                    width += glyph.width;
                }
            }
        }
        return { success: resultGlyph, width: width };
    }
}
