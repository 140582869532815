/**
 * PdfPageSize.ts class for EJ2-PDF
 */
import { SizeF } from './../drawing/pdf-drawing';
/**
 * Represents information about various predefined `page sizes`.
 */
export class PdfPageSize {
    //constructor
    /**
     * Initialize an instance for `PdfPageSize` class.
     * @private
     */
    constructor() {
        // 
    }
}
/**
 * Specifies the size of `letter`.
 * @private
 */
PdfPageSize.letter = new SizeF(612, 792);
/**
 * Specifies the size of `note`.
 * @private
 */
PdfPageSize.note = new SizeF(540, 720);
/**
 * Specifies the size of `legal`.
 * @private
 */
PdfPageSize.legal = new SizeF(612, 1008);
/**
 * Specifies the size of `a0`.
 * @private
 */
PdfPageSize.a0 = new SizeF(2380, 3368);
/**
 * Specifies the size of `a1`.
 * @private
 */
PdfPageSize.a1 = new SizeF(1684, 2380);
/**
 * Specifies the size of `a2`.
 * @private
 */
PdfPageSize.a2 = new SizeF(1190, 1684);
/**
 * Specifies the size of `a3`.
 * @private
 */
PdfPageSize.a3 = new SizeF(842, 1190);
/**
 * Specifies the size of `a4`.
 * @private
 */
PdfPageSize.a4 = new SizeF(595, 842);
/**
 * Specifies the size of `a5`.
 * @private
 */
PdfPageSize.a5 = new SizeF(421, 595);
/**
 * Specifies the size of `a6`.
 * @private
 */
PdfPageSize.a6 = new SizeF(297, 421);
/**
 * Specifies the size of `a7`.
 * @private
 */
PdfPageSize.a7 = new SizeF(210, 297);
/**
 * Specifies the size of `a8`.
 * @private
 */
PdfPageSize.a8 = new SizeF(148, 210);
/**
 * Specifies the size of `a9`.
 * @private
 */
PdfPageSize.a9 = new SizeF(105, 148);
/**
 * Specifies the size of `a10`.
 * @private
 */
PdfPageSize.a10 = new SizeF(74, 105);
/**
 * Specifies the size of `b0`.
 * @private
 */
PdfPageSize.b0 = new SizeF(2836, 4008);
/**
 * Specifies the size of `b1`.
 * @private
 */
PdfPageSize.b1 = new SizeF(2004, 2836);
/**
 * Specifies the size of `b2`.
 * @private
 */
PdfPageSize.b2 = new SizeF(1418, 2004);
/**
 * Specifies the size of `b3`.
 * @private
 */
PdfPageSize.b3 = new SizeF(1002, 1418);
/**
 * Specifies the size of `b4`.
 * @private
 */
PdfPageSize.b4 = new SizeF(709, 1002);
/**
 * Specifies the size of `b5`.
 * @private
 */
PdfPageSize.b5 = new SizeF(501, 709);
/**
 * Specifies the size of `archE`.
 * @private
 */
PdfPageSize.archE = new SizeF(2592, 3456);
/**
 * Specifies the size of `archD`.
 * @private
 */
PdfPageSize.archD = new SizeF(1728, 2592);
/**
 * Specifies the size of `archC`.
 * @private
 */
PdfPageSize.archC = new SizeF(1296, 1728);
/**
 * Specifies the size of `archB`.
 * @private
 */
PdfPageSize.archB = new SizeF(864, 1296);
/**
 * Specifies the size of `archA`.
 * @private
 */
PdfPageSize.archA = new SizeF(648, 864);
/**
 * Specifies the size of `flsa`.
 * @private
 */
PdfPageSize.flsa = new SizeF(612, 936);
/**
 * Specifies the size of `halfLetter`.
 * @private
 */
PdfPageSize.halfLetter = new SizeF(396, 612);
/**
 * Specifies the size of `letter11x17`.
 * @private
 */
PdfPageSize.letter11x17 = new SizeF(792, 1224);
/**
 * Specifies the size of `ledger`.
 * @private
 */
PdfPageSize.ledger = new SizeF(1224, 792);
