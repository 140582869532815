/**
 * Represents the `collection of pages in a section`.
 * @private
 */
export class PdfSectionPageCollection {
    // Constructors
    /**
     * Initializes a new instance of the `PdfSectionPageCollection` class.
     * @private
     */
    constructor(section) {
        //  Fields
        /**
         * @hidden
         * @private
         */
        this.pdfSection = null;
        if (section == null) {
            throw Error('ArgumentNullException("section")');
        }
        this.section = section;
    }
    // Properties
    /**
     * Gets the `PdfPage` at the specified index.
     * @private
     */
    get section() {
        return this.pdfSection;
    }
    set section(value) {
        this.pdfSection = value;
    }
    // Public Methods
    /**
     * `Determines` whether the specified page is within the collection.
     * @private
     */
    contains(page) {
        return this.section.contains(page);
    }
    /**
     * `Removes` the specified page from collection.
     * @private
     */
    remove(page) {
        this.section.remove(page);
    }
    /**
     * `Adds` a new page from collection.
     * @private
     */
    add() {
        return this.section.add();
    }
}
