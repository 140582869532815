/**
 * `PdfName` class is used to perform name (element names) related primitive operations.
 * @private
 */
export class PdfName {
    constructor(value) {
        /**
         * `Start symbol` of the name object.
         * @default /
         * @private
         */
        this.stringStartMark = '/';
        /**
         * `Value` of the element.
         * @private
         */
        this.internalValue = '';
        /**
         * Indicates if the object is currently in `saving state or not`.
         * @default false
         * @private
         */
        this.isSaving6 = false;
        /**
         * Internal variable to store the `position`.
         * @default -1
         * @private
         */
        this.position6 = -1;
        this.internalValue = this.normalizeValue(value);
    }
    //property
    /**
     * Gets or sets the `Status` of the specified object.
     * @private
     */
    get status() {
        return this.status6;
    }
    set status(value) {
        this.status6 = value;
    }
    /**
     * Gets or sets a value indicating whether this document `is saving` or not.
     * @private
     */
    get isSaving() {
        return this.isSaving6;
    }
    set isSaving(value) {
        this.isSaving6 = value;
    }
    /**
     * Gets or sets the `index` value of the specified object.
     * @private
     */
    get objectCollectionIndex() {
        return this.index6;
    }
    set objectCollectionIndex(value) {
        this.index6 = value;
    }
    /**
     * Gets or sets the `position` of the object.
     * @private
     */
    get position() {
        return this.position6;
    }
    set position(value) {
        this.position6 = value;
    }
    /**
     * Returns `cloned object`.
     * @private
     */
    get clonedObject() {
        return null;
    }
    /**
     * Gets or sets the `value` of the object.
     * @private
     */
    get value() {
        return this.internalValue;
    }
    set value(value) {
        // if (value !== this.value) {
        let val = value;
        if (value !== null && value.length > 0) {
            // val = (value.substring(0, 1) === this.stringStartMark) ? value.substring(1) : value;
            val = value;
            this.internalValue = this.normalizeValue(val);
        }
        else {
            this.internalValue = val;
        }
        // }
    }
    //public methods
    /**
     * `Saves` the name using the specified writer.
     * @private
     */
    save(writer) {
        // if (writer === null) {
        //     throw new Error('ArgumentNullException : writer');
        // }
        writer.write(this.toString());
    }
    /**
     * Gets `string` representation of the primitive.
     * @private
     */
    toString() {
        return (this.stringStartMark + this.escapeString(this.value));
    }
    /**
     * Creates a `copy of PdfName`.
     * @private
     */
    clone(crossTable) {
        let newName = new PdfName();
        newName.value = this.internalValue;
        return newName;
    }
    /**
     * Replace some characters with its `escape sequences`.
     * @private
     */
    escapeString(stringValue) {
        // if (str === null) {
        //     throw new Error('ArgumentNullException : str');
        // }
        // if (str === '') {
        //     return str;
        // }
        let result = '';
        let len = 0;
        for (let i = 0, len = stringValue.length; i < len; i++) {
            let ch = stringValue[i];
            let index = PdfName.delimiters.indexOf(ch);
            // switch (ch) {
            //     case '\r' :
            //         result = result + '\\r';
            //         break;
            //     case '\n' :
            //         result = result + '\n';
            //         break;
            //     case '(' :
            //     case ')' :
            //     case '\\' :
            //         //result.Append( '\\' ).Append( ch );
            //         result = result + ch;
            //         break;
            //     default :
            //         result = result + ch;
            //         break;
            // }
            result = result + ch;
        }
        return result;
    }
    //methiods
    /**
     * Replace a symbol with its code with the precedence of the `sharp sign`.
     * @private
     */
    normalizeValue(value, c) {
        // if (typeof c === undefined) {
        //     let str : string = value;
        //     for (let i : number = 0; i < PdfName.replacements.length; i++) {
        //         str = this.normalizeValue(str, c);
        //     }
        //     return str;
        // } else {
        let strFormat = '#{0:X}';
        //return value.replace(c.toString(),String.format(strFormat,c));
        return value;
        // }
    }
}
/**
 * PDF `special characters`.
 * @private
 */
PdfName.delimiters = '()<>[]{}/%}';
/**
 * The symbols that are not allowed in PDF names and `should be replaced`.
 * @private
 */
PdfName.replacements = [' ', '\t', '\n', '\r'];
