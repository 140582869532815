import { PdfStream } from './../primitives/pdf-stream';
import { PdfGraphics, GetResourceEventHandler } from './../graphics/pdf-graphics';
import { PdfPageLayerCollection } from './pdf-page-layer-collection';
import { DictionaryProperties } from './../input-output/pdf-dictionary-properties';
import { PdfColorSpace } from './../graphics/enum';
/**
 * The `PdfPageLayer` used to create layers in PDF document.
 * @private
 */
export class PdfPageLayer {
    constructor(page, streamClipPageTemplates) {
        // private bSaved : boolean;
        /**
         * Local Variable to store the `color space` of the document.
         * @private
         */
        this.pdfColorSpace = PdfColorSpace.Rgb;
        /**
         * Local Variable to set `visibility`.
         * @default true
         * @private
         */
        this.isVisible = true;
        /**
         * Indicates if `Sublayer` is present.
         * @default false
         * @private
         */
        this.sublayer = false;
        /**
         * Local variable to store `length` of the graphics.
         * @default 0
         * @private
         */
        this.contentLength = 0;
        /**
         * Instance for `PdfDictionaryProperties` Class.
         * @private
         */
        this.dictionaryProperties = new DictionaryProperties();
        if (page === null) {
            throw new Error('ArgumentNullException:page');
        }
        this.pdfPage = page;
        this.clipPageTemplates = true;
        if (typeof streamClipPageTemplates === 'undefined') {
            this.content = new PdfStream();
        }
        else if (streamClipPageTemplates instanceof PdfStream || streamClipPageTemplates === null) {
            if (streamClipPageTemplates === null) {
                throw new Error('ArgumentNullException:stream');
            }
            this.content = streamClipPageTemplates;
        }
        else {
            this.content = new PdfStream();
            this.clipPageTemplates = streamClipPageTemplates;
        }
    }
    // Properties
    /**
     * Get or set the `color space`.
     * @private
     */
    get colorSpace() {
        return this.pdfColorSpace;
    }
    set colorSpace(value) {
        this.pdfColorSpace = value;
    }
    /**
     * Gets parent `page` of the layer.
     * @private
     */
    get page() {
        return this.pdfPage;
    }
    /**
     * Gets and Sets the `id of the layer`.
     * @private
     */
    get layerId() {
        return this.layerid;
    }
    set layerId(value) {
        this.layerid = value;
    }
    /**
     * Gets or sets the `name` of the layer.
     * @private
     */
    get name() {
        return this.layerName;
    }
    set name(value) {
        this.layerName = value;
    }
    /**
     * Gets or sets the `visibility` of the layer.
     * @private
     */
    get visible() {
        return this.isVisible;
    }
    set visible(value) {
        this.isVisible = value;
    }
    /**
     * Gets `Graphics` context of the layer, used to draw various graphical content on layer.
     * @private
     */
    get graphics() {
        if ((this.pdfGraphics == null)) {
            this.initializeGraphics(this.page);
        }
        return this.pdfGraphics;
    }
    /**
     * Gets the collection of `PdfPageLayer`, this collection handle by the class 'PdfPageLayerCollection'.
     * @private
     */
    get layers() {
        if (this.layer == null) {
            this.layer = new PdfPageLayerCollection(this.page);
            this.layer.sublayer = true;
            return this.layer;
        }
        else {
            return this.layer;
        }
    }
    // Implementation
    /**
     * `Adds` a new PDF Page layer.
     * @private
     */
    add() {
        let layer = new PdfPageLayer(this.pdfPage);
        layer.name = '';
        return layer;
    }
    /**
     * Returns a value indicating the `sign` of a single-precision floating-point number.
     * @private
     */
    sign(number) {
        if (number === 0) {
            return 0;
        }
        else if (number > 0) {
            return 1;
        }
        else {
            return -1;
        }
    }
    /**
     * `Initializes Graphics context` of the layer.
     * @private
     */
    initializeGraphics(page) {
        let oPage = page;
        let gr = new GetResourceEventHandler(this.page);
        let cropBox = null;
        this.pdfGraphics = new PdfGraphics(page.size, gr, this.content);
        this.pdfGraphics.mediaBoxUpperRightBound = 0;
        if (oPage != null) {
            let sc = oPage.section.parent;
            if (sc != null) {
                this.pdfGraphics.colorSpace = sc.document.colorSpace;
                this.colorSpace = sc.document.colorSpace;
            }
        }
        // Transform coordinates to the left/top and activate margins.
        let isSame = (this.sign(page.origin.y) === this.sign(page.origin.x));
        // if (page != null) {
        if (page.origin.x >= 0 && page.origin.y >= 0 || !(isSame)) {
            this.pdfGraphics.initializeCoordinates();
        }
        else {
            // this.m_graphics.InitializeCoordinates(page);
        }
        let clipRect = oPage.section.getActualBounds(oPage, true);
        let margins = oPage.section.pageSettings.margins;
        if (this.clipPageTemplates) {
            if (page.origin.x >= 0 && page.origin.y >= 0) {
                this.pdfGraphics.clipTranslateMargins(clipRect);
            }
        }
        else {
            this.graphics.clipTranslateMargins(clipRect.x, clipRect.y, margins.left, margins.top, margins.right, margins.bottom);
        }
        this.pdfGraphics.setLayer(this);
        // this.bSaved = false;
    }
    // IPdfWrapper Members
    /**
     * Gets the wrapped `element`.
     * @private
     */
    get element() {
        return this.content;
    }
}
