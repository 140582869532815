import { PdfAction } from './action';
import { PdfString } from './../primitives/pdf-string';
import { PdfName } from './../primitives/pdf-name';
/**
 * `PdfUriAction` class for initialize the uri related internals.
 * @private
 */
export class PdfUriAction extends PdfAction {
    constructor(uri) {
        super();
        // Fields
        /**
         * Specifies the `uri` string.
         * @default ''.
         * @private
         */
        this.uniformResourceIdentifier = '';
    }
    // Properties
    /**
     * Gets and Sets the value of `Uri`.
     * @private
     */
    get uri() {
        return this.uniformResourceIdentifier;
    }
    set uri(value) {
        this.uniformResourceIdentifier = value;
        this.dictionary.items.setValue(this.dictionaryProperties.uri, new PdfString(this.uniformResourceIdentifier));
    }
    // Implementation
    /**
     * `Initialize` the internals.
     * @private
     */
    initialize() {
        super.initialize();
        this.dictionary.items.setValue(this.dictionaryProperties.s, new PdfName(this.dictionaryProperties.uri));
    }
}
