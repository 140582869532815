/**
 * ByteArray class
 * Used to keep information about image stream as byte array.
 * @private
 */
export class ByteArray {
    /**
     * Initialize the new instance for `byte-array` class
     * @hidden
     * @private
     */
    constructor(length) {
        /**
         * Current stream `position`.
         * @default 0
         * @private
         */
        this.mPosition = 0;
        this.buffer = new Uint8Array(length);
        this.dataView = new DataView(this.buffer.buffer);
    }
    /**
     * Gets and Sets a current `position` of byte array.
     * @hidden
     * @private
     */
    get position() {
        return this.mPosition;
    }
    set position(value) {
        this.mPosition = value;
    }
    /**
     * `Read` from current stream position.
     * @default 0
     * @hidden
     * @private
     */
    read(buffer, offset, count) {
        for (let index = offset; index < count; index++) {
            let position = this.position;
            buffer.buffer[index] = this.readByte(position);
            this.position++;
        }
    }
    /**
     * @hidden
     */
    getBuffer(index) {
        return this.buffer[index];
    }
    /**
     * @hidden
     */
    writeFromBase64String(base64) {
        let arr = this.encodedString(base64);
        this.buffer = arr;
    }
    /**
     * @hidden
     */
    encodedString(input) {
        let keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
        let chr1;
        let chr2;
        let chr3;
        let enc1;
        let enc2;
        let enc3;
        let enc4;
        let i = 0;
        let resultIndex = 0;
        let dataUrlPrefix = 'data:';
        input = input.replace(/[^A-Za-z0-9\+\/\=]/g, '');
        let totalLength = input.length * 3 / 4;
        if (input.charAt(input.length - 1) === keyStr.charAt(64)) {
            totalLength--;
        }
        let output = new Uint8Array(totalLength | 0);
        while (i < input.length) {
            enc1 = keyStr.indexOf(input.charAt(i++));
            enc2 = keyStr.indexOf(input.charAt(i++));
            enc3 = keyStr.indexOf(input.charAt(i++));
            enc4 = keyStr.indexOf(input.charAt(i++));
            chr1 = (enc1 << 2) | (enc2 >> 4);
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            chr3 = ((enc3 & 3) << 6) | enc4;
            output[resultIndex++] = chr1;
            output[resultIndex++] = chr2;
            output[resultIndex++] = chr3;
        }
        return output;
    }
    /**
     * @hidden
     */
    readByte(offset) {
        return (this.buffer[offset]);
    }
    /**
     * @hidden
     */
    get internalBuffer() {
        return this.buffer;
    }
    /**
     * @hidden
     */
    get count() {
        return this.buffer.byteLength;
    }
    /**
     * 'readNextTwoBytes' stream
     * @hidden
     * @private
     */
    readNextTwoBytes(stream) {
        let data = stream.readByte(this.position);
        this.position++;
        data <<= 8;
        data |= stream.readByte(this.position);
        this.position++;
        return data;
    }
}
