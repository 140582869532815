import { PdfColorSpace } from './../enum';
import { PdfColor } from './../pdf-color';
import { PdfBrush } from './pdf-brush';
import { PdfDictionary } from '../../primitives/pdf-dictionary';
import { DictionaryProperties } from './../../input-output/pdf-dictionary-properties';
import { PdfBoolean } from './../../primitives/pdf-boolean';
import { PdfArray } from './../../primitives/pdf-array';
import { PdfName } from '../../primitives/pdf-name';
import { PdfNumber } from '../../primitives/pdf-number';
import { PdfReferenceHolder } from '../../primitives/pdf-reference';
/**
 * `PdfGradientBrush` class provides objects used to fill the interiors of graphical shapes such as rectangles,
 * ellipses, pies, polygons, and paths.
 * @private
 */
export class PdfGradientBrush extends PdfBrush {
    //Constructor
    /**
     * Initializes a new instance of the `PdfGradientBrush` class.
     * @public
     */
    /* tslint:disable-next-line:max-line-length */
    constructor(shading) {
        super();
        // Fields
        /**
         * Local variable to store the background color.
         * @private
         */
        this.mbackground = new PdfColor(255, 255, 255);
        /**
         * Local variable to store the stroking color.
         * @private
         */
        this.mbStroking = false;
        /**
         * Local variable to store the function.
         * @private
         */
        this.mfunction = null;
        /**
         * Local variable to store the DictionaryProperties.
         * @private
         */
        this.dictionaryProperties = new DictionaryProperties();
        this.mpatternDictionary = new PdfDictionary();
        this.mpatternDictionary.items.setValue(this.dictionaryProperties.type, new PdfName(this.dictionaryProperties.pattern));
        this.mpatternDictionary.items.setValue(this.dictionaryProperties.patternType, new PdfNumber(2));
        this.shading = shading;
        this.colorSpace = PdfColorSpace.Rgb;
    }
    //Properties
    /**
     * Gets or sets the background color of the brush.
     * @public
     */
    get background() {
        return this.mbackground;
    }
    set background(value) {
        this.mbackground = value;
        let sh = this.shading;
        if (value.isEmpty) {
            sh.remove(this.dictionaryProperties.background);
        }
        else {
            sh.items.setValue(this.dictionaryProperties.background, value.toArray(this.colorSpace));
        }
    }
    /**
     * Gets or sets a value indicating whether use anti aliasing algorithm.
     * @public
     */
    get antiAlias() {
        let sh = this.shading;
        let aa = (sh.items.getValue(this.dictionaryProperties.antiAlias));
        return aa.value;
    }
    set antiAlias(value) {
        let sh = this.shading;
        let aa = (sh.items.getValue(this.dictionaryProperties.antiAlias));
        if ((aa == null && typeof aa === 'undefined')) {
            aa = new PdfBoolean(value);
            sh.items.setValue(this.dictionaryProperties.antiAlias, aa);
        }
        else {
            aa.value = value;
        }
    }
    /**
     * Gets or sets the function of the brush.
     * @protected
     */
    get function() {
        return this.mfunction;
    }
    set function(value) {
        this.mfunction = value;
        if (value != null && typeof value !== 'undefined') {
            this.shading.items.setValue(this.dictionaryProperties.function, new PdfReferenceHolder(this.mfunction));
        }
        else {
            this.shading.remove(this.dictionaryProperties.function);
        }
    }
    /**
     * Gets or sets the boundary box of the brush.
     * @protected
     */
    get bBox() {
        let sh = this.shading;
        let box = (sh.items.getValue(this.dictionaryProperties.bBox));
        return box;
    }
    set bBox(value) {
        let sh = this.shading;
        if (value == null && typeof value === 'undefined') {
            sh.remove(this.dictionaryProperties.bBox);
        }
        else {
            sh.items.setValue(this.dictionaryProperties.bBox, value);
        }
    }
    /**
     * Gets or sets the color space of the brush.
     * @public
     */
    get colorSpace() {
        return this.mcolorSpace;
    }
    set colorSpace(value) {
        let colorSpace = this.shading.items.getValue(this.dictionaryProperties.colorSpace);
        if ((value !== this.mcolorSpace) || (colorSpace == null)) {
            this.mcolorSpace = value;
            let csValue = this.colorSpaceToDeviceName(value);
            this.shading.items.setValue(this.dictionaryProperties.colorSpace, new PdfName(csValue));
        }
    }
    /**
     * Gets or sets a value indicating whether this PdfGradientBrush is stroking.
     * @public
     */
    get stroking() {
        return this.mbStroking;
    }
    set stroking(value) {
        this.mbStroking = value;
    }
    /**
     * Gets the pattern dictionary.
     * @protected
     */
    get patternDictionary() {
        if (this.mpatternDictionary == null) {
            this.mpatternDictionary = new PdfDictionary();
        }
        return this.mpatternDictionary;
    }
    /**
     * Gets or sets the shading dictionary.
     * @protected
     */
    get shading() {
        return this.mshading;
    }
    set shading(value) {
        if (value == null) {
            throw new Error('ArgumentNullException : Shading');
        }
        if (value !== this.mshading) {
            this.mshading = value;
            this.patternDictionary.items.setValue(this.dictionaryProperties.shading, new PdfReferenceHolder(this.mshading));
        }
    }
    /**
     * Gets or sets the transformation matrix.
     * @public
     */
    get matrix() {
        return this.mmatrix;
    }
    set matrix(value) {
        if (value == null) {
            throw new Error('ArgumentNullException : Matrix');
        }
        if (value !== this.mmatrix) {
            this.mmatrix = value.clone();
            let m = new PdfArray(this.mmatrix.matrix.elements);
            this.mpatternDictionary.items.setValue(this.dictionaryProperties.matrix, m);
        }
    }
    //Overrides
    /**
     * Monitors the changes of the brush and modify PDF state respectfully.
     * @param brush The brush.
     * @param streamWriter The stream writer.
     * @param getResources The get resources delegate.
     * @param saveChanges if set to true the changes should be saved anyway.
     * @param currentColorSpace The current color space.
     */
    /* tslint:disable-next-line:max-line-length */
    monitorChanges(brush, streamWriter, getResources, saveChanges, currentColorSpace) {
        let diff = false;
        if (brush instanceof PdfGradientBrush) {
            if ((this.colorSpace !== currentColorSpace)) {
                this.colorSpace = currentColorSpace;
                this.resetFunction();
            }
            //  Set the /Pattern colour space.
            streamWriter.setColorSpace('Pattern', this.mbStroking);
            //  Set the pattern for non-stroking operations.
            let resources = getResources.getResources();
            let name = resources.getName(this);
            streamWriter.setColourWithPattern(null, name, this.mbStroking);
            diff = true;
        }
        return diff;
    }
    /**
     * Resets the changes, which were made by the brush.
     * In other words resets the state to the initial one.
     * @param streamWriter The stream writer.
     */
    resetChanges(streamWriter) {
        //  Unable reset.
    }
    //Implementation
    /**
     * Converts colorspace enum to a PDF name.
     * @param colorSpace The color space enum value.
     */
    colorSpaceToDeviceName(colorSpace) {
        let result;
        switch (colorSpace) {
            case PdfColorSpace.Rgb:
                result = 'DeviceRGB';
                break;
        }
        return result;
    }
    /**
     * Resets the pattern dictionary.
     * @param dictionary A new pattern dictionary.
     */
    resetPatternDictionary(dictionary) {
        this.mpatternDictionary = dictionary;
    }
    /**
     * Clones the anti aliasing value.
     * @param brush The brush.
     */
    cloneAntiAliasingValue(brush) {
        if ((brush == null)) {
            throw new Error('ArgumentNullException : brush');
        }
        let sh = this.shading;
        let aa = (sh.items.getValue(this.dictionaryProperties.antiAlias));
        if ((aa != null)) {
            brush.shading.items.setValue(this.dictionaryProperties.antiAlias, new PdfBoolean(aa.value));
        }
    }
    /**
     * Clones the background value.
     * @param brush The brush.
     */
    cloneBackgroundValue(brush) {
        let background = this.background;
        if (!background.isEmpty) {
            brush.background = background;
        }
    }
    /* tslint:enable */
    // IPdfWrapper Members
    /**
     * Gets the `element`.
     * @private
     */
    get element() {
        return this.patternDictionary;
    }
}
