import { DictionaryProperties } from './../input-output/pdf-dictionary-properties';
import { PdfStream } from './../primitives/pdf-stream';
import { PdfArray } from './../primitives/pdf-array';
import { PdfNumber } from './../primitives/pdf-number';
import { PdfName } from './../primitives/pdf-name';
import { PdfDictionary } from './../primitives/pdf-dictionary';
import { PdfString } from './../primitives/pdf-string';
/**
 * `PdfReference` class is used to perform reference related primitive operations.
 * @private
 */
export class PdfReference {
    constructor(objNumber, genNumber) {
        /**
         * Holds the `index` number of the object.
         * @default -1
         * @private
         */
        this.index3 = -1;
        /**
         * Internal variable to store the `position`.
         * @default -1
         * @private
         */
        this.position3 = -1;
        /**
         * Holds the `object number`.
         * @default 0
         * @private
         */
        this.objNumber = 0;
        /**
         * Holds the `generation number` of the object.
         * @default 0
         * @private
         */
        this.genNumber = 0;
        if (typeof objNumber === 'number' && typeof genNumber === 'number') {
            this.objNumber = objNumber;
            this.genNumber = genNumber;
            // } else if (typeof objNum === 'string' && typeof genNum === 'string') {
        }
        else {
            this.objNumber = Number(objNumber);
            this.genNumber = Number(genNumber);
        }
    }
    //Property
    /**
     * Gets or sets the `Status` of the specified object.
     * @private
     */
    get status() {
        return this.status3;
    }
    set status(value) {
        this.status3 = value;
    }
    /**
     * Gets or sets a value indicating whether this document `is saving` or not.
     * @private
     */
    get isSaving() {
        return this.isSaving3;
    }
    set isSaving(value) {
        this.isSaving3 = value;
    }
    /**
     * Gets or sets the `index` value of the specified object.
     * @private
     */
    get objectCollectionIndex() {
        return this.index3;
    }
    set objectCollectionIndex(value) {
        this.index3 = value;
    }
    /**
     * Gets or sets the `position` of the object.
     * @private
     */
    get position() {
        return this.position3;
    }
    set position(value) {
        this.position3 = value;
    }
    /**
     * Returns `cloned object`.
     * @private
     */
    get clonedObject() {
        let returnObject3 = null;
        return returnObject3;
    }
    //IPdfPrimitives methods
    /**
     * `Saves` the object.
     * @private
     */
    save(writer) {
        writer.write(this.toString());
    }
    /**
     * Returns a `string` representing the object.
     * @private
     */
    toString() {
        return this.objNumber.toString() + ' ' + this.genNumber.toString() + ' R';
    }
    /**
     * Creates a `deep copy` of the IPdfPrimitive object.
     * @private
     */
    clone(crossTable) {
        return null;
    }
}
/**
 * `PdfReferenceHolder` class is used to perform reference holder related primitive operations.
 * @private
 */
export class PdfReferenceHolder {
    constructor(obj1, obj2) {
        /**
         * Holds the `index` number of the object.
         * @default -1
         * @private
         */
        this.index4 = -1;
        /**
         * Internal variable to store the `position`.
         * @default -1
         * @private
         */
        this.position4 = -1;
        /**
         * The `index` of the object within the object collection.
         * @default -1
         * @private
         */
        this.objectIndex = -1;
        /**
         * @hidden
         * @private
         */
        this.dictionaryProperties = new DictionaryProperties();
        // if (typeof obj2 === 'undefined') {
        this.initialize(obj1);
        // }
        // else {
        //     if (obj2 === null) {
        //         throw new Error('ArgumentNullException : crossTable');
        //     }
        //     if (obj1 === null) {
        //         throw new Error('ArgumentNullException : reference');
        //     }
        //     this.crossTable = obj2;
        //     let tempObj1 : PdfReference = <PdfReference>obj1;
        //     this.reference = tempObj1;
        // }
    }
    //Properties
    /**
     * Gets or sets the `Status` of the specified object.
     * @private
     */
    get status() {
        return this.status4;
    }
    set status(value) {
        this.status4 = value;
    }
    /**
     * Gets or sets a value indicating whether this document `is saving` or not.
     * @private
     */
    get isSaving() {
        return this.isSaving4;
    }
    set isSaving(value) {
        this.isSaving4 = value;
    }
    /**
     * Gets or sets the `index` value of the specified object.
     * @private
     */
    get objectCollectionIndex() {
        return this.index4;
    }
    set objectCollectionIndex(value) {
        this.index4 = value;
    }
    /**
     * Gets or sets the `position` of the object.
     * @private
     */
    get position() {
        return this.position4;
    }
    set position(value) {
        this.position4 = value;
    }
    /**
     * Returns `cloned object`.
     * @private
     */
    get clonedObject() {
        return null;
    }
    /**
     * Gets the `object` the reference is of.
     * @private
     */
    get object() {
        // if ((this.reference != null) || (this.object == null)) {
        //     this.object = this.GetterObject();
        // }
        return this.primitiveObject;
    }
    /**
     * Gets the `reference`.
     * @private
     */
    get reference() {
        return this.pdfReference;
    }
    /**
     * Gets the `index` of the object.
     * @private
     */
    get index() {
        // let items : PdfMainObjectCollection = this.crossTable.PdfObjects;
        // this.objectIndex = items.GetObjectIndex(this.reference);
        // if (this.objectIndex < 0) {
        //     let obj : IPdfPrimitive = this.crossTable.GetObject(this.reference);
        //     this.objectIndex = items.Count - 1;
        // }
        return this.objectIndex;
    }
    /**
     * Gets the `element`.
     * @private
     */
    get element() {
        return this.primitiveObject;
    }
    initialize(obj1) {
        if (obj1 instanceof PdfArray
            || obj1 instanceof PdfDictionary
            || obj1 instanceof PdfName
            || obj1 instanceof PdfNumber
            || obj1 instanceof PdfStream
            || obj1 instanceof PdfReference
            || obj1 instanceof PdfString) {
            this.primitiveObject = obj1;
        }
        else {
            let tempObj = obj1;
            this.initialize(tempObj.element);
        }
    }
    /**
     * `Writes` a reference into a PDF document.
     * @private
     */
    save(writer) {
        // if (writer == null) {
        //     throw new Error('ArgumentNullException : writer');
        // }
        let position = writer.position;
        let cTable = writer.document.crossTable;
        // if (cTable.Document instanceof PdfDocument) {
        this.object.isSaving = true;
        // }
        let reference = null;
        // if (writer.Document.FileStructure.IncrementalUpdate === true && writer.Document.isStreamCopied === true) {
        //     if (this.reference === null) {
        //         reference = cTable.GetReference(this.Object);
        //     } else {
        //         reference = this.reference;
        //     }
        // } else {
        //     reference = cTable.GetReference(this.Object);
        // }
        // if (!(writer.Document.FileStructure.IncrementalUpdate === true && writer.Document.isStreamCopied === true)) {
        reference = cTable.getReference(this.object);
        // }
        // if (writer.Position !== position) {
        //     writer.Position = position;
        // }
        reference.save(writer);
    }
    /**
     * Creates a `copy of PdfReferenceHolder`.
     * @private
     */
    clone(crossTable) {
        let refHolder = null;
        let temp = null;
        let refNum = '';
        let reference = null;
        // Restricts addition of same object multiple time.
        /* if (this.Reference != null && this.crossTable != null && this.crossTable.PageCorrespondance.containsKey(this.Reference)) {
            refHolder = new PdfReferenceHolder(this.crossTable.PageCorrespondance.getValue(this.Reference) as PdfReference, crossTable);
            return refHolder;
        }
        if (Object instanceof PdfNumber) {
            return new PdfNumber((Object as PdfNumber).IntValue);
        }
        */
        // if (Object instanceof PdfDictionary) {
        //     // Meaning the referenced page is not available for import.
        //     let type : PdfName = new PdfName(this.dictionaryProperties.type);
        //     let dict : PdfDictionary = Object as PdfDictionary;
        //     if (dict.ContainsKey(type)) {
        //         let pageName : PdfName = dict.Items.getValue(type.Value) as PdfName;
        //         if (pageName !== null) {
        //             if (pageName.Value === 'Page') {
        //                 return new PdfNull();
        //             }
        //         }
        //     }
        // }
        /* if (Object instanceof PdfName) {
            return new PdfName ((Object as PdfName ).Value);
        }
        */
        // Resolves circular references.
        // if (crossTable.PrevReference !== null && (crossTable.PrevReference.indexOf(this.Reference) !== -1)) {
        //     let obj : IPdfPrimitive = this.crossTable.GetObject(this.Reference).ClonedObject;
        //     if (obj !== null) {
        //         reference = crossTable.GetReference(obj);
        //         return new PdfReferenceHolder(reference, crossTable);
        //     } else {
        //         return new PdfNull();
        //     }
        // }
        /*if (this.Reference !== null) {
            crossTable.PrevReference.push(this.Reference);
        }
        reference = crossTable.GetReference(temp);
        refHolder = new PdfReferenceHolder(reference, crossTable);
        return refHolder;
        */
        return null;
    }
}
