/**
 * PdfPageSettings.ts class for EJ2-PDF
 */
import { SizeF, PointF } from './../drawing/pdf-drawing';
import { PdfPageSize } from './pdf-page-size';
import { PdfPageRotateAngle, PdfPageOrientation } from './enum';
import { PdfMargins } from './../graphics/pdf-margins';
/**
 * The class provides various `setting` related with PDF pages.
 */
export class PdfPageSettings {
    constructor(margins) {
        //Fields
        /**
         * The page `margins`.
         * @private
         */
        this.pageMargins = new PdfMargins();
        /**
         * The page `size`.
         * @default a4
         * @private
         */
        this.pageSize = PdfPageSize.a4;
        /**
         * The page `rotation angle`.
         * @default PdfPageRotateAngle.RotateAngle0
         * @private
         */
        this.rotateAngle = PdfPageRotateAngle.RotateAngle0;
        /**
         * The page `orientation`.
         * @default PdfPageOrientation.Portrait
         * @private
         */
        this.pageOrientation = PdfPageOrientation.Portrait;
        /**
         * The page `origin`.
         * @default 0,0
         * @private
         */
        this.pageOrigin = new PointF(0, 0);
        /**
         * Checks the Whether the `rotation` is applied or not.
         * @default false
         * @private
         */
        this.isRotation = false;
        if (typeof margins === 'number') {
            this.pageMargins.setMargins(margins);
        }
    }
    //Properties
    /**
     * Gets or sets the `size` of the page.
     * @private
     */
    get size() {
        return this.pageSize;
    }
    set size(value) {
        this.setSize(value);
    }
    /**
     * Gets or sets the page `orientation`.
     * @private
     */
    get orientation() {
        return this.pageOrientation;
    }
    set orientation(orientation) {
        if (this.pageOrientation !== orientation) {
            this.pageOrientation = orientation;
            this.updateSize(orientation);
        }
    }
    /**
     * Gets or sets the `margins` of the page.
     * @private
     */
    get margins() {
        return this.pageMargins;
    }
    set margins(value) {
        this.pageMargins = value;
    }
    /**
     * Gets or sets the `width` of the page.
     * @private
     */
    get width() {
        return this.pageSize.width;
    }
    set width(value) {
        this.pageSize.width = value;
    }
    /**
     * Gets or sets the `height` of the page.
     * @private
     */
    get height() {
        return this.pageSize.height;
    }
    set height(value) {
        this.pageSize.height = value;
    }
    /**
     * Gets or sets the `origin` of the page.
     * @private
     */
    get origin() {
        return this.pageOrigin;
    }
    set origin(value) {
        this.pageOrigin = value;
    }
    /**
     * Gets or sets the number of degrees by which the page should be `rotated` clockwise when displayed or printed.
     * @private
     */
    get rotate() {
        return this.rotateAngle;
    }
    set rotate(value) {
        this.rotateAngle = value;
        this.isRotation = true;
    }
    //Methods
    /**
     * `Update page size` depending on orientation.
     * @private
     */
    updateSize(orientation) {
        let min = Math.min(this.pageSize.width, this.pageSize.height);
        let max = Math.max(this.pageSize.width, this.pageSize.height);
        switch (orientation) {
            case PdfPageOrientation.Portrait:
                this.pageSize = new SizeF(min, max);
                break;
            case PdfPageOrientation.Landscape:
                this.pageSize = new SizeF(max, min);
                break;
        }
    }
    /**
     * Creates a `clone` of the object.
     * @private
     */
    clone() {
        let settings = this;
        settings.pageMargins = this.pageMargins.clone();
        // if (GetTransition() != null)
        // {
        //     settings.Transition = (PdfPageTransition)Transition.clone();
        // }
        return settings;
    }
    /**
     * Returns `size`, shrinked by the margins.
     * @private
     */
    getActualSize() {
        let width = this.width - (this.margins.left + this.margins.right);
        let height = this.height - (this.margins.top + this.margins.bottom);
        let size = new SizeF(width, height);
        return size;
    }
    /**
     * Sets `size` to the page aaccording to the orientation.
     * @private
     */
    setSize(size) {
        let min = Math.min(size.width, size.height);
        let max = Math.max(size.width, size.height);
        if (this.orientation === PdfPageOrientation.Portrait) {
            this.pageSize = new SizeF(min, max);
        }
        else {
            this.pageSize = new SizeF(max, min);
        }
    }
}
