import { PdfActionLinkAnnotation } from './action-link-annotation';
import { PdfName } from './../primitives/pdf-name';
import { PdfUriAction } from './../actions/uri-action';
/**
 * `PdfUriAnnotation` class represents the Uri annotation.
 * @private
 */
export class PdfUriAnnotation extends PdfActionLinkAnnotation {
    /**
     * Get `action` of the annotation.
     * @private
     */
    get uriAction() {
        if (typeof this.pdfUriAction === 'undefined') {
            this.pdfUriAction = new PdfUriAction();
        }
        return this.pdfUriAction;
    }
    // Properties
    /**
     * Gets or sets the `Uri` address.
     * @private
     */
    get uri() {
        return this.uriAction.uri;
    }
    set uri(value) {
        if (this.uriAction.uri !== value) {
            this.uriAction.uri = value;
        }
    }
    /**
     * Gets or sets the `action`.
     * @private
     */
    get action() {
        return this.getSetAction();
    }
    set action(value) {
        this.getSetAction(value);
        this.uriAction.next = value;
    }
    constructor(rectangle, uri) {
        super(rectangle);
        if (typeof uri !== 'undefined') {
            this.uri = uri;
        }
    }
    // Implementation
    /**
     * `Initializes` annotation object.
     * @private
     */
    initialize() {
        super.initialize();
        this.dictionary.items.setValue(this.dictionaryProperties.subtype, new PdfName(this.dictionaryProperties.link));
        let tempPrimitive = this.uriAction.element;
        this.dictionary.items.setValue(this.dictionaryProperties.a, this.uriAction.element);
    }
}
