import { PdfDictionary } from './../primitives/pdf-dictionary';
import { PdfReferenceHolder } from './../primitives/pdf-reference';
import { PdfName } from './../primitives/pdf-name';
import { DictionaryProperties } from './../input-output/pdf-dictionary-properties';
/**
 * `PdfAction` class represents base class for all action types.
 * @private
 */
export class PdfAction {
    // Constructors
    /**
     * Initialize instance for `Action` class.
     * @private
     */
    constructor() {
        /**
         * Specifies the Next `action` to perform.
         * @private
         */
        this.action = null;
        /**
         * Specifies the Internal variable to store `dictionary properties`.
         * @private
         */
        this.dictionaryProperties = new DictionaryProperties();
        // super(); -> Object()
        this.initialize();
    }
    // Properties
    /**
     * Gets and Sets the `Next` action to perform.
     * @private
     */
    get next() {
        return this.action;
    }
    set next(value) {
        // if (this.action !== value) {
        this.action = value;
        this.dictionary.items.setValue(this.dictionaryProperties.next, new PdfReferenceHolder(this.action));
        // }
    }
    /**
     * Gets and Sets the instance of PdfDictionary class for `Dictionary`.
     * @private
     */
    get dictionary() {
        if (typeof this.pdfDictionary === 'undefined') {
            this.pdfDictionary = new PdfDictionary();
        }
        return this.pdfDictionary;
    }
    // Implementation
    /**
     * `Initialize` the action type.
     * @private
     */
    initialize() {
        this.dictionary.items.setValue(this.dictionaryProperties.type, new PdfName(this.dictionaryProperties.action));
    }
    // IPdfWrapper Members
    /**
     * Gets the `Element` as IPdfPrimitive class.
     * @private
     */
    get element() {
        return this.dictionary;
    }
}
