/**
 * PdfAutomaticField.ts class for EJ2-PDF
 */
import { PdfAutomaticField } from './automatic-field';
import { TemporaryDictionary } from './../../collections/object-object-pair/dictionary';
import { PdfTemplateValuePair } from './pdf-template-value-pair';
import { SizeF, PointF } from './../../drawing/pdf-drawing';
import { PdfTemplate } from './../../graphics/figures/pdf-template';
/**
 * Represents automatic field which has the same value within the `PdfGraphics`.
 */
export class PdfMultipleValueField extends PdfAutomaticField {
    constructor() {
        super();
        //  Fields
        /**
         * Stores the instance of dictionary values of `graphics and template value pair`.
         * @private
         */
        this.list = new TemporaryDictionary();
    }
    // Implementation
    /* tslint:disable */
    performDraw(graphics, location, scalingX, scalingY) {
        super.performDrawHelper(graphics, location, scalingX, scalingY);
        let value = this.getValue(graphics);
        let template = new PdfTemplate(this.getSize());
        this.list.setValue(graphics, new PdfTemplateValuePair(template, value));
        let g = template.graphics;
        let size = this.getSize();
        template.graphics.drawString(value, this.getFont(), this.pen, this.getBrush(), 0, 0, size.width, size.height, this.stringFormat);
        let drawLocation = new PointF(location.x + this.location.x, location.y + this.location.y);
        graphics.drawPdfTemplate(template, drawLocation, new SizeF(template.width * scalingX, template.height * scalingY));
    }
}
