import { PdfFont } from './pdf-font';
import { PdfSubSuperScript } from './enum';
import { PdfArray } from './../../primitives/pdf-array';
/**
 * `Metrics` of the font.
 * @private
 */
export class PdfFontMetrics {
    constructor() {
        /**
         * `Line gap`.
         * @private
         */
        this.lineGap = 0;
    }
    //  Public methods
    /**
     * Returns `ascent` taking into consideration font`s size.
     * @private
     */
    getAscent(format) {
        let returnValue = this.ascent * PdfFont.charSizeMultiplier * this.getSize(format);
        return returnValue;
    }
    /**
     * Returns `descent` taking into consideration font`s size.
     * @private
     */
    getDescent(format) {
        let returnValue = this.descent * PdfFont.charSizeMultiplier * this.getSize(format);
        return returnValue;
    }
    /**
     * Returns `Line gap` taking into consideration font`s size.
     * @private
     */
    getLineGap(format) {
        let returnValue = this.lineGap * PdfFont.charSizeMultiplier * this.getSize(format);
        return returnValue;
    }
    /**
     * Returns `height` taking into consideration font`s size.
     * @private
     */
    getHeight(format) {
        let height;
        let clearTypeFonts = ['cambria', 'candara', 'constantia', 'corbel', 'cariadings'];
        let clearTypeFontCollection = [];
        for (let index = 0; index < clearTypeFonts.length; index++) {
            let font = clearTypeFonts[index];
            clearTypeFontCollection.push(font);
        }
        if (this.getDescent(format) < 0) {
            // if ((clearTypeFontCollection.indexOf(this.name.toLowerCase()) !== -1) && !this.isUnicodeFont) {
            //     height = (this.GetAscent(format) - this.GetDescent(format) - this.GetLineGap(format));
            // } else {
            height = (this.getAscent(format) - this.getDescent(format) + this.getLineGap(format));
            // }
        }
        else {
            height = (this.getAscent(format) + this.getDescent(format) + this.getLineGap(format));
        }
        return height;
    }
    /**
     * Calculates `size` of the font depending on the subscript/superscript value.
     * @private
     */
    getSize(format) {
        let size = this.size;
        if (format != null) {
            switch (format.subSuperScript) {
                case PdfSubSuperScript.SubScript:
                    size /= this.subScriptSizeFactor;
                    break;
                case PdfSubSuperScript.SuperScript:
                    size /= this.superscriptSizeFactor;
                    break;
            }
        }
        return size;
    }
    /**
     * `Clones` the metrics.
     * @private
     */
    clone() {
        let metrics = this;
        metrics.widthTable = WidthTable.clone();
        return metrics;
    }
    //  Properies
    /**
     * Gets or sets the `width table`.
     * @private
     */
    get widthTable() {
        return this.internalWidthTable;
    }
    set widthTable(value) {
        this.internalWidthTable = value;
    }
}
export class WidthTable {
    /**
     * Static `clones` this instance of the WidthTable class.
     * @private
     */
    static clone() {
        return null;
    }
}
export class StandardWidthTable extends WidthTable {
    //Properties
    /**
     * Gets the `32 bit number` at the specified index.
     * @private
     */
    items(index) {
        if (index < 0 || index >= this.widths.length) {
            throw new Error('ArgumentOutOfRangeException:index, The character is not supported by the font.');
        }
        let result = this.widths[index];
        return result;
    }
    /**
     * Gets the `length` of the internal array.
     * @private
     */
    get length() {
        return this.widths.length;
    }
    // Constructors
    /**
     * Initializes a new instance of the `StandardWidthTable` class.
     * @private
     */
    constructor(widths) {
        super();
        if (widths == null) {
            throw new Error('ArgumentNullException:widths');
        }
        this.widths = widths;
    }
    //Overrides
    /**
     * `Clones` this instance of the WidthTable class.
     * @private
     */
    clone() {
        let swt = this;
        swt.widths = this.widths;
        return swt;
    }
    /**
     * Converts width table to a `PDF array`.
     * @private
     */
    toArray() {
        let arr = new PdfArray(this.widths);
        return arr;
    }
}
