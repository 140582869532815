import { PdfGridCell } from './pdf-grid-cell';
import { PdfStringFormat } from './../../graphics/fonts/pdf-string-format';
/**
 * `PdfGridColumn` class represents the schema of a column in a 'PdfGrid'.
 */
export class PdfGridColumn {
    //Constructors
    /**
     * Initializes a new instance of the `PdfGridColumn` class with the parent grid.
     * @private
     */
    constructor(grid) {
        /**
         * The `width` of the column.
         * @default 0
         * @private
         */
        this.columnWidth = 0;
        this.grid = grid;
    }
    /**
     * Gets or sets the `width` of the 'PdfGridColumn'.
     * @private
     */
    get width() {
        return this.columnWidth;
    }
    set width(value) {
        this.isCustomWidth = true;
        this.columnWidth = value;
    }
    /**
     * Gets or sets the information about the text `formatting`.
     * @private
     */
    get format() {
        if (this.stringFormat == null) {
            this.stringFormat = new PdfStringFormat(); //GetDefaultFormat();
        }
        return this.stringFormat;
    }
    set format(value) {
        this.stringFormat = value;
    }
}
/**
 * `PdfGridColumnCollection` class provides access to an ordered,
 * strongly typed collection of 'PdfGridColumn' objects.
 * @private
 */
export class PdfGridColumnCollection {
    //properties
    //Constructors
    /**
     * Initializes a new instance of the `PdfGridColumnCollection` class with the parent grid.
     * @private
     */
    constructor(grid) {
        /**
         * @hidden
         * @private
         */
        this.internalColumns = [];
        /**
         * @hidden
         * @private
         */
        this.columnWidth = 0;
        this.grid = grid;
        this.internalColumns = [];
    }
    //Iplementation
    /**
     * `Add` a new column to the 'PdfGrid'.
     * @private
     */
    add(count) {
        // public add(column : PdfGridColumn) : void
        // public add(arg : number|PdfGridColumn) : void {
        // if (typeof arg === 'number') {
        for (let i = 0; i < count; i++) {
            this.internalColumns.push(new PdfGridColumn(this.grid));
            for (let index = 0; index < this.grid.rows.count; index++) {
                let row = this.grid.rows.getRow(index);
                let cell = new PdfGridCell();
                cell.value = '';
                row.cells.add(cell);
            }
        }
        // } else {
        //     let column : PdfGridColumn = new PdfGridColumn(this.grid);
        //     this.columns.push(column);
        //     return column;
        // }
    }
    /**
     * Gets the `number of columns` in the 'PdfGrid'.[Read-Only].
     * @private
     */
    get count() {
        return this.internalColumns.length;
    }
    /**
     * Gets the `widths`.
     * @private
     */
    get width() {
        if (this.columnWidth === 0) {
            this.columnWidth = this.measureColumnsWidth();
        }
        if (this.grid.initialWidth !== 0 && this.columnWidth !== this.grid.initialWidth && !this.grid.style.allowHorizontalOverflow) {
            this.columnWidth = this.grid.initialWidth;
            this.grid.isPageWidth = true;
        }
        return this.columnWidth;
    }
    /**
     * Gets the `array of PdfGridColumn`.[Read-Only]
     * @private
     */
    get columns() {
        return this.internalColumns;
    }
    /**
     * Gets the `PdfGridColumn` from the specified index.[Read-Only]
     * @private
     */
    getColumn(index) {
        if (index >= 0 && index <= this.columns.length) {
            return this.columns[index];
        }
        else {
            throw Error('can not get the column from the index: ' + index);
        }
    }
    //Implementation
    /**
     * `Calculates the column widths`.
     * @private
     */
    measureColumnsWidth() {
        let totalWidth = 0;
        this.grid.measureColumnsWidth();
        for (let i = 0, count = this.internalColumns.length; i < count; i++) {
            totalWidth += this.internalColumns[i].width;
        }
        return totalWidth;
    }
    /**
     * Gets the `widths of the columns`.
     * @private
     */
    getDefaultWidths(totalWidth) {
        let widths = [];
        let summ = 0.0;
        let subFactor = this.count;
        for (let i = 0; i < this.count; i++) {
            if (this.grid.isPageWidth && totalWidth >= 0 && !this.internalColumns[i].isCustomWidth) {
                this.internalColumns[i].width = 0;
            }
            else {
                widths[i] = this.internalColumns[i].width;
                if (this.internalColumns[i].width > 0 && this.internalColumns[i].isCustomWidth) {
                    totalWidth -= this.internalColumns[i].width;
                    subFactor--;
                }
                else {
                    widths[i] = 0;
                }
            }
        }
        for (let i = 0; i < this.count; i++) {
            let width = totalWidth / subFactor;
            if (widths[i] <= 0) {
                widths[i] = width;
            }
        }
        return widths;
    }
}
