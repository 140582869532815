/**
 * Used to `write a string` into output file.
 * @private
 */
export class PdfWriter {
    /**
     * Initialize an instance of `PdfWriter` class.
     * @private
     */
    constructor(stream) {
        this.streamWriter = stream;
    }
    //properties
    /**
     * Gets and Sets the `document`.
     * @private
     */
    get document() {
        return this.pdfDocument;
    }
    set document(value) {
        this.pdfDocument = value;
    }
    /**
     * Gets the `position`.
     * @private
     */
    get position() {
        return this.streamWriter.buffer.size;
    }
    /**
     * Gets  the `length` of the stream'.
     * @private
     */
    get length() {
        return this.streamWriter.buffer.size;
    }
    /**
     * Gets the `stream`.
     * @private
     */
    get stream() {
        let result = this.streamWriter;
        return result;
    }
    //public Methods
    /**
     * `Writes the specified data`.
     * @private
     */
    write(overload) {
        let data = [];
        let tempOverload = overload;
        this.streamWriter.write(tempOverload);
    }
}
