import { PdfLayoutElement } from './../layout-element';
import { ShapeLayouter } from './shape-layouter';
/**
 * Base class for the main shapes.
 * @private
 */
export class PdfShapeElement extends PdfLayoutElement {
    // methods
    /**
     * Gets the bounds.
     * @private
     */
    getBounds() {
        let rect = this.getBoundsInternal();
        return rect;
    }
    /**
     * `drawGraphicsHelper` the graphics.
     * @public
     */
    drawGraphicsHelper(graphics, location) {
        if ((graphics == null)) {
            throw new Error('ArgumentNullException :graphics');
        }
        this.drawShapeHelper(graphics, location.x, location.y);
    }
    /**
     * `drawShapeHelper` the graphics.
     * @private
     */
    drawShapeHelper(graphics, x, y) {
        let bNeedSave = (x !== 0.5 || y !== 0.5);
        let gState = null;
        // Translate co-ordinates.
        if (bNeedSave) {
            // Save state.
            gState = graphics.save();
            graphics.translateTransform(x, y);
        }
        this.drawInternal(graphics);
        if (bNeedSave) {
            // Restore state.
            graphics.restore(gState);
        }
    }
    /**
     * Layouts the element.
     * @private
     */
    layout(param) {
        let layouter = new ShapeLayouter(this);
        let result = layouter.layout(param);
        return result;
    }
}
