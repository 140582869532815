import { PdfColor } from './../graphics/pdf-color';
import { RectangleF, PointF } from './../drawing/pdf-drawing';
import { PdfDictionary, SaveAnnotationEventHandler } from './../primitives/pdf-dictionary';
import { PdfArray } from './../primitives/pdf-array';
import { PdfNumber } from './../primitives/pdf-number';
import { PdfReferenceHolder } from './../primitives/pdf-reference';
import { PdfString } from './../primitives/pdf-string';
import { DictionaryProperties } from './../input-output/pdf-dictionary-properties';
import { PdfName } from './../primitives/pdf-name';
import { PdfSolidBrush } from './../graphics/brushes/pdf-solid-brush';
import { PdfStandardFont } from './../graphics/fonts/pdf-standard-font';
import { PdfFontFamily } from './../graphics/fonts/enum';
import { PdfStringFormat } from './../graphics/fonts/pdf-string-format';
import { PdfTextAlignment } from './../graphics/enum';
/**
 * `PdfAnnotation` class represents the base class for annotation objects.
 * @private
 */
export class PdfAnnotation {
    constructor(arg1) {
        // Fields
        /**
         * Specifies the Internal variable to store fields of `PdfDictionaryProperties`.
         * @private
         */
        this.dictionaryProperties = new DictionaryProperties();
        /**
         * `Color` of the annotation
         * @private
         */
        this.pdfColor = new PdfColor(255, 255, 255);
        /**
         * `Bounds` of the annotation.
         * @private
         */
        this.rectangle = new RectangleF(0, 0, 0, 0);
        /**
         * Parent `page` of the annotation.
         * @private
         */
        this.pdfPage = null;
        /**
         * `Brush of the text` of the annotation.
         * @default new PdfSolidBrush(new PdfColor(0, 0, 0))
         * @private
         */
        this.textBrush = new PdfSolidBrush(new PdfColor(0, 0, 0));
        /**
         * `Font of the text` of the annotation.
         * @default new PdfStandardFont(PdfFontFamily.TimesRoman, 10)
         * @private
         */
        this.textFont = new PdfStandardFont(PdfFontFamily.TimesRoman, 10);
        /**
         * `StringFormat of the text` of the annotation.
         * @default new PdfStringFormat(PdfTextAlignment.Left)
         * @private
         */
        this.format = new PdfStringFormat(PdfTextAlignment.Left);
        /**
         * `Text` of the annotation.
         * @private
         */
        this.content = '';
        /**
         * Internal variable to store `dictionary`.
         * @private
         */
        this.pdfDictionary = new PdfDictionary();
        /**
         * To specifying the `Inner color` with which to fill the annotation
         * @private
         */
        this.internalColor = new PdfColor();
        /**
         * `opacity or darkness` of the annotation.
         * @private
         * @default 1.0
         */
        this.darkness = 1.0;
        if (typeof arg1 === 'undefined') {
            this.initialize();
        }
        else {
            this.initialize();
            this.bounds = arg1;
        }
    }
    // Properties
    /**
     * `Color` of the annotation
     * @private
     */
    get color() {
        return this.pdfColor;
    }
    set color(value) {
        this.pdfColor = value;
    }
    /**
     * To specifying the `Inner color` with which to fill the annotation
     * @private
     */
    get innerColor() {
        return this.internalColor;
    }
    set innerColor(value) {
        this.internalColor = value;
    }
    /**
     * `bounds` of the annotation.
     * @private
     */
    get bounds() {
        return this.rectangle;
    }
    set bounds(value) {
        this.rectangle = value;
    }
    /**
     * Parent `page` of the annotation.
     * @private
     */
    get page() {
        return this.pdfPage;
    }
    /**
     * To specifying the `Font of the text` in the annotation.
     * @private
     */
    get font() {
        return this.textFont;
    }
    set font(value) {
        this.textFont = value;
    }
    /**
     * To specifying the `StringFormat of the text` in the annotation.
     * @private
     */
    get stringFormat() {
        return this.format;
    }
    set stringFormat(value) {
        this.format = value;
    }
    /**
     * To specifying the `Brush of the text` in the annotation.
     * @private
     */
    get brush() {
        return this.textBrush;
    }
    set brush(value) {
        this.textBrush = value;
    }
    /**
     * `Text` of the annotation.
     * @private
     */
    get text() {
        return this.content;
    }
    set text(value) {
        this.content = value;
        this.dictionary.items.setValue(this.dictionaryProperties.contents, new PdfString(this.content));
    }
    /**
     * Internal variable to store `dictionary`.
     * @hidden
     */
    get dictionary() {
        return this.pdfDictionary;
    }
    set dictionary(value) {
        this.pdfDictionary = value;
    }
    // Implementation
    /**
     * `Initialize` the annotation event handler and specifies the type of the annotation.
     * @private
     */
    initialize() {
        this.pdfDictionary.annotationBeginSave = new SaveAnnotationEventHandler(this);
        this.pdfDictionary.items.setValue(this.dictionaryProperties.type, new PdfName(this.dictionaryProperties.annot));
    }
    /**
     * Sets related `page` of the annotation.
     * @private
     */
    setPage(page) {
        this.pdfPage = page;
        this.pdfDictionary.items.setValue(this.dictionaryProperties.p, new PdfReferenceHolder(this.pdfPage));
    }
    /**
     * Handles the `BeginSave` event of the Dictionary.
     * @private
     */
    beginSave() {
        this.save();
    }
    /**
     * `Saves` an annotation.
     * @private
     */
    /* tslint:disable */
    save() {
        let nativeRectangle = new RectangleF(this.rectangle.x, this.rectangle.y, this.rectangle.width, this.rectangle.height);
        let section = this.pdfPage.section;
        let initialHeight = nativeRectangle.height;
        let tempLoacation = section.pointToNativePdf(this.page, new PointF(nativeRectangle.x, nativeRectangle.y));
        nativeRectangle.x = tempLoacation.x;
        nativeRectangle.width = tempLoacation.x + nativeRectangle.width;
        nativeRectangle.y = (tempLoacation.y - this.page.document.pageSettings.margins.top);
        nativeRectangle.height = nativeRectangle.y - initialHeight;
        this.pdfDictionary.items.setValue(this.dictionaryProperties.rect, PdfArray.fromRectangle(nativeRectangle));
        this.dictionary.items.setValue(this.dictionaryProperties.ca, new PdfNumber(this.darkness));
    }
    /* tslint:enable */
    // IPdfWrapper Members
    /**
     * Gets the `element`.
     * @private
     */
    get element() {
        return this.pdfDictionary;
    }
}
