/**
 * Dictionary.ts class for EJ2-PDF
 * @private
 * @hidden
 */
import { defaultToString } from './utils';
/**
 * @private
 * @hidden
 */
export class Dictionary {
    /**
     * @private
     * @hidden
     */
    constructor(toStringFunction) {
        this.table = {};
        this.nElements = 0;
        this.toStr = toStringFunction || defaultToString;
    }
    /**
     * @private
     * @hidden
     */
    getValue(key) {
        const pair = this.table['$' + this.toStr(key)];
        if (typeof pair === 'undefined') {
            return undefined;
        }
        return pair.value;
    }
    /**
     * @private
     * @hidden
     */
    setValue(key, value) {
        // if (typeof key === 'undefined' || typeof value === 'undefined') {
        //     return undefined;
        // }
        let ret;
        const k = '$' + this.toStr(key);
        const previousElement = this.table[k];
        // if (typeof previousElement === 'undefined') {
        this.nElements++;
        ret = undefined;
        // }
        this.table[k] = {
            key: key,
            value: value
        };
        return ret;
    }
    /**
     * @private
     * @hidden
     */
    remove(key) {
        const k = '$' + this.toStr(key);
        const previousElement = this.table[k];
        // if (typeof previousElement !== 'undefined') {
        delete this.table[k];
        this.nElements--;
        return previousElement.value;
        // }
        // return undefined;
    }
    /**
     * @private
     * @hidden
     */
    keys() {
        const keysArray = [];
        let namesOfKeys = Object.keys(this.table);
        for (let index1 = 0; index1 < namesOfKeys.length; index1++) {
            // if (Object.prototype.hasOwnProperty.call(this.table, namesOfKeys[index1])) {
            const pair1 = this.table[namesOfKeys[index1]];
            keysArray.push(pair1.key);
            // }
        }
        return keysArray;
    }
    /**
     * @private
     * @hidden
     */
    values() {
        const valuesArray = [];
        let namesOfValues = Object.keys(this.table);
        for (let index2 = 0; index2 < namesOfValues.length; index2++) {
            // if (Object.prototype.hasOwnProperty.call(this.table, namesOfValues[index2])) {
            const pair2 = this.table[namesOfValues[index2]];
            valuesArray.push(pair2.value);
            // }
        }
        return valuesArray;
    }
    /**
     * @private
     * @hidden
     */
    containsKey(key) {
        let retutnValue = true;
        if (typeof this.getValue(key) === 'undefined') {
            retutnValue = true;
        }
        else {
            retutnValue = false;
        }
        return !retutnValue;
    }
    /**
     * @private
     * @hidden
     */
    clear() {
        this.table = {};
        this.nElements = 0;
    }
    /**
     * @private
     * @hidden
     */
    size() {
        return this.nElements;
    }
} // End of dictionary
