import { PdfBorders, PdfPaddings } from './pdf-borders';
import { PdfBorderOverlapStyle } from './../../tables/light-tables/enum';
/**
 * Base class for the `grid style`,
 */
export class PdfGridStyleBase {
    // Properties
    /**
     * Gets or sets the `background brush`.
     * @private
     */
    get backgroundBrush() {
        return this.gridBackgroundBrush;
    }
    set backgroundBrush(value) {
        this.gridBackgroundBrush = value;
    }
    /**
     * Gets or sets the `text brush`.
     * @private
     */
    get textBrush() {
        return this.gridTextBrush;
    }
    set textBrush(value) {
        this.gridTextBrush = value;
    }
    /**
     * Gets or sets the `text pen`.
     * @private
     */
    get textPen() {
        return this.gridTextPen;
    }
    set textPen(value) {
        this.gridTextPen = value;
    }
    /**
     * Gets or sets the `font`.
     * @private
     */
    get font() {
        return this.gridFont;
    }
    set font(value) {
        this.gridFont = value;
    }
    /**
     * Gets or sets the `background Image`.
     * @private
     */
    get backgroundImage() {
        return this.gridBackgroundImage;
    }
    set backgroundImage(value) {
        this.gridBackgroundImage = value;
    }
}
/**
 * `PdfGridStyle` class provides customization of the appearance for the 'PdfGrid'.
 */
export class PdfGridStyle extends PdfGridStyleBase {
    //constructor
    /**
     * Initialize a new instance for `PdfGridStyle` class.
     * @private
     */
    constructor() {
        super();
        this.gridBorderOverlapStyle = PdfBorderOverlapStyle.Overlap;
        this.bAllowHorizontalOverflow = false;
        this.gridHorizontalOverflowType = PdfHorizontalOverflowType.LastPage;
    }
    //Properties
    /**
     * Gets or sets the `cell spacing` of the 'PdfGrid'.
     * @private
     */
    get cellSpacing() {
        if (typeof this.gridCellSpacing === 'undefined') {
            this.gridCellSpacing = 0;
        }
        return this.gridCellSpacing;
    }
    set cellSpacing(value) {
        this.gridCellSpacing = value;
    }
    /**
     * Gets or sets the type of the `horizontal overflow` of the 'PdfGrid'.
     * @private
     */
    get horizontalOverflowType() {
        return this.gridHorizontalOverflowType;
    }
    set horizontalOverflowType(value) {
        this.gridHorizontalOverflowType = value;
    }
    /**
     * Gets or sets a value indicating whether to `allow horizontal overflow`.
     * @private
     */
    get allowHorizontalOverflow() {
        return this.bAllowHorizontalOverflow;
    }
    set allowHorizontalOverflow(value) {
        this.bAllowHorizontalOverflow = value;
    }
    /**
     * Gets or sets the `cell padding`.
     * @private
     */
    get cellPadding() {
        if (typeof this.gridCellPadding === 'undefined') {
            this.gridCellPadding = new PdfPaddings();
        }
        return this.gridCellPadding;
    }
    set cellPadding(value) {
        if (typeof this.gridCellPadding === 'undefined') {
            this.gridCellPadding = new PdfPaddings();
            this.gridCellPadding = value;
        }
        else {
            this.gridCellPadding = value;
        }
    }
    /**
     * Gets or sets the `border overlap style` of the 'PdfGrid'.
     * @private
     */
    get borderOverlapStyle() {
        return this.gridBorderOverlapStyle;
    }
    set borderOverlapStyle(value) {
        this.gridBorderOverlapStyle = value;
    }
}
/**
 * `PdfGridCellStyle` class provides customization of the appearance for the 'PdfGridCell'.
 */
export class PdfGridCellStyle extends PdfGridStyleBase {
    /**
     * Initializes a new instance of the `PdfGridCellStyle` class.
     * @private
     */
    constructor() {
        super();
        /**
         * @hidden
         * @private
         */
        this.gridCellBorders = PdfBorders.default;
    }
    //Properties
    /**
     * Gets the `string format` of the 'PdfGridCell'.
     * @private
     */
    get stringFormat() {
        return this.format;
    }
    set stringFormat(value) {
        this.format = value;
    }
    /**
     * Gets or sets the `border` of the 'PdfGridCell'.
     * @private
     */
    get borders() {
        return this.gridCellBorders;
    }
    set borders(value) {
        this.gridCellBorders = value;
    }
    /**
     * Gets or sets the `cell padding`.
     * @private
     */
    get cellPadding() {
        return this.gridCellPadding;
    }
    set cellPadding(value) {
        if (this.gridCellPadding == null || typeof this.gridCellPadding === 'undefined') {
            this.gridCellPadding = new PdfPaddings();
        }
        this.gridCellPadding = value;
    }
}
/**
 * `PdfGridRowStyle` class provides customization of the appearance for the `PdfGridRow`.
 */
export class PdfGridRowStyle {
    // Properties
    /**
     * Gets or sets the `background brush`.
     * @private
     */
    get backgroundBrush() {
        return this.gridRowBackgroundBrush;
    }
    setBackgroundBrush(value) {
        this.gridRowBackgroundBrush = value;
        if (typeof this.parent !== 'undefined') {
            for (let i = 0; i < this.parent.cells.count; i++) {
                this.parent.cells.getCell(i).style.backgroundBrush = value;
            }
        }
    }
    /**
     * Gets or sets the `text brush`.
     * @private
     */
    get textBrush() {
        return this.gridRowTextBrush;
    }
    setTextBrush(value) {
        this.gridRowTextBrush = value;
        if (typeof this.parent !== 'undefined') {
            for (let i = 0; i < this.parent.cells.count; i++) {
                this.parent.cells.getCell(i).style.textBrush = value;
            }
        }
    }
    /**
     * Gets or sets the `text pen`.
     * @private
     */
    get textPen() {
        return this.gridRowTextPen;
    }
    setTextPen(value) {
        this.gridRowTextPen = value;
        if (typeof this.parent !== 'undefined') {
            for (let i = 0; i < this.parent.cells.count; i++) {
                this.parent.cells.getCell(i).style.textPen = value;
            }
        }
    }
    /**
     * Gets or sets the `font`.
     * @private
     */
    get font() {
        return this.gridRowFont;
    }
    setFont(value) {
        this.gridRowFont = value;
        if (typeof this.parent !== 'undefined') {
            for (let i = 0; i < this.parent.cells.count; i++) {
                this.parent.cells.getCell(i).style.font = value;
            }
        }
    }
    /**
     * Gets or sets the `border` of the current row.
     * @private
     */
    get border() {
        if (typeof this.gridRowBorder === 'undefined') {
            this.setBorder(new PdfBorders());
        }
        return this.gridRowBorder;
    }
    setBorder(value) {
        this.gridRowBorder = value;
        if (typeof this.parent !== 'undefined') {
            for (let i = 0; i < this.parent.cells.count; i++) {
                this.parent.cells.getCell(i).style.borders = value;
            }
        }
    }
    /**
     * sets the `parent row` of the current object.
     * @private
     */
    setParent(parent) {
        this.parent = parent;
    }
    /**
     * Gets or sets the `backgroundImage` of the 'PdfGridCell'.
     * @private
     */
    get backgroundImage() {
        return this.gridRowBackgroundImage;
    }
    /**
     * sets the `backgroundImage` of the 'PdfGridCell'.
     * @private
     */
    setBackgroundImage(value) {
        this.gridRowBackgroundImage = value;
    }
    // Constructor
    /**
     * Initializes a new instance of the `PdfGridRowStyle` class.
     * @private
     */
    constructor() {
        //
    }
}
/**
 * public Enum for `PdfHorizontalOverflowType`.
 * @private
 */
export var PdfHorizontalOverflowType;
(function (PdfHorizontalOverflowType) {
    /**
     * Specifies the type of `NextPage`.
     * @private
     */
    PdfHorizontalOverflowType[PdfHorizontalOverflowType["NextPage"] = 0] = "NextPage";
    /**
     * Specifies the type of `LastPage`.
     * @private
     */
    PdfHorizontalOverflowType[PdfHorizontalOverflowType["LastPage"] = 1] = "LastPage";
})(PdfHorizontalOverflowType || (PdfHorizontalOverflowType = {}));
