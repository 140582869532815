import { TemplateType } from './../pages/enum';
// import { PdfStampCollection } from `./../Pages/PdfStampCollection`;
/**
 * `PdfDocumentTemplate` class encapsulates a page template for all the pages in the document.
 * @private
 */
export class PdfDocumentTemplate {
    // private m_stamps : PdfStampCollection;
    // Properties
    /**
     * `Left` page template object.
     * @public
     */
    get left() {
        return this.leftTemplate;
    }
    set left(value) {
        this.leftTemplate = this.checkElement(value, TemplateType.Left);
    }
    /**
     * `Top` page template object.
     * @public
     */
    get top() {
        return this.topTemplate;
    }
    set top(value) {
        this.topTemplate = this.checkElement(value, TemplateType.Top);
    }
    /**
     * `Right` page template object.
     * @public
     */
    get right() {
        return this.rightTemplate;
    }
    set right(value) {
        this.rightTemplate = this.checkElement(value, TemplateType.Right);
    }
    /**
     * `Bottom` page template object.
     * @public
     */
    get bottom() {
        return this.bottomTemplate;
    }
    set bottom(value) {
        this.bottomTemplate = this.checkElement(value, TemplateType.Bottom);
    }
    /**
     * `EvenLeft` page template object.
     * @public
     */
    get EvenLeft() {
        return this.evenLeft;
    }
    set EvenLeft(value) {
        this.evenLeft = this.checkElement(value, TemplateType.Left);
    }
    /**
     * `EvenTop` page template object.
     * @public
     */
    get EvenTop() {
        return this.evenTop;
    }
    set EvenTop(value) {
        this.evenTop = this.checkElement(value, TemplateType.Top);
    }
    /**
     * `EvenRight` page template object.
     * @public
     */
    get EvenRight() {
        return this.evenRight;
    }
    set EvenRight(value) {
        this.evenRight = this.checkElement(value, TemplateType.Right);
    }
    /**
     * `EvenBottom` page template object.
     * @public
     */
    get EvenBottom() {
        return this.evenBottom;
    }
    set EvenBottom(value) {
        this.evenBottom = this.checkElement(value, TemplateType.Bottom);
    }
    /**
     * `OddLeft` page template object.
     * @public
     */
    get OddLeft() {
        return this.oddLeft;
    }
    set OddLeft(value) {
        this.oddLeft = this.checkElement(value, TemplateType.Left);
    }
    /**
     * `OddTop` page template object.
     * @public
     */
    get OddTop() {
        return this.oddTop;
    }
    set OddTop(value) {
        this.oddTop = this.checkElement(value, TemplateType.Top);
    }
    /**
     * `OddRight` page template object.
     * @public
     */
    get OddRight() {
        return this.oddRight;
    }
    set OddRight(value) {
        this.oddRight = this.checkElement(value, TemplateType.Right);
    }
    /**
     * `OddBottom` page template object.
     * @public
     */
    get OddBottom() {
        return this.oddBottom;
    }
    set OddBottom(value) {
        this.oddBottom = this.checkElement(value, TemplateType.Bottom);
    }
    // Constructors
    /**
     * Initializes a new instance of the `PdfDocumentTemplate` class.
     * @public
     */
    constructor() {
        //
    }
    // Implementation
    /**
     * Returns `left` template.
     * @public
     */
    getLeft(page) {
        if (page == null) {
            throw new Error('ArgumentNullException:page');
        }
        let template = null;
        // if (page.Document.Pages != null) {
        let even = this.isEven(page);
        if (even) {
            template = (this.EvenLeft != null) ? this.EvenLeft : this.left;
        }
        else {
            template = (this.OddLeft != null) ? this.OddLeft : this.left;
        }
        // }
        return template;
    }
    /**
     * Returns `top` template.
     * @public
     */
    getTop(page) {
        if (page == null) {
            throw new Error('ArgumentNullException:page');
        }
        let template = null;
        // if (page.Document.Pages != null) {
        let even = this.isEven(page);
        if (even) {
            template = (this.EvenTop != null) ? this.EvenTop : this.top;
        }
        else {
            template = (this.OddTop != null) ? this.OddTop : this.top;
        }
        // }
        return template;
    }
    /**
     * Returns `right` template.
     * @public
     */
    getRight(page) {
        if (page == null) {
            throw new Error('ArgumentNullException:page');
        }
        let template = null;
        // if (page.Document.Pages != null) {
        let even = this.isEven(page);
        if (even) {
            template = (this.EvenRight != null) ? this.EvenRight : this.right;
        }
        else {
            template = (this.OddRight != null) ? this.OddRight : this.right;
        }
        // }
        return template;
    }
    /**
     * Returns `bottom` template.
     * @public
     */
    getBottom(page) {
        if (page == null) {
            throw new Error('ArgumentNullException:page');
        }
        let template = null;
        // if (page.Document.Pages != null) {
        let even = this.isEven(page);
        if (even) {
            template = (this.EvenBottom != null) ? this.EvenBottom : this.bottom;
        }
        else {
            template = (this.OddBottom != null) ? this.OddBottom : this.bottom;
        }
        // }
        return template;
    }
    /**
     * Checks whether the page `is even`.
     * @private
     */
    isEven(page) {
        let pages = page.section.document.pages;
        let index = 0;
        if (pages.pageCollectionIndex.containsKey(page)) {
            index = pages.pageCollectionIndex.getValue(page) + 1;
        }
        else {
            index = pages.indexOf(page) + 1;
        }
        let even = ((index % 2) === 0);
        return even;
    }
    /**
     * Checks a `template element`.
     * @private
     */
    checkElement(templateElement, type) {
        if (templateElement != null) {
            if ((typeof templateElement.type !== 'undefined') && (templateElement.type !== TemplateType.None)) {
                throw new Error('NotSupportedException:Can not reassign the template element. Please, create new one.');
            }
            templateElement.type = type;
        }
        return templateElement;
    }
}
