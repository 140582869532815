import { PdfString } from './../../primitives/pdf-string';
import { ArabicShapeRenderer } from './rtl/rtl-text-shape';
import { PdfTextDirection } from './../enum';
import { Bidi } from './rtl/rtl-bidirectional';
/**
 * `Metrics` of the font.
 * @private
 */
export class RtlRenderer {
    constructor() {
        //region Constants
        /// Open bracket symbol.
        /// </summary>
        this.openBracket = '(';
        /// <summary>
        /// Close bracket symbol.
        /// </summary>
        this.closeBracket = ')';
        //#endregion
    }
    //#region Constructors
    /// <summary>
    /// Initializes a new instance of the <see cref="RtlRenderer"/> class.
    /// </summary>
    // public constructor() {
    // }
    //#region Public Methods
    /// <summary>
    /// Layouts text. Changes blocks position in the RTL text.
    /// Ligates the text if needed.
    /// </summary>
    /// <param name="line">Line of the text.</param>
    /// <param name="font">Font to be used for string printing.</param>
    /// <param name="rtl">Font alignment.</param>
    /// <param name="wordSpace">Indicates whether Word Spacing used or not.</param>
    /// <returns>Layout string.</returns>
    layout(line, font, rtl, wordSpace, format) {
        if (line == null) {
            throw new Error('ArgumentNullException : line');
        }
        if (font == null) {
            throw new Error('ArgumentNullException : font');
        }
        let result = [];
        if (font.Unicode) {
            result = this.customLayout(line, rtl, format, font, wordSpace);
        }
        else {
            result = [];
            result[0] = line;
        }
        return result;
    }
    /// <summary>
    /// Layouts a string and splits it by the words and using correct lay outing.
    /// </summary>
    /// <param name="line">Text line.</param>
    /// <param name="font">Font object.</param>
    /// <param name="rtl">Indicates whether RTL should be applied.</param>
    /// <param name="wordSpace">Indicates whether word spacing is used.</param>
    /// <returns>Array of words if converted, null otherwise.</returns>
    splitLayout(line, font, rtl, wordSpace, format) {
        if (line == null) {
            throw new Error('ArgumentNullException : line');
        }
        if (font == null) {
            throw new Error('ArgumentNullException : font');
        }
        let words = [];
        let system = false;
        if (!system || words == null) {
            words = this.customSplitLayout(line, font, rtl, wordSpace, format);
        }
        return words;
    }
    //#endregion
    //#region Implementation
    // private isEnglish( word : string) : boolean
    // {
    //     let c : string = (word.length > 0) ? word[0] : '';
    //     return (c >= '0' && c < 'ÿ');
    // }
    // private keepOrder( words : string, startIndex : number, count: number, result : string[], resultIndex : number) : void
    // {
    //     for (let i : number = 0, ri = resultIndex - count + 1; i < count; ++i, ++ri) {
    //         result[ri] = words[i + startIndex];
    //     }
    // }
    /// <summary>
    /// Uses system API to layout the text.
    /// </summary>
    /// <param name="line">Line of the text to be layouted.</param>
    /// <param name="font">Font which is used for text printing.</param>
    /// <param name="rtl">Indicates whether we use RTL or RTL lay outing of the text container.</param>
    /// <returns>Layout string.</returns>
    /* tslint:disable-next-line:max-line-length */
    getGlyphIndex(line, font, rtl, /*out*/ glyphs, custom) {
        let success = true;
        let fail = false;
        if (line == null) {
            throw new Error('ArgumentNullException : line');
        }
        if (font == null) {
            throw new Error('ArgumentNullException : font');
        }
        glyphs = null;
        if (line.length === 0) {
            return { success: fail, glyphs: glyphs };
        }
        let renderer = new ArabicShapeRenderer();
        let text = renderer.shape(line, 0);
        let internalFont = font.fontInternal;
        let ttfReader = internalFont.ttfReader;
        glyphs = new Uint16Array(text.length);
        let i = 0;
        for (let k = 0, len = text.length; k < len; k++) {
            let ch = text[k];
            let glyphInfo = ttfReader.getGlyph(ch);
            if (glyphInfo !== null && typeof glyphInfo !== 'undefined') {
                glyphs[i++] = (glyphInfo).index;
            }
        }
        return { success: success, glyphs: glyphs };
    }
    /* tslint:disable-next-line:max-line-length */
    customLayout(line, rtl, format, font, wordSpace) {
        if (wordSpace === null || typeof wordSpace === 'undefined') {
            if (line == null) {
                throw new Error('ArgumentNullException : line');
            }
            let result = null;
            //bidirectional order.
            if (format !== null && typeof format !== 'undefined' && format.textDirection !== PdfTextDirection.None) {
                let bidi = new Bidi();
                result = bidi.getLogicalToVisualString(line, rtl);
            }
            return result;
        }
        else {
            if (line == null) {
                throw new Error('ArgumentNullException : line');
            }
            if (font == null) {
                throw new Error('ArgumentNullException : font');
            }
            let layouted = null;
            if (format !== null && typeof format !== 'undefined' && format.textDirection !== PdfTextDirection.None) {
                let renderer = new ArabicShapeRenderer();
                let txt = renderer.shape(line, 0);
                layouted = this.customLayout(txt, rtl, format);
            }
            // else {
            //     layouted = this.customLayout(line, rtl, format);
            // }
            // We have unicode font, but from the file.        
            let result = [];
            // Split the text by words if word spacing is not default.
            if (wordSpace) {
                let words = layouted.split('');
                let count = words.length;
                for (let i = 0; i < count; i++) {
                    words[i] = this.addChars(font, words[i]);
                }
                result = words;
            }
            else {
                result = [];
                result[0] = this.addChars(font, layouted);
            }
            return result;
        }
    }
    /// <summary>
    /// Add information about used glyphs to the font.
    /// </summary>
    /// <param name="font">Font used for text rendering.</param>
    /// <param name="glyphs">Array of used glyphs.</param>
    /// <returns>String in the form to be written to the file.</returns>
    addChars(font, glyphs) {
        let line = glyphs;
        if (font == null) {
            throw new Error('ArgumentNullException : font');
        }
        if (line == null) {
            throw new Error('ArgumentNullException : line');
        }
        let text = line;
        let internalFont = font.fontInternal;
        let ttfReader = internalFont.ttfReader;
        font.setSymbols(text);
        // Reconvert string according to unicode standard.
        text = ttfReader.convertString(text);
        let bytes = PdfString.toUnicodeArray(text, false);
        text = PdfString.byteToString(bytes);
        return text;
        // else {
        //     if (font == null) {
        //         throw new Error('ArgumentNullException : font');
        //     }
        //     if (glyphs == null) {
        //         throw new Error('ArgumentNullException : glyphs');
        //     }
        //     // Mark the chars as used.
        //     let text : string = '';
        //     font.setSymbols(glyphs);
        //     // Create string from the glyphs.
        //     
        //     let chars : string[] = [];
        //     for (let i : number = 0; i < glyphs.length; i++) {
        //         chars[i] = glyphs[i].toString();
        //     }
        //     for (let j : number = 0 ; j < chars.length; j++) {
        //         text = text + chars[j];
        //     }
        //     let bytes : number[] = PdfString.toUnicodeArray(text, false);
        //     text = PdfString.byteToString(bytes);
        //     return text;
        // }
    }
    /// <summary>
    /// Layouts a string and splits it by the words by using custom lay outing.
    /// </summary>
    /// <param name="line">Text line.</param>
    /// <param name="font">Font object.</param>
    /// <param name="rtl">Indicates whether RTL should be applied.</param>
    /// <param name="wordSpace">Indicates whether word spacing is used.</param>
    /// <returns>Array of words if converted, null otherwise.</returns>
    /* tslint:disable-next-line:max-line-length */
    customSplitLayout(line, font, rtl, wordSpace, format) {
        if (line == null) {
            throw new Error('ArgumentNullException : line');
        }
        if (font == null) {
            throw new Error('ArgumentNullException : font');
        }
        let reversedLine = this.customLayout(line, rtl, format);
        let words = reversedLine.split('');
        return words;
    }
}
