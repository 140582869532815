import { Operators } from './pdf-operators';
import { PdfNumber } from './../primitives/pdf-number';
import { PointF, RectangleF } from './../drawing/pdf-drawing';
import { PdfString } from './../primitives/pdf-string';
import { PdfName } from './../primitives/pdf-name';
/**
 * Helper class to `write PDF graphic streams` easily.
 * @private
 */
export class PdfStreamWriter {
    /**
     * Initialize an instance of `PdfStreamWriter` class.
     * @private
     */
    constructor(stream) {
        if (stream == null) {
            throw new Error('ArgumentNullException:stream');
        }
        this.stream = stream;
    }
    //Implementation
    /**
     * `Clear` the stream.
     * @public
     */
    clear() {
        this.stream.clearStream();
    }
    setGraphicsState(dictionaryName) {
        if (dictionaryName instanceof PdfName) {
            this.stream.write(dictionaryName.toString());
            this.stream.write(Operators.whiteSpace);
            this.writeOperator(Operators.setGraphicsState);
        }
        else {
            this.stream.write(Operators.slash);
            this.stream.write(dictionaryName);
            this.stream.write(Operators.whiteSpace);
            this.writeOperator(Operators.setGraphicsState);
        }
    }
    /**
     * `Executes the XObject`.
     * @private
     */
    executeObject(name) {
        this.stream.write(name.toString());
        this.stream.write(Operators.whiteSpace);
        this.writeOperator(Operators.paintXObject);
        this.stream.write(Operators.newLine);
    }
    /**
     * `Closes path object`.
     * @private
     */
    closePath() {
        this.writeOperator(Operators.closePath);
    }
    /**
     * `Clips the path`.
     * @private
     */
    clipPath(useEvenOddRule) {
        this.stream.write(Operators.clipPath);
        if (useEvenOddRule) {
            this.stream.write(Operators.evenOdd);
        }
        this.stream.write(Operators.whiteSpace);
        this.stream.write(Operators.endPath);
        this.stream.write(Operators.newLine);
    }
    /**
     * `Closes, then fills and strokes the path`.
     * @private
     */
    closeFillStrokePath(useEvenOddRule) {
        this.stream.write(Operators.closeFillStrokePath);
        if (useEvenOddRule) {
            this.stream.write(Operators.evenOdd);
            this.stream.write(Operators.newLine);
        }
        else {
            this.stream.write(Operators.newLine);
        }
    }
    /**
     * `Fills and strokes path`.
     * @private
     */
    fillStrokePath(useEvenOddRule) {
        this.stream.write(Operators.fillStroke);
        if (useEvenOddRule) {
            this.stream.write(Operators.evenOdd);
            this.stream.write(Operators.newLine);
        }
        else {
            this.stream.write(Operators.newLine);
        }
    }
    /**
     * `Fills path`.
     * @private
     */
    fillPath(useEvenOddRule) {
        this.stream.write(Operators.fill);
        if (useEvenOddRule) {
            this.stream.write(Operators.evenOdd);
            this.stream.write(Operators.newLine);
        }
        else {
            this.stream.write(Operators.newLine);
        }
    }
    /**
     * `Ends the path`.
     * @private
     */
    endPath() {
        this.writeOperator(Operators.n);
    }
    /**
     * `Closes and fills the path`.
     * @private
     */
    closeFillPath(useEvenOddRule) {
        this.writeOperator(Operators.closePath);
        this.stream.write(Operators.fill);
        if (useEvenOddRule) {
            this.stream.write(Operators.evenOdd);
            this.stream.write(Operators.newLine);
        }
        else {
            this.stream.write(Operators.newLine);
        }
    }
    /**
     * `Closes and strokes the path`.
     * @private
     */
    closeStrokePath() {
        this.writeOperator(Operators.closeStrokePath);
    }
    /**
     * `Sets the text scaling`.
     * @private
     */
    setTextScaling(textScaling) {
        this.stream.write(PdfNumber.floatToString(textScaling));
        this.stream.write(Operators.whiteSpace);
        this.writeOperator(Operators.setTextScaling);
    }
    /**
     * `Strokes path`.
     * @private
     */
    strokePath() {
        this.writeOperator(Operators.stroke);
    }
    /**
     * `Restores` the graphics state.
     * @private
     */
    restoreGraphicsState() {
        this.writeOperator(Operators.restoreState);
    }
    /**
     * `Saves` the graphics state.
     * @private
     */
    saveGraphicsState() {
        this.writeOperator(Operators.saveState);
    }
    startNextLine(arg1, arg2) {
        if (typeof arg1 === 'undefined') {
            this.writeOperator(Operators.goToNextLine);
        }
        else if (arg1 instanceof PointF) {
            this.writePoint(arg1);
            this.writeOperator(Operators.setCoords);
        }
        else {
            this.writePoint(arg1, arg2);
            this.writeOperator(Operators.setCoords);
        }
    }
    /**
     * Shows the `text`.
     * @private
     */
    showText(text) {
        this.checkTextParam(text);
        this.writeText(text);
        this.writeOperator(Operators.setText);
    }
    /**
     * Sets `text leading`.
     * @private
     */
    setLeading(leading) {
        this.stream.write(PdfNumber.floatToString(leading));
        this.stream.write(Operators.whiteSpace);
        this.writeOperator(Operators.setTextLeading);
    }
    /**
     * `Begins the path`.
     * @private
     */
    beginPath(x, y) {
        this.writePoint(x, y);
        this.writeOperator(Operators.beginPath);
    }
    /**
     * `Begins text`.
     * @private
     */
    beginText() {
        this.writeOperator(Operators.beginText);
    }
    /**
     * `Ends text`.
     * @private
     */
    endText() {
        this.writeOperator(Operators.endText);
    }
    appendRectangle(arg1, arg2, arg3, arg4) {
        if (arg1 instanceof RectangleF) {
            this.appendRectangle(arg1.x, arg1.y, arg1.width, arg1.height);
        }
        else {
            this.writePoint(arg1, arg2);
            this.writePoint(arg3, arg4);
            this.writeOperator(Operators.appendRectangle);
        }
    }
    appendLineSegment(arg1, arg2) {
        if (arg1 instanceof PointF) {
            this.appendLineSegment(arg1.x, arg1.y);
        }
        else {
            this.writePoint(arg1, arg2);
            this.writeOperator(Operators.appendLineSegment);
        }
    }
    /**
     * Sets the `text rendering mode`.
     * @private
     */
    setTextRenderingMode(renderingMode) {
        this.stream.write(renderingMode.toString());
        this.stream.write(Operators.whiteSpace);
        this.writeOperator(Operators.setRenderingMode);
    }
    /**
     * Sets the `character spacing`.
     * @private
     */
    setCharacterSpacing(charSpacing) {
        this.stream.write(PdfNumber.floatToString(charSpacing));
        this.stream.write(Operators.whiteSpace);
        this.stream.write(Operators.setCharacterSpace);
        this.stream.write(Operators.newLine);
    }
    /**
     * Sets the `word spacing`.
     * @private
     */
    setWordSpacing(wordSpacing) {
        this.stream.write(PdfNumber.floatToString(wordSpacing));
        this.stream.write(Operators.whiteSpace);
        this.writeOperator(Operators.setWordSpace);
    }
    showNextLineText(arg1, arg2) {
        if (arg1 instanceof PdfString) {
            this.checkTextParam(arg1);
            this.writeText(arg1);
            this.writeOperator(Operators.setTextOnNewLine);
        }
        else {
            this.checkTextParam(arg1);
            this.writeText(arg1, arg2);
            this.writeOperator(Operators.setTextOnNewLine);
        }
    }
    setColorSpace(arg1, arg2) {
        if (arg1 instanceof PdfName && typeof arg2 === 'boolean') {
            let temparg1 = arg1;
            let temparg2 = arg2;
            // if (temparg1 == null) {
            //     throw new Error('ArgumentNullException:name');
            // }
            let op = (temparg2) ? Operators.selectcolorspaceforstroking : Operators.selectcolorspacefornonstroking;
            this.stream.write(temparg1.toString());
            this.stream.write(Operators.whiteSpace);
            this.stream.write(op);
            this.stream.write(Operators.newLine);
        }
        else {
            let temparg1 = arg1;
            let temparg2 = arg2;
            this.setColorSpace(new PdfName(temparg1), temparg2);
        }
    }
    /**
     * Modifies current `transformation matrix`.
     * @private
     */
    modifyCtm(matrix) {
        if (matrix == null) {
            throw new Error('ArgumentNullException:matrix');
        }
        this.stream.write(matrix.toString());
        this.stream.write(Operators.whiteSpace);
        this.writeOperator(Operators.modifyCtm);
    }
    setFont(font, name, size) {
        if (typeof name === 'string') {
            this.setFont(font, new PdfName(name), size);
        }
        else {
            if (font == null) {
                throw new Error('ArgumentNullException:font');
            }
            this.stream.write(name.toString());
            this.stream.write(Operators.whiteSpace);
            this.stream.write(PdfNumber.floatToString(size));
            this.stream.write(Operators.whiteSpace);
            this.writeOperator(Operators.setFont);
        }
    }
    /**
     * `Writes the operator`.
     * @private
     */
    writeOperator(opcode) {
        this.stream.write(opcode);
        this.stream.write(Operators.newLine);
    }
    checkTextParam(text) {
        if (text == null) {
            throw new Error('ArgumentNullException:text');
        }
        if (typeof text === 'string' && text === '') {
            throw new Error('ArgumentException:The text can not be an empty string, text');
        }
    }
    writeText(arg1, arg2) {
        if ((arg1 instanceof PdfString) && (typeof arg2 === 'undefined')) {
            this.stream.write(arg1.pdfEncode());
        }
        else {
            let start;
            let end;
            if (arg2) {
                start = PdfString.hexStringMark[0];
                end = PdfString.hexStringMark[1];
            }
            else {
                start = PdfString.stringMark[0];
                end = PdfString.stringMark[1];
            }
            this.stream.write(start);
            this.stream.write(arg1);
            this.stream.write(end);
        }
    }
    writePoint(arg1, arg2) {
        if ((arg1 instanceof PointF) && (typeof arg2 === 'undefined')) {
            this.writePoint(arg1.x, arg1.y);
        }
        else {
            let temparg1 = arg1;
            this.stream.write(PdfNumber.floatToString(temparg1));
            this.stream.write(Operators.whiteSpace);
            // NOTE: Change Y co-ordinate because we shifted co-ordinate system only.
            arg2 = this.updateY(arg2);
            this.stream.write(PdfNumber.floatToString(arg2));
            this.stream.write(Operators.whiteSpace);
        }
    }
    /**
     * `Updates y` co-ordinate.
     * @private
     */
    updateY(arg) {
        return -arg;
    }
    /**
     * `Writes string` to the file.
     * @private
     */
    write(string) {
        let builder = '';
        builder += string;
        builder += Operators.newLine;
        this.writeOperator(builder);
    }
    /**
     * `Writes comment` to the file.
     * @private
     */
    writeComment(comment) {
        if (comment != null && comment.length > 0) {
            let builder = '';
            builder += Operators.comment;
            builder += Operators.whiteSpace;
            builder += comment;
            //builder.Append( Operators.NewLine );
            this.writeOperator(builder);
        }
        else {
            throw new Error('Invalid comment');
        }
    }
    /**
     * Sets the `color and space`.
     * @private
     */
    setColorAndSpace(color, colorSpace, forStroking) {
        if (!color.isEmpty) {
            // bool test = color is PdfExtendedColor;
            this.stream.write(color.toString(colorSpace, forStroking));
            this.stream.write(Operators.newLine);
        }
    }
    // public setLineDashPattern(pattern : number[], patternOffset : number) : void
    // {
    //     let pat : PdfArray = new PdfArray(pattern);
    //     let off : PdfNumber = new PdfNumber(patternOffset);
    //     this.setLineDashPatternHelper(pat, off);
    // }
    // private setLineDashPatternHelper(pattern : PdfArray, patternOffset : PdfNumber) : void
    // {
    //     pattern.Save(this);
    //     this.m_stream.write(Operators.whiteSpace);
    //     patternOffset.Save(this);
    //     this.m_stream.write(Operators.whiteSpace);
    //     this.writeOperator(Operators.setDashPattern);
    // }
    /**
     * Sets the `line dash pattern`.
     * @private
     */
    setLineDashPattern(pattern, patternOffset) {
        // let pat : PdfArray = new PdfArray(pattern);
        // let off : PdfNumber = new PdfNumber(patternOffset);
        // this.setLineDashPatternHelper(pat, off);
        this.setLineDashPatternHelper(pattern, patternOffset);
    }
    /**
     * Sets the `line dash pattern`.
     * @private
     */
    setLineDashPatternHelper(pattern, patternOffset) {
        let tempPattern = '[';
        if (pattern.length > 1) {
            for (let index = 0; index < pattern.length; index++) {
                if (index === pattern.length - 1) {
                    tempPattern += pattern[index].toString();
                }
                else {
                    tempPattern += pattern[index].toString() + ' ';
                }
            }
        }
        tempPattern += '] ';
        tempPattern += patternOffset.toString();
        tempPattern += ' ' + Operators.setDashPattern;
        this.stream.write(tempPattern);
        this.stream.write(Operators.newLine);
    }
    /**
     * Sets the `miter limit`.
     * @private
     */
    setMiterLimit(miterLimit) {
        this.stream.write(PdfNumber.floatToString(miterLimit));
        this.stream.write(Operators.whiteSpace);
        this.writeOperator(Operators.setMiterLimit);
    }
    /**
     * Sets the `width of the line`.
     * @private
     */
    setLineWidth(width) {
        this.stream.write(PdfNumber.floatToString(width));
        this.stream.write(Operators.whiteSpace);
        this.writeOperator(Operators.setLineWidth);
    }
    /**
     * Sets the `line cap`.
     * @private
     */
    setLineCap(lineCapStyle) {
        this.stream.write((lineCapStyle).toString());
        this.stream.write(Operators.whiteSpace);
        this.writeOperator(Operators.setLineCapStyle);
    }
    /**
     * Sets the `line join`.
     * @private
     */
    setLineJoin(lineJoinStyle) {
        this.stream.write((lineJoinStyle).toString());
        this.stream.write(Operators.whiteSpace);
        this.writeOperator(Operators.setLineJoinStyle);
    }
    //IPdfWriter members
    /**
     * Gets or sets the current `position` within the stream.
     * @private
     */
    get position() {
        return this.stream.position;
    }
    /**
     * Gets `stream length`.
     * @private
     */
    get length() {
        let returnValue = 0;
        if (this.stream.data.length !== 0 && this.stream.data.length !== -1) {
            for (let index = 0; index < this.stream.data.length; index++) {
                returnValue += this.stream.data[index].length;
            }
        }
        return returnValue;
    }
    /**
     * Gets and Sets the `current document`.
     * @private
     */
    get document() {
        return null;
    }
    /* tslint:disable-next-line:max-line-length */
    appendBezierSegment(arg1, arg2, arg3, arg4, arg5, arg6) {
        if (arg1 instanceof PointF && arg2 instanceof PointF && arg3 instanceof PointF) {
            this.writePoint(arg1.x, arg1.y);
            this.writePoint(arg2.x, arg2.y);
            this.writePoint(arg3.x, arg3.y);
        }
        else {
            this.writePoint(arg1, arg2);
            this.writePoint(arg3, arg4);
            this.writePoint(arg5, arg6);
        }
        this.writeOperator(Operators.appendbeziercurve);
    }
    setColourWithPattern(colours, patternName, forStroking) {
        if ((colours != null)) {
            let count = colours.length;
            let i = 0;
            for (i = 0; i < count; ++i) {
                this.stream.write(colours[i].toString());
                this.stream.write(Operators.whiteSpace);
            }
        }
        if ((patternName != null)) {
            this.stream.write(patternName.toString());
            this.stream.write(Operators.whiteSpace);
        }
        if (forStroking) {
            this.writeOperator(Operators.setColorAndPatternStroking);
        }
        else {
            this.writeOperator(Operators.setColorAndPattern);
        }
    }
}
