import { PdfPen } from './../pdf-pen';
import { RectangleF } from './../../drawing/pdf-drawing';
import { PdfRectangleArea } from './rectangle-area';
/**
 * `PdfEllipsePart` class Implements graphics ellipse part, which is a sequence of primitive graphics elements.
 * @private
 */
export class PdfEllipsePart extends PdfRectangleArea {
    /* tslint:disable-next-line:max-line-length */
    constructor(arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8) {
        super();
        // Fields
        /**
         * public variable to store the start angle.
         * @public
         */
        this.startAngle = 0;
        /**
         * public variable to store the sweep angle.
         * @public
         */
        this.sweepAngle = 0;
        if (typeof arg1 === 'undefined') {
            //
        }
        else if (arg1 instanceof RectangleF && typeof arg2 !== 'undefined' && typeof arg3 !== 'undefined') {
            super(arg1);
            this.startAngle = arg2;
            this.sweepAngle = arg3;
        }
        else if (arg1 instanceof PdfPen) {
            if (arg3 instanceof RectangleF) {
                super(arg1, arg2, arg3);
                this.startAngle = arg4;
                this.sweepAngle = arg5;
            }
            else {
                super(arg1, arg2, arg3, arg4, arg5, arg6);
                this.startAngle = arg7;
                this.sweepAngle = arg8;
            }
        }
        else {
            super(arg1, arg2, arg3, arg4);
            this.startAngle = arg5;
            this.sweepAngle = arg6;
        }
    }
}
