import { PdfWriter } from './../input-output/pdf-writer';
import { PdfMainObjectCollection } from './../input-output/pdf-main-object-collection';
import { PdfDocumentBase } from './pdf-document-base';
import { PdfCrossTable } from './../input-output/pdf-cross-table';
import { PdfCatalog } from './pdf-catalog';
import { PdfPageSettings } from './../pages/pdf-page-settings';
import { PdfSectionCollection } from './../pages/pdf-section-collection';
import { PdfDocumentPageCollection } from './../pages/pdf-document-page-collection';
import { PdfCacheCollection } from './../general/pdf-cache-collection';
import { PdfColorSpace } from './../graphics/enum';
import { PdfDocumentTemplate } from './pdf-document-template';
import { PdfFontFamily } from './../graphics/fonts/enum';
import { PdfStandardFont } from './../graphics/fonts/pdf-standard-font';
/**
 * Represents a PDF document and can be used to create a new PDF document from the scratch.
 * ```typescript
 * // create a new PDF document
 * let document : PdfDocument = new PdfDocument();
 * // add a new page to the document
 * let page1 : PdfPage = document.pages.add();
 * // set the font
 * let font : PdfStandardFont = new PdfStandardFont(PdfFontFamily.Helvetica, 20);
 * // create black brush
 * let blackBrush : PdfSolidBrush = new PdfSolidBrush(new PdfColor(0, 0, 0));
 * // draw the text
 * page1.graphics.drawString('Hello World', font, blackBrush, new PointF(0, 0));
 * // save the document
 * document.save('output.pdf');
 * // destroy the document
 * document.destroy();
 * ```
 */
export class PdfDocument extends PdfDocumentBase {
    constructor(isMerging) {
        super();
        /**
         * Default `margin` value.
         * @default 40.0
         * @private
         */
        this.defaultMargin = 40.0;
        /**
         * Internal variable to store instance of `StreamWriter` classes..
         * @default null
         * @private
         */
        this.streamWriter = null;
        this.document = this;
        let isMerge = false;
        if (typeof isMerging === 'undefined') {
            PdfDocument.cacheCollection = new PdfCacheCollection();
            isMerge = false;
        }
        else {
            isMerge = isMerging;
        }
        let objects = new PdfMainObjectCollection();
        this.setMainObjectCollection(objects);
        let crossTable = new PdfCrossTable();
        crossTable.isMerging = isMerge;
        crossTable.document = this;
        this.setCrossTable(crossTable);
        let catalog = new PdfCatalog();
        this.setCatalog(catalog);
        objects.add(catalog);
        catalog.position = -1;
        this.sectionCollection = new PdfSectionCollection(this);
        this.documentPageCollection = new PdfDocumentPageCollection(this);
        catalog.pages = this.sectionCollection;
    }
    //Properties
    /**
     * Gets the `default font`. It is used for complex objects when font is not explicitly defined.
     * @private
     */
    static get defaultFont() {
        if (this.defaultStandardFont == null) {
            this.defaultStandardFont = new PdfStandardFont(PdfFontFamily.Helvetica, 8);
        }
        return this.defaultStandardFont;
    }
    /**
     * Gets the collection of the `sections` in the document.
     * @private
     */
    get sections() {
        return this.sectionCollection;
    }
    /**
     * Gets the document's page setting.
     * @public
     */
    get pageSettings() {
        if (this.settings == null) {
            this.settings = new PdfPageSettings(this.defaultMargin);
        }
        return this.settings;
    }
    /**
     * Sets the document's page setting.
     * ```typescript
     * // create a new PDF document
     * let document : PdfDocument = new PdfDocument();
     *
     * // sets the right margin of the page
     * document.pageSettings.margins.right = 0;
     * // set the page size.
     * document.pageSettings.size = new SizeF(500, 500);
     * // change the page orientation to landscape
     * document.pageSettings.orientation = PdfPageOrientation.Landscape;
     * // apply 90 degree rotation on the page
     * document.pageSettings.rotate = PdfPageRotateAngle.RotateAngle90;
     *
     * // add a pages to the document
     * let page1 : PdfPage = document.pages.add();
     * // set font
     * let font : PdfStandardFont = new PdfStandardFont(PdfFontFamily.Helvetica, 20);
     * // set brush
     * let blackBrush : PdfSolidBrush = new PdfSolidBrush(new PdfColor(0, 0, 0));
     * // set the specified Point
     * let point : PointF = new PointF(page1.getClientSize().width - 200, page1.getClientSize().height - 200);
     * // draw the text
     * page1.graphics.drawString('Hello World', font, blackBrush, point);
     * // save the document
     * document.save('output.pdf');
     * // destroy the document
     * document.destroy();
     * ```
     */
    set pageSettings(value) {
        this.settings = value;
    }
    /**
     * Represents the collection of pages in the PDF document.
     * ```typescript
     * // create a new PDF document
     * let document : PdfDocument = new PdfDocument();
     * //
     * // get the collection of pages in the document
     * let pageCollection : PdfDocumentPageCollection  = document.pages;
     * //
     * // add pages
     * let page1 : PdfPage = pageCollection.add();
     * // save the document
     * document.save('output.pdf');
     * // destroy the document
     * document.destroy();
     * ```
     */
    get pages() {
        return this.documentPageCollection;
    }
    /**
     * Gets collection of the `cached objects`.
     * @private
     */
    static get cache() {
        if (typeof PdfDocument.cacheCollection === 'undefined' || PdfDocument.cacheCollection == null) {
            return new PdfCacheCollection();
        }
        return PdfDocument.cacheCollection;
    }
    /**
     * Sets collection of the `cached objects`.
     * @private
     */
    static set cache(value) {
        this.cacheCollection = value;
    }
    /**
     * Gets the value of enable cache.
     * @private
     */
    static get enableCache() {
        return this.isCacheEnabled;
    }
    /**
     * Sets thie value of enable cache.
     * @private
     */
    static set enableCache(value) {
        this.isCacheEnabled = value;
    }
    /* tslint:disable */
    /**
     * Gets or sets the `color space` of the document. This property can be used to create PDF document in RGB, Gray scale or CMYK color spaces.
     * @private
     */
    get colorSpace() {
        if ((this.pdfColorSpace === PdfColorSpace.Rgb) || ((this.pdfColorSpace === PdfColorSpace.Cmyk)
            || (this.pdfColorSpace === PdfColorSpace.GrayScale))) {
            return this.pdfColorSpace;
        }
        else {
            return PdfColorSpace.Rgb;
        }
    }
    set colorSpace(value) {
        if ((value === PdfColorSpace.Rgb) || ((value === PdfColorSpace.Cmyk) ||
            (value === PdfColorSpace.GrayScale))) {
            this.pdfColorSpace = value;
        }
        else {
            this.pdfColorSpace = PdfColorSpace.Rgb;
        }
    }
    /* tslint:enable */
    /**
     * Gets or sets a `template` to all pages in the document.
     * @private
     */
    get template() {
        if (this.pageTemplate == null) {
            this.pageTemplate = new PdfDocumentTemplate();
        }
        return this.pageTemplate;
    }
    set template(value) {
        this.pageTemplate = value;
    }
    docSave(stream, arg2, arg3) {
        this.checkPagesPresence();
        if (stream === null) {
            throw new Error('ArgumentNullException : stream');
        }
        this.streamWriter = stream;
        let writer = new PdfWriter(stream);
        writer.document = this;
        if (typeof arg2 === 'boolean' && typeof arg3 === 'undefined') {
            return this.crossTable.save(writer);
        }
        else {
            this.crossTable.save(writer, arg2);
        }
    }
    /**
     * Checks the pages `presence`.
     * @private
     */
    checkPagesPresence() {
        if (this.pages.count === 0) {
            this.pages.add();
        }
    }
    /**
     * disposes the current instance of `PdfDocument` class.
     * ```typescript
     * // create a new PDF document
     * let document : PdfDocument = new PdfDocument();
     * // add a new page to the document
     * let page1 : PdfPage = document.pages.add();
     * // set the font
     * let font : PdfStandardFont = new PdfStandardFont(PdfFontFamily.Helvetica, 20);
     * // create black brush
     * let blackBrush : PdfSolidBrush = new PdfSolidBrush(new PdfColor(0, 0, 0));
     * // draw the text
     * page1.graphics.drawString('Hello World', font, blackBrush, new PointF(0, 0));
     * // save the document
     * document.save('output.pdf');
     * // destroy the document
     * document.destroy();
     * ```
     */
    destroy() {
        this.catalog = undefined;
        this.colorSpace = undefined;
        this.currentSavingObj = undefined;
        this.documentPageCollection = undefined;
        this.isStreamCopied = undefined;
        this.pageSettings = undefined;
        this.pageTemplate = undefined;
        this.pdfColorSpace = undefined;
        this.sectionCollection = undefined;
        PdfDocument.cache.destroy();
        this.crossTable.pdfObjects.destroy();
        PdfDocument.cache = undefined;
        this.streamWriter.destroy();
    }
}
/**
 * `Font` used in complex objects to draw strings and text when it is not defined explicitly.
 * @default null
 * @private
 */
PdfDocument.defaultStandardFont = null;
/**
 * Indicates whether enable cache or not
 * @default true
 * @private
 */
PdfDocument.isCacheEnabled = true;
