/**
 * PdfCompositeField.ts class for EJ2-PDF
 */
import { PdfMultipleValueField } from './multiple-value-field';
/**
 * Represents class which can concatenate multiple automatic fields into single string.
 */
export class PdfCompositeField extends PdfMultipleValueField {
    // Constructor
    /**
     * Initialize a new instance of `PdfCompositeField` class.
     * @param font Font of the field.
     * @param brush Color of the field.
     * @param text Content of the field.
     * @param list List of the automatic fields in specific order based on the text content.
     */
    constructor(font, brush, text, ...list) {
        super();
        // Fields
        /**
         * Stores the array of automatic fields.
         * @private
         */
        this.internalAutomaticFields = null;
        /**
         * Stores the text value of the field.
         * @private
         */
        this.internalText = '';
        this.font = font;
        this.brush = brush;
        this.text = text;
        this.automaticFields = list;
    }
    // Properties
    /**
     * Gets and sets the content of the field.
     * @public
     */
    get text() {
        return this.internalText;
    }
    set text(value) {
        this.internalText = value;
    }
    /**
     * Gets and sets the list of the field to drawn.
     * @public
     */
    get automaticFields() {
        return this.internalAutomaticFields;
    }
    set automaticFields(value) {
        this.internalAutomaticFields = value;
    }
    // Implementation
    /**
     * Return the actual value generated from the list of automatic fields.
     * @public
     */
    getValue(graphics) {
        let values = [];
        let text = this.text.toString();
        if (typeof this.automaticFields !== 'undefined' && this.automaticFields != null && this.automaticFields.length > 0) {
            for (let i = 0; i < this.automaticFields.length; i++) {
                let automaticField = this.automaticFields[i];
                text = text.replace('{' + i + '}', automaticField.getValue(graphics));
            }
        }
        return text;
    }
}
