import { PdfAnnotation } from './annotation';
import { PdfName } from './../primitives/pdf-name';
/**
 * `PdfLinkAnnotation` class represents the ink annotation class.
 * @private
 */
export class PdfLinkAnnotation extends PdfAnnotation {
    constructor(rectangle) {
        super(rectangle);
    }
    // Implementation
    /**
     * `Initializes` annotation object.
     * @private
     */
    initialize() {
        super.initialize();
        this.dictionary.items.setValue(this.dictionaryProperties.subtype, new PdfName(this.dictionaryProperties.link));
    }
}
