/**
 * PdfAutomaticField.ts class for EJ2-PDF
 */
import { RectangleF, SizeF, PointF } from './../../drawing/pdf-drawing';
import { PdfSolidBrush } from './../../graphics/brushes/pdf-solid-brush';
import { PdfColor } from './../../graphics/pdf-color';
import { PdfDocument } from './../pdf-document';
import { PdfGraphicsElement } from './../../graphics/figures/base/graphics-element';
import { PdfAutomaticFieldInfo } from './automatic-field-info';
/**
 * Represents a fields which is calculated before the document saves.
 */
export class PdfAutomaticField extends PdfGraphicsElement {
    // Constructors
    constructor() {
        super();
        // Fields
        this.internalBounds = new RectangleF(0, 0, 0, 0);
        this.internalTemplateSize = new SizeF(0, 0);
    }
    // Properties
    get bounds() {
        return this.internalBounds;
    }
    set bounds(value) {
        this.internalBounds = value;
    }
    get size() {
        return new SizeF(this.bounds.width, this.bounds.height);
    }
    set size(value) {
        this.bounds.width = value.width;
        this.bounds.height = value.height;
    }
    get location() {
        return new PointF(this.bounds.x, this.bounds.y);
    }
    set location(value) {
        this.bounds.x = value.x;
        this.bounds.y = value.y;
    }
    get font() {
        return this.internalFont;
    }
    set font(value) {
        this.internalFont = value;
    }
    get brush() {
        return this.internalBrush;
    }
    set brush(value) {
        this.internalBrush = value;
    }
    get pen() {
        return this.internalPen;
    }
    set pen(value) {
        this.internalPen = value;
    }
    get stringFormat() {
        return this.internalStringFormat;
    }
    set stringFormat(value) {
        this.internalStringFormat = value;
    }
    performDrawHelper(graphics, location, scalingX, scalingY) {
        if (this.bounds.height === 0 || this.bounds.width === 0) {
            let text = this.getValue(graphics);
            this.internalTemplateSize = this.getFont().measureString(text, this.size, this.stringFormat);
        }
    }
    draw(arg1, arg2, arg3) {
        if (typeof arg2 === 'undefined') {
            let location = new PointF(0, 0);
            this.draw(arg1, location);
        }
        else if (arg2 instanceof PointF) {
            this.draw(arg1, arg2.x, arg2.y);
        }
        else {
            this.drawHelper(arg1, arg2, arg3);
            let info = new PdfAutomaticFieldInfo(this, new PointF(arg2, arg3));
            arg1.automaticFields.add(info);
        }
    }
    getSize() {
        if (this.bounds.height === 0 || this.bounds.width === 0) {
            return this.internalTemplateSize;
        }
        else {
            return this.size;
        }
    }
    drawInternal(graphics) {
        //
    }
    /* tslint:disable */
    getBrush() {
        return (typeof this.internalBrush === 'undefined' || this.internalBrush == null) ? new PdfSolidBrush(new PdfColor(0, 0, 0)) : this.internalBrush;
    }
    getFont() {
        return (typeof this.internalFont === 'undefined' || this.internalFont == null) ? PdfDocument.defaultFont : this.internalFont;
    }
    /* tslint:enable */
    getPageFromGraphics(graphics) {
        if (typeof graphics.page !== 'undefined' && graphics.page !== null) {
            let page = graphics.page;
            return page;
        }
        else {
            let page = graphics.currentPage;
            return page;
        }
    }
}
