import { Dictionary } from './../collections/dictionary';
import { PdfName } from './pdf-name';
import { Operators } from './../input-output/pdf-operators';
import { DictionaryProperties } from './../input-output/pdf-dictionary-properties';
/**
 * `PdfDictionary` class is used to perform primitive operations.
 * @private
 */
export class PdfDictionary {
    constructor(dictionary) {
        /**
         * Internal variable to store the `position`.
         * @default -1
         * @private
         */
        this.position7 = -1;
        /**
         * The `IPdfSavable` with the specified key.
         * @private
         */
        this.primitiveItems = new Dictionary();
        /**
         * `Start marker` for dictionary.
         * @private
         */
        this.prefix = '<<';
        /**
         * `End marker` for dictionary.
         * @private
         */
        this.suffix = '>>';
        /**
         * @hidden
         * @private
         */
        this.resources = [];
        /**
         * Internal variable to hold `cloned object`.
         * @default null
         * @private
         */
        this.object = null;
        /**
         * Flag for PDF file formar 1.5 is dictionary `archiving` needed.
         * @default true
         * @private
         */
        this.archive = true;
        /**
         * Represents the Font dictionary.
         * @hidden
         * @private
         */
        this.isFont = false;
        if (typeof dictionary === 'undefined') {
            this.primitiveItems = new Dictionary();
            this.encrypt = true;
            this.dictionaryProperties = new DictionaryProperties();
        }
        else {
            this.primitiveItems = new Dictionary();
            let keys = dictionary.items.keys();
            let values = dictionary.items.values();
            for (let index = 0; index < dictionary.items.size(); index++) {
                this.primitiveItems.setValue(keys[index], values[index]);
            }
            this.status = dictionary.status;
            this.freezeChanges(this);
            this.encrypt = true;
            this.dictionaryProperties = new DictionaryProperties();
        }
    }
    //Properties
    /**
     * Gets or sets the `IPdfSavable` with the specified key.
     * @private
     */
    get items() {
        return this.primitiveItems;
    }
    /**
     * Gets or sets the `Status` of the specified object.
     * @private
     */
    get status() {
        return this.status7;
    }
    set status(value) {
        this.status7 = value;
    }
    /**
     * Gets or sets a value indicating whether this document `is saving` or not.
     * @private
     */
    get isSaving() {
        return this.isSaving7;
    }
    set isSaving(value) {
        this.isSaving7 = value;
    }
    /**
     * Gets or sets the `index` value of the specified object.
     * @private
     */
    get objectCollectionIndex() {
        return this.index7;
    }
    set objectCollectionIndex(value) {
        this.index7 = value;
    }
    /**
     * Returns `cloned object`.
     * @private
     */
    get clonedObject() {
        return this.object;
    }
    /**
     * Gets or sets the `position` of the object.
     * @private
     */
    get position() {
        return this.position7;
    }
    set position(value) {
        this.position7 = value;
    }
    /**
     * Gets the `count`.
     * @private
     */
    get Count() {
        return this.primitiveItems.size();
    }
    /**
     * Collection of `items` in the object.
     * @private
     */
    get Dictionary() {
        return this;
    }
    /**
     * Get flag if need to `archive` dictionary.
     * @private
     */
    getArchive() {
        return this.archive;
    }
    /**
     * Set flag if need to `archive` dictionary.
     * @private
     */
    setArchive(value) {
        this.archive = value;
    }
    /**
     * Sets flag if `encryption` is needed.
     * @private
     */
    setEncrypt(value) {
        this.encrypt = value;
        this.modify();
    }
    /**
     * Gets flag if `encryption` is needed.
     * @private
     */
    getEncrypt() {
        return this.encrypt;
    }
    /**
     * `Freezes` the changes.
     * @private
     */
    freezeChanges(freezer) {
        this.bChanged = false;
    }
    /**
     * Creates a `copy of PdfDictionary`.
     * @private
     */
    clone(crossTable) {
        //Need to add more codings
        let newDict = new PdfDictionary();
        return newDict;
    }
    /**
     * `Mark` this instance modified.
     * @private
     */
    modify() {
        this.bChanged = true;
    }
    /**
     * `Removes` the specified key.
     * @private
     */
    remove(key) {
        if (typeof key !== 'string') {
            this.primitiveItems.remove(key.value);
            this.modify();
        }
        else {
            this.remove(new PdfName(key));
        }
    }
    /**
     * `Determines` whether the dictionary contains the key.
     * @private
     */
    containsKey(key) {
        let returnValue = false;
        returnValue = this.primitiveItems.containsKey(key.toString());
        return returnValue;
    }
    /**
     * Raises event `BeginSave`.
     * @private
     */
    onBeginSave() {
        this.beginSave.sender.beginSave();
    }
    /**
     * Raises event `Font Dictionary BeginSave`.
     * @private
     */
    onFontDictionaryBeginSave() {
        this.fontDictionaryBeginSave.sender.fontDictionaryBeginSave();
    }
    /**
     * Raises event `Descendant Font BeginSave`.
     * @private
     */
    onDescendantFontBeginSave() {
        this.descendantFontBeginSave.sender.descendantFontBeginSave();
    }
    /**
     * Raises event 'BeginSave'.
     * @private
     */
    onTemplateBeginSave() {
        this.pageBeginDrawTemplate.sender.pageBeginSave();
    }
    /**
     * Raises event `BeginSave`.
     * @private
     */
    onBeginAnnotationSave() {
        this.annotationBeginSave.sender.beginSave();
    }
    /**
     * Raises event `BeginSave`.
     * @private
     */
    onSectionBeginSave(writer) {
        let saveEvent = this.sectionBeginSave;
        saveEvent.sender.beginSave(saveEvent.state, writer);
    }
    save(writer, bRaiseEvent) {
        if (typeof bRaiseEvent === 'undefined') {
            this.save(writer, true);
        }
        else {
            writer.write(this.prefix);
            if (typeof this.beginSave !== 'undefined') {
                this.onBeginSave();
            }
            if (typeof this.descendantFontBeginSave !== 'undefined') {
                this.onDescendantFontBeginSave();
            }
            if (typeof this.fontDictionaryBeginSave !== 'undefined') {
                this.onFontDictionaryBeginSave();
            }
            if (typeof this.annotationBeginSave !== 'undefined') {
                this.onBeginAnnotationSave();
            }
            if (typeof this.sectionBeginSave !== 'undefined') {
                this.onSectionBeginSave(writer);
            }
            if (typeof this.pageBeginDrawTemplate !== 'undefined') {
                this.onTemplateBeginSave();
            }
            // }
            if (this.Count > 0) {
                this.saveItems(writer);
            }
            writer.write(this.suffix);
            writer.write(Operators.newLine);
        }
    }
    /**
     * `Save dictionary items`.
     * @private
     */
    saveItems(writer) {
        writer.write(Operators.newLine);
        let keys = this.primitiveItems.keys();
        let values = this.primitiveItems.values();
        for (let index = 0; index < keys.length; index++) {
            let key = keys[index];
            let name = new PdfName(key);
            name.save(writer);
            writer.write(Operators.whiteSpace);
            let resources = values[index];
            resources.save(writer);
            writer.write(Operators.newLine);
        }
    }
}
export class SaveSectionCollectionEventHandler {
    /**
     * New instance for `save section collection event handler` class.
     * @private
     */
    constructor(sender) {
        this.sender = sender;
    }
}
export class SaveDescendantFontEventHandler {
    /**
     * New instance for `save section collection event handler` class.
     * @private
     */
    constructor(sender) {
        this.sender = sender;
    }
}
export class SaveFontDictionaryEventHandler {
    /**
     * New instance for `save section collection event handler` class.
     * @private
     */
    constructor(sender) {
        this.sender = sender;
    }
}
export class SaveAnnotationEventHandler {
    /**
     * New instance for `save annotation event handler` class.
     * @private
     */
    constructor(sender) {
        this.sender = sender;
    }
}
export class SaveSectionEventHandler {
    // constructors
    /**
     * New instance for `save section event handler` class.
     * @private
     */
    constructor(sender, state) {
        this.sender = sender;
        this.state = state;
    }
}
/**
 * SaveTemplateEventHandler class used to store information about template elements.
 * @private
 * @hidden
 */
export class SaveTemplateEventHandler {
    /**
     * New instance for save section collection event handler class.
     * @public
     */
    constructor(sender) {
        this.sender = sender;
    }
}
