/**
 * Used to perform `convertion between pixels and points`.
 * @private
 */
export class PdfUnitConverter {
    //constructors
    /**
     * Initializes a new instance of the `UnitConvertor` class with DPI value.
     * @private
     */
    constructor(dpi) {
        this.updateProportionsHelper(dpi);
    }
    /**
     * `Converts` the value, from one graphics unit to another graphics unit.
     * @private
     */
    convertUnits(value, from, to) {
        return this.convertFromPixels(this.convertToPixels(value, from), to);
    }
    /**
     * Converts the value `to pixel` from specified graphics unit.
     * @private
     */
    convertToPixels(value, from) {
        let index = from;
        let result = (value * this.proportions[index]);
        return result;
    }
    /**
     * Converts value, to specified graphics unit `from Pixel`.
     * @private
     */
    convertFromPixels(value, to) {
        let index = to;
        let result = (value / this.proportions[index]);
        return result;
    }
    /**
     * `Update proportions` matrix according to Graphics settings.
     * @private
     */
    updateProportionsHelper(pixelPerInch) {
        this.proportions = [
            pixelPerInch / 2.54,
            pixelPerInch / 6.0,
            1,
            pixelPerInch / 72.0,
            pixelPerInch,
            pixelPerInch / 300.0,
            pixelPerInch / 25.4 // Millimeter
        ];
    }
}
//Fields
/**
 * Indicates default `horizontal resolution`.
 * @default 96
 * @private
 */
PdfUnitConverter.horizontalResolution = 96;
/**
 * Indicates default `vertical resolution`.
 * @default 96
 * @private
 */
PdfUnitConverter.verticalResolution = 96;
