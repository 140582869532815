/**
 * `constants.ts` class for EJ2-PDF
 * @private
 */
export class ProcedureSets {
    constructor() {
        /**
         * Specifies the `PDF` procedure set.
         * @private
         */
        this.pdf = 'PDF';
        /**
         * Specifies the `Text` procedure set.
         * @private
         */
        this.text = 'Text';
        /**
         * Specifies the `ImageB` procedure set.
         * @private
         */
        this.imageB = 'ImageB';
        /**
         * Specifies the `ImageC` procedure set.
         * @private
         */
        this.imageC = 'ImageC';
        /**
         * Specifies the `ImageI` procedure set.
         * @private
         */
        this.imageI = 'ImageI';
    }
}
