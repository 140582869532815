/**
 * PdfSingleValueField.ts class for EJ2-PDF
 */
import { PdfAutomaticField } from './automatic-field';
import { TemporaryDictionary } from './../../collections/object-object-pair/dictionary';
import { PdfTemplateValuePair } from './pdf-template-value-pair';
import { PointF, SizeF } from './../../drawing/pdf-drawing';
import { PdfTemplate } from './../../graphics/figures/pdf-template';
/**
 * Represents automatic field which has the same value in the whole document.
 */
export class PdfSingleValueField extends PdfAutomaticField {
    // Constructors
    constructor() {
        super();
        // Fields
        /* tslint:disable */
        this.list = new TemporaryDictionary();
        /* tslint:enable */
        this.painterGraphics = [];
    }
    performDraw(graphics, location, scalingX, scalingY) {
        super.performDrawHelper(graphics, location, scalingX, scalingY);
        let page = this.getPageFromGraphics(graphics);
        let document = page.document;
        let textValue = this.getValue(graphics);
        /* tslint:disable */
        if (this.list.containsKey(document)) {
            let pair = this.list.getValue(document);
            let drawLocation = new PointF(location.x + this.location.x, location.y + this.location.y);
            graphics.drawPdfTemplate(pair.template, drawLocation, new SizeF(pair.template.width * scalingX, pair.template.height * scalingY));
            this.painterGraphics.push(graphics);
        }
        else {
            let size = this.getSize();
            let template = new PdfTemplate(size);
            this.list.setValue(document, new PdfTemplateValuePair(template, textValue));
            template.graphics.drawString(textValue, this.getFont(), this.pen, this.getBrush(), 0, 0, size.width, size.height, this.stringFormat);
            let drawLocation = new PointF(location.x + this.location.x, location.y + this.location.y);
            graphics.drawPdfTemplate(template, drawLocation, new SizeF(template.width * scalingX, template.height * scalingY));
            this.painterGraphics.push(graphics);
        }
        /* tslint:enable */
    }
}
